import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as momentTimeZone from "moment-timezone";
import axios from "axios";
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  public isRefreshing: boolean = false;
  constructor(public jwtHelper: JwtHelperService, public router: Router) {}
  // ...
  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    var date = momentTimeZone(new Date())
      .tz("Asia/Riyadh")
      .format("MM-DD-YYYY HH:mm:ss") as any;

    //var date = new Date();
    if (localStorage.getItem("tokenExpireDateTime") != null) {
      var x = new Date(localStorage.getItem("tokenExpireDateTime"));
      var y = new Date(date);
      var tokenTimeInSec = this.convertTimeToSeconds(x);
      var currentTimeInSec = this.convertTimeToSeconds(y);

      var seconds = tokenTimeInSec - currentTimeInSec;
      if (seconds > 0 && seconds <= 2700 && !this.isRefreshing) {
        this.isRefreshing = true;
        const payload = {
          userToken: localStorage.getItem("token"),
        };
        axios
          .post("Account/RefreshToke", payload)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.payload.userToken) {
                localStorage.setItem("token", response.data.payload.userToken);
                let expiarytime = null;
                expiarytime = new Date(response.data.payload.expiresIn);
                localStorage.setItem(
                  "tokenExpireDateTime",
                  expiarytime.toString()
                );
                this.isRefreshing = false;
              }
            } else {
              this.isRefreshing = false;
            }
          })
          .catch((error) => {
            this.isRefreshing = false;
          });
      }
    }
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
  public convertTimeToSeconds(date: Date): number {
    var toConnvert = new Date(date);

    var hoursInSec = toConnvert.getHours() * 60 * 60;
    var mintInSec = toConnvert.getMinutes() * 60;
    var second = toConnvert.getSeconds();

    return hoursInSec + mintInSec + second;
  }
}
