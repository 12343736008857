import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetectScrollDirective } from './detect-scroll/detect-scroll.directive';
import { AfterValueChangedDirective } from './after-value-change.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DetectScrollDirective,
    AfterValueChangedDirective
  ],
  exports: [
    DetectScrollDirective,
    AfterValueChangedDirective
  ]
})
export class DirectivesModule { }
