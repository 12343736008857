import { Injectable, EventEmitter } from '@angular/core';
@Injectable()
export class StorageService {
    private _selectedBranch = null;
    private _selectedUser = null;
    notificationEmit: EventEmitter<any> = new EventEmitter();
    constructor() { }
    set setBranch(value) {
        this._selectedBranch = value;
    }
    get getBranch() {
        return this._selectedBranch = JSON.parse(localStorage.getItem('selectedCompanyBranch'));
    }
    get getUserInfo() {
        return this._selectedUser = JSON.parse(localStorage.getItem('user'));
    }
    resetBranch () {
        this._selectedBranch = null;
    }


}