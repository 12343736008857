<div class="dx-grid-container" id="dx-grid">
  <dx-load-panel
    [hideOnOutsideClick]="false"
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="loading"
  >
  </dx-load-panel>
  <app-panel [footer]="false" [backArrow]="false">
    <div class="heading">Min Qty</div>
    <div class="body">
      <div class="row">
        <div class="col-md-12">
          <ng-container *ngIf="minQtyList?.length > 0">
            <div class="upload-csv">
              <button type="button" mat-button (click)="csvfileInput.click()"
              matTooltip="directly upload csv file" matTooltipPosition="left">
              <i class="material-icons">cloud_upload</i>
              <span class="ml-2">upload csv</span>
              <input #csvfileInput type="file" accept=".csv" (change)="readCSV($event, 'minQtyList')" style="display:none;" />
            </button>
            </div>
            <dx-data-grid
              [dataSource]="minQtyList"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [hoverStateEnabled]="true"
              [showBorders]="true"
              [columnAutoWidth]="true"
              [customizeColumns]="customizeColumns"
              (onEditingStart)="setColumnDataType($event)" (onExporting)="onExporting($event)" 
            >
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-search-panel [visible]="true"></dxo-search-panel>
              <dxo-filter-row [visible]="false"></dxo-filter-row>
              <dxo-header-filter [visible]="false"></dxo-header-filter>
              <dxo-column-chooser
                [enabled]="true"
                mode="select"
              ></dxo-column-chooser>
              <dxo-pager
                [showPageSizeSelector]="true"
                [showInfo]="true"
                [allowedPageSizes]="[5, 10, 20]"
              ></dxo-pager>
              <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
              <dxo-export [enabled]="true" fileName="exportData"></dxo-export>
            </dx-data-grid>
          </ng-container>
        </div>
      </div>
    </div>
  </app-panel>

  <div class="mt-3"></div>

  <app-panel [footer]="false" [backArrow]="false">
    <div class="heading">Max Qty</div>
    <div class="body">
      <div class="row">
        <div class="col-md-12">
          <ng-container *ngIf="maxQtyList?.length > 0">
            <div class="upload-csv">
              <button type="button" mat-button (click)="csvfileInput.click()"
              matTooltip="directly upload csv file" matTooltipPosition="left">
              <i class="material-icons">cloud_upload</i>
              <span class="ml-2">upload csv</span>
              <input #csvfileInput type="file" accept=".csv" (change)="readCSV($event, 'maxQtyList')" style="display:none;" />
            </button>
            </div>
            <dx-data-grid
              [dataSource]="maxQtyList"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [hoverStateEnabled]="true"
              [showBorders]="true"
              [columnAutoWidth]="true"
              [customizeColumns]="customizeColumns"
              (onEditingStart)="setColumnDataType($event)" (onExporting)="onExporting($event)"
            >
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-search-panel [visible]="true"></dxo-search-panel>
              <dxo-filter-row [visible]="false"></dxo-filter-row>
              <dxo-header-filter [visible]="false"></dxo-header-filter>
              <dxo-column-chooser
                [enabled]="true"
                mode="select"
              ></dxo-column-chooser>
              <dxo-pager
                [showPageSizeSelector]="true"
                [showInfo]="true"
                [allowedPageSizes]="[5, 10, 20]"
              ></dxo-pager>
              <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
              <dxo-export [enabled]="true" fileName="exportData"></dxo-export>
            </dx-data-grid>
          </ng-container>
        </div>
      </div>
    </div>
  </app-panel>
  <dx-speed-dial-action
    icon="add"
    label="Save"
    [index]="1"
    (onClick)="savebothSidesForm()"
  >
  </dx-speed-dial-action>
</div>
