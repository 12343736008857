import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'simulation-dialog',
    styleUrls: ['./simulation-dialog.component.scss'],
    templateUrl: './simulation-dialog.component.html',
  })
  export class SimulationDialogComponent implements OnInit {
    loadingIndicator: boolean;
    simulationList: any[];
    currency: string;
    constructor(
      public dialogRef: MatDialogRef<SimulationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data) {
          this.loadingIndicator = false;
          this.simulationList = data.list;
          this.currency = data.currency;
        }

  ngOnInit() {
  }

       /**
   * @name customizeText
   * @param e 
   * @description display summary text
   * @returns void
  */
       customizeText (e) {
        return "Total: ";
      }
    
      /**
       * @name customizeAmount
       * @param form 
       * @description display summary amount
       * @returns void
      */
      customizeAmount (e) {
        return (e.value as number).toFixed(2);
      }
  }
