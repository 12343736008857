<div class="container">
  <form [formGroup]="mplForm">
    <div class="row">
      <div class="col-md-12">
        <div class="dx-field">
          <div>Pricing For <span>*</span> :</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="pricingTypeList"
            placeholder="Choose Pricing Type" [showClearButton]="true" displayExpr="priceTypeName"
            valueExpr="pricingTypeId" [searchEnabled]="true" formControlName="pricingTypeId"
            (onSelectionChanged)="changePricingType(mplForm.get('pricingTypeId').value, type)">
            <dx-validator>
              <dxi-validation-rule type="required" message="Pricing Type is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12" *ngIf="!brandFilter">
        <div class="dx-field">
          <div>Brands <span>*</span> :</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }" 
           [items]="brandList" placeholder="Choose Brand" [showClearButton]="true" displayExpr="brandName"
          valueExpr="brandMasterId" [searchEnabled]="true"
          formControlName="brandMasterId">
          <dx-validator>
            <dxi-validation-rule type="required" message="Brands is required">
            </dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
        </div>
      </div>
      <div class="col-md-12" *ngIf="isItemCategoryShowing">
        <div class="dx-field">
          <div>Item Category <span>*</span> :</div>
          <dx-select-box
          [inputAttr]="{ autocomplete: 'off' }"
          [items]="itemCategoryList"
          
          placeholder="Choose Item Category"
          [showClearButton]="true"
          displayExpr="itemCategoryName"
          valueExpr="itemCategoryId"
          [searchEnabled]="true"
          formControlName="itemCategoryId"
          >
          <dx-validator>
            <dxi-validation-rule type="required" message="Item Category is required">
            </dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
        </div>
      </div>
      <div class="col-md-12"
        *ngIf="(mplForm.get('pricingTypeId').value !=1) && mplForm.get('pricingTypeId').value !=2">
        <div class="dx-field">
          <div>City {{mplForm.get('pricingTypeId').value == 3 ? 'From' : ''}}<span>*</span>:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="citiesList"
           placeholder="Choose City {{mplForm.get('pricingTypeId').value == 3 ? 'From' : ''}}"
            [readOnly]="citiesList?.length == 0" [showClearButton]="false" displayExpr="cityName"
            valueExpr="cityId" [searchEnabled]="true" formControlName="cityId">
            <dx-validator>
              <dxi-validation-rule type="required" message="City {{mplForm.get('pricingTypeId').value == 3 ? 'From' : ''}} is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12"
        *ngIf="mplForm.get('pricingTypeId').value == 3">
        <div class="dx-field">
          <div>City To <span>*</span>:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="citiesList" 
          placeholder="Choose City To"
            [readOnly]="citiesList?.length == 0" [showClearButton]="false" displayExpr="cityName"
            valueExpr="cityId" [searchEnabled]="true" formControlName="cityTo">
            <dx-validator>
              <dxi-validation-rule type="required" message="City To is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12" *ngIf="mplForm.get('pricingTypeId').value == 4 || mplForm.get('pricingTypeId').value == 5">
        <div class="dx-field">
          <div>Branches From<span>*</span>:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="companyBranchsList"
            placeholder="Choose Branch From" [showClearButton]="true" displayExpr="companyBranchName"
            valueExpr="companyBranchId" [searchEnabled]="true" formControlName="companyBranchId">
            <dx-validator>
              <dxi-validation-rule type="required" message="Branch From is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12" *ngIf="mplForm.get('pricingTypeId').value == 4 || mplForm.get('pricingTypeId').value == 5">
        <div class="dx-field">
          <div>Branches To<span>*</span>:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="companyBranchsList"
            placeholder="Choose Branch To" [showClearButton]="true" displayExpr="companyBranchName"
            valueExpr="companyBranchId" [searchEnabled]="true" formControlName="companyBranchIdTo" (onSelectionChanged)="getAllCustomerCompanyBranchId(mplForm.get('companyBranchId').value)">
            <dx-validator>
              <dxi-validation-rule type="required" message="Branch To is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12" *ngIf="mplForm.get('pricingTypeId').value == 5">
        <div class="dx-field">
          <div>Customers From <span>*</span>: </div>
          <dx-select-box  [inputAttr]="{ autocomplete: 'off' }"
            [items]="customerList" placeholder="Choose Customer From" [showClearButton]="true"
            displayExpr="customerName" valueExpr="customerId" [searchEnabled]="true" formControlName="customerId">
            <dx-validator>
              <dxi-validation-rule type="required" message="Customer From is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12" *ngIf="mplForm.get('pricingTypeId').value == 5">
        <div class="dx-field">
          <div>Customers To <span>*</span>: </div>
          <dx-select-box [readOnly]="customerList?.length == 0" [inputAttr]="{ autocomplete: 'off' }"
            [items]="customerList" placeholder="Choose Customer To" [showClearButton]="true"
            displayExpr="customerName" valueExpr="customerId" [searchEnabled]="true" formControlName="customerIdTo">
            <dx-validator>
              <dxi-validation-rule type="required" message="Customer To is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-12">
        <div class="dx-field">
          <div>Validity From <span>*</span> :</div>
          <dx-date-box formControlName="validityFrom" style="width: 100%" [min]="currentDate"
          [max]="futureDate">
            <dx-validator>
              <dxi-validation-rule type="required" message="Validity From is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </div>
      <div class="col-md-12">
        <div class="dx-field">
          <div>Validity To <span>*</span> :</div>
          <dx-date-box formControlName="validityTo" style="width: 100%"
          (onValueChanged)="onValueChanged2($event)" [min]="mplForm.get('validityFrom').value"
          [max]="diffInDay">
            <dx-validator (onInitialized)="onInitialized2($event)">
              <dxi-validation-rule type="required" message="Validity To is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </div>
      <div class="col-md-12">
        <div class="dx-field">
          <div>Description <span>*</span> :</div>
          <dx-text-box [inputAttr]="{ autocomplete: 'off' }" placeholder="Description"
            formControlName="description">
            <dx-validator>
              <dxi-validation-rule type="required" message="Description To is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <dx-button id="button-serch" text="Copy" type="default"
        [useSubmitBehavior]="true"
        (click)="!mplForm.valid || submitCopyPricing(mplForm)">
      </dx-button>
      </div>
    </div>
  </form>
</div>