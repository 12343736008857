import { ModuleWithProviders, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import {
    AuthGuardService as AuthGuard
} from './auth/auth-guard.service';
import { ErrorComponent } from './template/pages/errors/error/error.component';
import { NotFoundComponent } from './template/pages/errors/not-found/not-found.component';
import { PagesComponent } from './template/pages/pages.component';

export const routes: Routes = [
    {
        path: 'portal',
        component: PagesComponent,
         children: [
            // tslint:disable-next-line: max-line-length
            { path: 'dashboard',
            loadChildren: () => import('./template/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
             ,
             canActivate: [AuthGuard], data: { breadcrumb: 'Dashboard' } },
            { path: 'procurement',
            loadChildren: () => import('./components/procurement/procurement.module').then(m => m.ProcurementModule)
            },
            { path: 'configurations',
            loadChildren: () => import('./components/configurations/configurations.module').then(m => m.ConfigurationsModule),
              data: { breadcrumb: 'Configurations' } },

            { path: 'inventory', 
            loadChildren: () => import('./components/inventory/inventory.module').then(m => m.InventoryModule),
            data: { breadcrumb: 'Inventory' } },
            { path: 'sales', 
            loadChildren: () => import('./components/sales/sales.module').then(m => m.SalesModule)
            , data: { breadcrumb: 'Sales' } },
            { path: 'production',
            loadChildren: () => import('./components/production/production.module').then(m => m.ProductionModule)
             , data: { breadcrumb: 'Production' } },
            { path: 'administration',
            loadChildren: () => import('./components/administration/administration.module').then(m => m.AdministrationModule)
             , data: { breadcrumb: 'Administration' } },
            { path: 'finance',
            loadChildren: () => import('./components/finance/finance.module').then(m => m.FinanceModule)
             , data: { breadcrumb: 'Finance' } },
            { path: 'accounting',
            loadChildren: () => import('./components/accounting/accounting.module').then(m => m.AccountingModule)
             , data: { breadcrumb: 'Accounting' } },
            { path: "crm",
            loadChildren: () => import('./components/crm/crm.module').then(m => m.CrmModule)
             , data: { breadcrumb: 'CRM' } },
            { path: 'reports', 
            loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule)
            , data: { breadcrumb: 'Reports' } },
            { path: 'users-tickets', 
            loadChildren: () => import('./components/user-tickets/user-tickets.module').then(m => m.UsersTicketsModule)
            , data: { breadcrumb: 'Tickets' } }
        ]            
    },
    {
       path: 'authentication',
       loadChildren: () => import('././components/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    { path: 'purchase-order',
    loadChildren: () => import('./components/purchase-order/purchase-order.module').then(m => m.PurchaseOrderdModule),
        data: { breadcrumb: 'Purchase Order' } },
    { path: '', redirectTo: 'authentication/login', pathMatch: 'full' },
    // { path: 'landing', loadChildren: './template/pages/landing/landing.module#LandingModule' },
    { path: 'register',
    loadChildren: () => import('./template/pages/register/register.module').then(m => m.RegisterModule)
 },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: 'no-access', component: NotFoundComponent, data: { breadcrumb: 'No Access' } },
    { path: '**', component: NotFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
// export const routing: ModuleWithProviders  = RouterModule.forRoot(routes, {
//     // preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
//     //  useHash: true
// });
