<div class="filter-page-wrapper" class="full-screen-dialog">
  <h1 mat-dialog-title>
    Report Viewer
    <div class="float-right cursor-pointer" mat-dialog-close>
      <i class="fa fa-close"></i>
    </div>
  </h1>

  <mat-dialog-content class="full-screen-dialog">
    <div id="report-viwer">
      <dx-report-viewer [reportUrl]="reportUrl" height="1000px" *ngIf="reportUrl">
          <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
        </dx-report-viewer>
  </div>
  </mat-dialog-content>
</div>
