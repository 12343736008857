import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

import { AppConstant } from "src/app/app.const";
import { DialogAddRows } from "../dilog/add-rows.component";
import { MatDialog } from "@angular/material/dialog";
import { Papa } from "ngx-papaparse";
import { SharedService } from "../../shared.service";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import notify from "devextreme/ui/notify";

declare var $: any;







@Component({
  selector: "app-bulk-add",
  templateUrl: "./bulk-add.component.html",
  styleUrls: ["./bulk-add.component.scss"],
})
export class BulkAddfComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild("saveSwal") private saveSwal: SwalComponent;
  requestForm: FormGroup;
  @Input() dataSource: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @Output() onResetAll: EventEmitter<any> = new EventEmitter();
  // @Input() itemCatalogId: any;
  // @Input() focalId: any;
  // @Input() itemTypeMasterId: any;
  @Input() companyBranchId: any;
  @Input() acknowledgement: any;
  @Input() hidePriceBtn: boolean;
  loadingIndicator: boolean;
  showGenerateBtn: boolean;
  SPH_RANGE = [];
  ADD_RANGE = [];
  rangeStockListPayload = [];
  isEdit: boolean;
  isCsv: boolean;
  actionSheetVisible: boolean;
  actionSheetTarget: any;
  readonly: boolean;
  isAllZero: boolean;
  templist: any;
  previousStock: boolean;
  crrentStock: number;
  sphError: boolean;
  addError: boolean;
  stockIcon: boolean;
  inputType: boolean;
  totalQTY: number;
  availableStock: number;
  loadingStockAvailable: boolean;
  displayOnly: boolean;
  rangeLoading: boolean;
  showPreviousStock: boolean;
  loadingAllAvailableStock: boolean;
  hideClearBtn: boolean;
  sphRangeList: any[];
  addfRangeList: any[];
  loadingSphRange: boolean;
  loadingAddfRange: boolean;
  priceRange: any[];
  totalAmount: number;
  itemQty: number;
  stockLense: any;
  appCons: AppConstant;
  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.totalQTY = 0;
    this.availableStock = 0;
    this.showGenerateBtn = false;
    this.sphRangeList = [];
    this.addfRangeList = [];
    this.priceRange = [];
    this.totalAmount = 0;
    // this.displayOnly = false;
    this.getSPH(-35, 35);
    this.getADDF(0.25, 4);
    $(document).ready(() => {
      $('#dealInner').removeClass('hide');
      $('#close').addClass('hide');
      const countRightNav = $('.right-nav .card-body').height() + 10;
      const countHeight = '-' + countRightNav + 'px';
      $('.right-nav').css({ bottom: countHeight });
      $('#dealInner').on('click', () => {
        $('.right-nav')
          .stop()
          .animate({
            bottom: '4px',
            slow: '300'
          });
        $('#dealInner').addClass('hide');
        $('#close').removeClass('hide');
      });
      $('#close').on('click', () => {
        var r = $('.right-nav').height() - 50;
        $('.right-nav')
          .stop()
          .animate({
            bottom: '-' + r,
            slow: '300'
          });
        $('#dealInner').removeClass('hide');
        $('#close').addClass('hide');
      });
    });
  }

  ngAfterViewInit() {
    $(document).ready(() => {
        var element = null;
        $('table tr td input').focus((e) => {
           element =  e.target.parentNode.parentNode.parentNode;
           $(element).find('th').css('background-color', 'lightgreen');
        }).blur(() => {
           $(element).find('th').css('background-color', 'inherit');
        })
    })
}

  /**
   * @name getInitForm
   * @description init form
   * @return form controls
   */
  getInitForm() {
    this.requestForm = new FormGroup({
      sections: new FormArray([]),
    });
  }

  /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
  ngOnChanges(changes: SimpleChanges) {
    this.stockLense = [];
    this.totalQTY = 0;
    this.getInitForm();
    if (Object.keys(changes.dataSource.currentValue).length > 0) {
      this.rangeStockListPayload = [];
      this.isAllZero = false;
      // console.log('Add', changes.dataSource.currentValue);
      this.inputType = changes.dataSource.currentValue.inputType;
      this.stockIcon = changes.dataSource.currentValue.stockIcon;
      this.displayOnly = changes.dataSource.currentValue.displayOnly;
      this.showPreviousStock =
        changes.dataSource.currentValue.showPreviousStock;
      this.hideClearBtn = changes.dataSource.currentValue.hideClearBtn;
      // this.totalToggle = changes.dataSource.currentValue.totalToggle;
      this.addSection(changes.dataSource.currentValue);
      this.checkRange();
      if (changes.dataSource.currentValue.isEdit) {
        if (changes.dataSource.currentValue.rangeStockListPayload) {
          this.setInventoryFormData(
            changes.dataSource.currentValue.rangeStockListPayload
          );
          // this.estimatePrice(changes.dataSource.currentValue.rangeStockListPayload);
        }
        changes.dataSource.currentValue.rangeStockListPayload.forEach(element => {
          let obj = {
            qty: element.qty,
            customerId: this.dataSource.customerId,
            itemCatalogGroupId: element.itemCatalogGroupId,
            focalId: element.focalId,
            brandMasterId: element.brandMasterId,
            sideName: element.sideName,
            size: element.size,
            itemName: element.itemName,
            itemTypeMasterId: element.itemTypeMasterId ? element.itemTypeMasterId : 0,
            lensSideId: element.lensSideId,
            salesOrderId: element.salesOrderId ? element.salesOrderId : 0,
            salesOrderLensId: element.salesOrderLensId ? element.salesOrderLensId : 0,
            vendorItemCatalogId: element.vendorItemCatalogId ? element.vendorItemCatalogId : 0,
            sph: element.sph,
            cyl: element.cyl,
            addf: element.addf,
            axis: null,
            isBarcodeSingleSide: false,
            unitPrice: element.unitPrice,
            pricingTypeId: element.pricingTypeId ? element.pricingTypeId : 0,
            mplDetailId: element.mplDetailId ? element.mplDetailId : 0,
            total: element.qty * element.unitPrice,
            uomId: element.uomId,
            isTranspose: false,
            addDateTime: element.addDateTime,
            addUserId: element.addUserId,
            updateDateTime: element.updateDateTime,
            updateUserId: element.updateUserId,
            active: true
          };
          this.stockLense.push(obj);
          this.stockLense.map((item, index) => {
            item["index"] = index;
          });
        });
        this.isEdit = true;
        this.readonly = changes.dataSource.currentValue.readonly;
        this.isAllZero = changes.dataSource.currentValue.isAllZero;
        this.templist = changes.dataSource.currentValue.rangeStockListPayload;
      }
      // if (changes.dataSource.currentValue.isValidate) {
      //   const payload = {
      //     itemCatalogId: this.dataSource.itemCatalogId,
      //     // itemTypeMasterId: this.dataSource.itemTypeMasterId,
      //     focalId: this.dataSource.focalId,
      //     sphmin:
      //       changes.dataSource.currentValue.validateRange.sphmin ||
      //       changes.dataSource.currentValue.validateRange.sphmin == 0
      //         ? changes.dataSource.currentValue.validateRange.sphmin
      //         : 0,
      //     sphmax:
      //       changes.dataSource.currentValue.validateRange.sphmax ||
      //       changes.dataSource.currentValue.validateRange.sphmax == 0
      //         ? changes.dataSource.currentValue.validateRange.sphmax
      //         : 0,
      //     cylmin:
      //       changes.dataSource.currentValue.validateRange.cylmin ||
      //       changes.dataSource.currentValue.validateRange.cylmin == 0
      //         ? changes.dataSource.currentValue.validateRange.cylmin
      //         : 0,
      //     cylmax:
      //       changes.dataSource.currentValue.validateRange.cylmax ||
      //       changes.dataSource.currentValue.validateRange.cylmax == 0
      //         ? changes.dataSource.currentValue.validateRange.cylmax
      //         : 0,
      //     addmin:
      //       changes.dataSource.currentValue.validateRange.addmin ||
      //       changes.dataSource.currentValue.validateRange.addmin == 0
      //         ? changes.dataSource.currentValue.validateRange.addmin
      //         : 0,
      //     addmax:
      //       changes.dataSource.currentValue.validateRange.addmax ||
      //       changes.dataSource.currentValue.validateRange.addmax == 0
      //         ? changes.dataSource.currentValue.validateRange.addmax
      //         : 0,
      //     cxmin:
      //       changes.dataSource.currentValue.validateRange.cxmin ||
      //       changes.dataSource.currentValue.validateRange.cxmin == 0
      //         ? changes.dataSource.currentValue.validateRange.cxmin
      //         : 0,
      //     cxmax:
      //       changes.dataSource.currentValue.validateRange.cxmax ||
      //       changes.dataSource.currentValue.validateRange.cxmax == 0
      //         ? changes.dataSource.currentValue.validateRange.cxmax
      //         : 0,
      //   };
      //   // this.validateBarcodeRequestRange(payload);
      //   this.validateRangeByPower(payload);
      // }
    }
  }

  /**
   * @name getAvailableStock
   * @param {*} e
   * @param {*} sph
   * @param {*} cyl
   * @memberof CylFormComponent
   */
  getAvailableStock(e, sph: any, add: any) {
    this.availableStock = 0;
    this.loadingStockAvailable = true;
    let payload = {
      itemCatalogId: this.dataSource.itemCatalogId,
      sph: sph,
      cylOrAdd: add,
    };
    this.sharedService
      .getAvailableStock(payload)
      .then((response: any) => {
        if (response.data.completed) {
          this.loadingStockAvailable = false;
          this.availableStock = response.data.payload;
          console.log("getAvailableStock", response.data.payload);
          this.actionSheetTarget = "#img-" + e;
          this.actionSheetVisible = true;
        } else {
          this.loadingStockAvailable = false;
          this.availableStock = 0;
          console.error(response);
        }
      })
      .catch((error) => {
        this.availableStock = 0;
        this.loadingStockAvailable = false;
        console.log(error);
      });
  }
  readCSV(event) {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csvData: string = reader.result as string;
        // console.log(csvData);
        this.papa.parse(csvData, {
          complete: (result) => {
            // console.log('Parsed: ', result);
            result.data.map((parentArray, parentArrayIndex) => {
              parentArray.map((value, childArrayIndex) => {
                $(
                  "table tr td #input-" +
                    parentArrayIndex +
                    "-" +
                    childArrayIndex
                ).val(value);
                this.getRangeValues(
                  value,
                  this.ADD_RANGE[childArrayIndex],
                  this.SPH_RANGE[parentArrayIndex]
                );
              });
            });
          },
        });
      };
    }
  }

  /**
   * @name getSPH
   * @param {number} range1
   * @param {number} range2
   * @returns sph range
   * @memberof salesService
   */
  getSPH(range1: number, range2: number) {
    this.loadingSphRange = true;
    this.sharedService
      .getSPH(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.sphRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.sphRangeList = response.data.payload;
            }
          }
          this.loadingSphRange = false;
        }
      })
      .catch((error) => {
        this.loadingSphRange = false;
        console.log(error);
      });
  }
  /**
   * @name getADDF
   * @param {number} range1
   * @param {number} range2
   * @returns addf range
   * @memberof salesService
   */
  getADDF(range1: number, range2: number) {
    this.loadingAddfRange = true;
    this.sharedService
      .getADDF(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.addfRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.addfRangeList = response.data.payload;
            }
          }
          this.loadingAddfRange = false;
        }
      })
      .catch((error) => {
        this.loadingAddfRange = false;
        const option = {
          width: 300,
          displayTime: 5000,
          message: error.message,
          type: "error",
        };
        console.log(error);
        notify(option);
      });
  }
  /**
     // tslint:disable-next-line:no-redundant-jsdoc
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description addSection
     // tslint:disable-next-line:no-redundant-jsdoc
     // tslint:disable-next-line:no-redundant-jsdoc
     * @params data
     * @returns void
     * @description create section array
     */
  addSection(data?: any): void {
    if (data) {
      if (data.active) {
        // console.log(data);
        this.showGenerateBtn = data.generateBtn;
        this.isCsv = data.csv;
        const sectionsList = this.requestForm.get("sections") as FormArray;
        sectionsList.push(this.createSection(data));
      }
    }
  }

  /**
      // tslint:disable-next-line:no-redundant-jsdoc
     * @description createSection
     * @params data
     * @return FormGroup
     * @description section controls
     */
  createSection(data?: any): FormGroup {
    return new FormGroup({
      condition: new FormControl(data ? data.condition : "", []),
      active: new FormControl(data ? data.active : false, []),
      childern: new FormArray(this.addChildern(data.childern)),
    });
  }

  /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description addChildern
     * @params data
     * @returns childern array
     */
  addChildern(data?: any) {
    const formArray = [];
    data.forEach((child) => {
      formArray.push(this.createChild(child));
    });
    return formArray;
  }

  /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description createChild
     * @params child
     * @return  childer controls
     * @deprecated child formcontrols
     */
  createChild(child?): FormGroup {
    return new FormGroup({
      name: new FormControl(child ? child.name : "", []),
      min: new FormControl(child ? child.min : "", [Validators.required]),
      max: new FormControl(child ? child.max : "", [Validators.required]),
      valueType: new FormControl(child.valueType, []),
    });
  }

  /**
   * @description sectionsArray
   * @returns section array controls
   */
  get sectionsArray() {
    return this.requestForm.get("sections") as FormArray;
  }

  /**
   * @description getChildern
   * @params form section item index
   * @returns childern array controls
   */
  getChildern(form) {
    return form.controls.childern.controls;
  }

  customizeText(e) {
    return e.value;
  }
  customizeUnit(e) {
    return "Total: ";
  }
  
    /**
   * @name removeStockLensItem
   * @return void
   */
     removeStockLensItem(index: number) {
      this.stockLense.forEach((item, i) => {
        if (item.index == index) {
          this.stockLense.splice(i, 1);
        }
      });
    }

  /**
   * @description saveStockRequest
   * @params {FormGroup} requestForm
   * @returns payload form cxtypedetail
   */
  saveStockRequest(requestForm: FormGroup) {
    this.loadingIndicator = true;
    const sph_min = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("min").value;
    const sph_max = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
    const add_min = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("min").value;
    const add_max = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("max").value;
    const add_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("valueType").value;
    const sph_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("valueType").value;
      this.stockLense.forEach(element => {
        this.totalAmount = (element.qty * element.unitPrice) + this.totalAmount;
        this.itemQty = element.qty + this.itemQty;
      });
    const payload = {
      sphmin: parseFloat(sph_min),
      sphmax: parseFloat(sph_max),
      addmin: parseFloat(add_min),
      addmax: parseFloat(add_max),
      strSphmin: sph_min,
      strSphmax: sph_max,
      strAddmin: add_min,
      strAddmax: add_max,
      // sphmin: sph_valueType ? -sph_min : sph_min,
      // sphmax: sph_valueType ? -sph_max : sph_max,
      // addmin: add_valueType ? -add_min : add_min,
      // addmax: add_valueType ? -add_max : add_max,
      rangeStockListPayload: this.stockLense,
      totalQTY: this.totalQTY,
    };
    // console.log('requestForm', requestForm.value);
    // console.log('payload', payload);
    if (requestForm.invalid) {
      return false;
    }
    if (this.totalAmount == 0) {
      const option = {
        width: 300,
        displayTime: 5000,
        message: "something was wrong in total amount..",
        type: "error",
      };
      notify(option);
      return false;
    }
    this.optSelected.emit(payload);
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 300);
  }

  /**
     // tslint:disable-next-line:no-redundant-jsdoc
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description clearField
     * @return void
     */
  clearField(): void {
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(0)
      .get("min")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(0)
      .get("max")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(1)
      .get("min")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(1)
      .get("max")
      .setValue("");
    // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
    // while (child.length !== 0) {
    //     child.removeAt(0);
    // }
    this.rangeStockListPayload = [];
    this.SPH_RANGE = [];
    this.ADD_RANGE = [];
  }

  /**
   * @description addRows
   * @return number of rows
   * @description modal popup
   */
  addRows() {
    const dialogRef = this.dialog.open(DialogAddRows, {
      width: "250px",
      data: { label: "ADD" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed', result);
      if (result.rows) {
        // tslint:disable-next-line:radix
        for (let i = 0; i < parseInt(result.rows); i++) {
          // this.createCxTypeDetail();
          this.updatedMaxVal("increment");
        }
      }
    });
  }

  /**
   * @description incrementMaxVal
   * @returns updated max value
   */
  updatedMaxVal(label: string) {
    const val = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
    if (label === "increment") {
      // tslint:disable-next-line:radix
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("max")
        .setValue(parseInt(val) + 1);
      // tslint:disable-next-line:radix
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("max")
        .setValue(parseInt(val) + 1);
    }
    if (label === "decrement") {
      // tslint:disable-next-line:radix
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("max")
        .setValue(parseInt(val) - 1);
      // tslint:disable-next-line:radix
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("max")
        .setValue(parseInt(val) - 1);
    }
  }

  /**
     * @description removeIndex
     // tslint:disable-next-line:no-redundant-jsdoc
     * @params index
     * @return void
     * @description removing row index
     */
  removeIndex(index) {
    // this.SPH_RANGE.splice(index, 1);
    // this.CYL_RANGE.splice(index, 1);
    // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    // item.removeAt(index);
    this.updatedMaxVal("decrement");
    // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
  }

  getRangeValues(val, addf, sph) {
    //   && this.isAllZero
    if (!val) {
      // if value empty then remove it from collection
      for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        if (
          this.rangeStockListPayload[i].addf === addf &&
          this.rangeStockListPayload[i].sph === sph
        ) {
          this.rangeStockListPayload.splice(i, 1);
          break;
        }
      }
      this.totalQTY = this.sharedService.getTotal(
        this.rangeStockListPayload,
        "vendorItemCode"
      );
      // console.log('if value empty then remove it from collection ', this.rangeStockListPayload);
      return;
    }

    // check whether the value already exist then modify it
    if (
      this.rangeStockListPayload.some((o) => o.addf === addf && o.sph === sph)
    ) {
      for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        if (
          this.rangeStockListPayload[i].addf === addf &&
          this.rangeStockListPayload[i].sph === sph &&
          val
        ) {
          this.rangeStockListPayload[i]["vendorItemCode"] = val;
          break;
        }
      }
      this.totalQTY = this.sharedService.getTotal(
        this.rangeStockListPayload,
        "vendorItemCode"
      );
    } else {
      // else not found so push it
      const rangePairObj = {
        cx: null,
        cxtypeId: null,
        vendorItemCode: val,
        cc: null,
        addf: addf,
        axis: null,
        cyl: null,
        ct: null,
        et: null,
        tc: null,
        wt: null,
        sph: sph,
      };
      this.rangeStockListPayload.push(rangePairObj);
      this.totalQTY = this.sharedService.getTotal(
        this.rangeStockListPayload,
        "vendorItemCode"
      );
    }
    // console.log('Else range ', this.rangeStockListPayload);
  }

  checkRange() {
    this.SPH_RANGE = [];
    this.ADD_RANGE = [];
    const sph_min = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("min").value
    );
    const sph_max = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("max").value
    );
    const sph_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("valueType").value;
    const add_min = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("min").value
    );
    const add_max = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("max").value
    );
    const add_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("valueType").value;
    if (sph_max < sph_min) {
      return (this.sphError = true);
    } else {
      this.sphError = false;
      // if ((sph_min && sph_max || sph_min === 0 && sph_max || sph_min === 0 && sph_max === 0)) {
      if (sph_min !== null && sph_max !== null) {
        // (sph_max < sph_min) ? this.isSphInvalid = true : this.isSphInvalid = false;
        // this.SPH_RANGE = this.sharedService.generateRange(sph_min, sph_max, 0.25);
        this.SPH_RANGE = this.sharedService.generateRange(
          sph_min,
          sph_max,
          0.25
        );
        if (this.check_number(sph_min)) {
          this.SPH_RANGE.reverse();
        }
        // console.log('SphRange ', this.SPH_RANGE);
      } else {
        this.SPH_RANGE = null;
      }
    }
    if (add_max < add_min) {
      return (this.addError = true);
    } else {
      this.addError = false;
      // if ((add_min && add_max) || (add_min === 0 && add_max) || (add_min === 0 && add_max === 0)) {
      if (add_min !== null && add_max !== null) {
        // (cyl_max < cyl_min) ? this.isCylInvalid = true : this.isCylInvalid = false;
        this.ADD_RANGE = this.sharedService.generateRange(
          add_min,
          add_max,
          0.25
        );
        // this.ADD_RANGE = this.sharedService.generateRange(add_min, add_max, 0.25);
        // console.log('ADD_RANGE ', this.ADD_RANGE);
        if (this.check_number(add_min)) {
          this.ADD_RANGE.reverse();
        }
      } else {
        this.ADD_RANGE = null;
      }
    }
    const payload = {
      itemCatalogId: this.dataSource.itemCatalogId,
      focalId: this.dataSource.focalId,
      sphmin: sph_min || sph_min == 0
          ? sph_min
          : 0,
      sphmax:
        sph_max ||
        sph_max == 0
          ? sph_max
          : 0,
      cylmin: 0,
      cylmax: 0,
      addmin: add_min || add_min == 0
      ? add_min
      : 0,
      addmax: add_max || add_max == 0
      ? add_max
      : 0,
    };
    // this.validateRangeByPower(payload);
  }

  toggleStock(label: string) {
    switch (label) {
      case "previous":
        this.previousStock = !this.previousStock;
        this.getAllAvailableStock(
          this.companyBranchId,
          this.dataSource.itemCatalogId,
          ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
            .at(0)
            .get("max").value > 0 ? true : false
        );
        break;
      //  case 'current':
      //     this.currentStock = !this.currentStock;
      //     this.setCurrentStock(this.dataSource.rangeStockListPayload);
      //   break;
    }
  }
  /**
   * @name getAllAvailableStock
   * @param {number} companyBranchId
   * @param {number} itemCatalogId
   * @param {number} isPositiveRange
   * @returns
   * @memberof SharedService
   */
  getAllAvailableStock(
    companyBranchId: number,
    itemCatalogId: number,
    isPositiveRange: boolean
  ) {
    this.loadingAllAvailableStock = true;
    this.sharedService
      .getAllAvailableStock(companyBranchId, itemCatalogId, isPositiveRange)
      .then((response: any) => {
        if (response.data.completed) {
          this.checkRange();
          this.loadingAllAvailableStock = false;
          this.setAvailableStock(response.data.payload.availableStock);
        } else {
          this.loadingAllAvailableStock = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.loadingAllAvailableStock = false;
        console.log(error);
      });
  }
  /**
   * @name setCurrentStock
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setAvailableStock(dataSource) {
    // if (this.SPH_RANGE && this.SPH_RANGE.length > 0 && this.CYL_RANGE && this.CYL_RANGE.length > 0) {
    dataSource.forEach((data) => {
      this.SPH_RANGE.forEach((sph, sphIndex) => {
        this.ADD_RANGE.forEach((addf, index) => {
        
            if (data.sph == sph.toString() && data.addf == addf.toString()) {
              setTimeout(() => {
                $("table tr td #availableStock-" + sphIndex + "-" + index).text(
                  data.availableStock
                );
              }, 10);
            }
          });
        });
    });
    // } else {
    //     console.error('Line: 634-SPH AND CYL LENGHT 0');
    // }
  }
  /**
   * @name validateBarcodeRequestRange
   * @param {number} itemCatalogId
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateBarcodeRequestRange(payload) {
    this.rangeLoading = true;
    this.sharedService
      .validateBarcodeRequestRange(payload)
      .then((response: any) => {
        if (response.data.completed && response.data.payload.length > 0) {
          this.rangeLoading = false;
          this.setBarCodeRange(response.data.payload);
        } else {
          this.rangeLoading = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.rangeLoading = false;
        console.log(error);
      });
  }
  /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description setInventoryFormData
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description params SPH_RANGE or CYL_RANGE
     * @returns value
     */
  setInventoryFormData(dataSource) {
    this.SPH_RANGE.forEach((sph, sphIndex) => {
      this.ADD_RANGE.forEach((addf, index) => {
        dataSource.forEach((data) => {
          // tslint:disable-next-line:triple-equals
          if (data.sph == sph.toString() && data.addf == addf.toString()) {
            this.getRangeValues(data.vendorItemCode, addf, sph);
            setTimeout(() => {
              $("table tr td #input-" + sphIndex + "-" + index).val(
                data.vendorItemCode
              );
              if (this.isEdit) {
                this.totalQTY = this.sharedService.getTotal(
                  this.rangeStockListPayload,
                  "vendorItemCode"
                );
              }
            }, 10);
          }
        });
      });
    });``
  }

  /**
   * @name setInventoryFormData
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setBarCodeRange(dataSource) {
    this.SPH_RANGE.forEach((sph, sphIndex) => {
      this.ADD_RANGE.forEach((addf, index) => {
        dataSource.forEach((data) => {
          let s = sph.toString().replace("-", "");
          let add = addf.toString().replace("-", "");
          let sp = data.sph.toFixed(2).replace("-", "");
          let ad = data.addf.toFixed(2).replace("-", "");
          $("table tr td #input-" + sphIndex + "-" + index).attr(
            "disabled",
            "disabled"
          );
          if (sp == s && ad == add) {
            setTimeout(() => {
              console.log("data.status", data.Status);
              $("table tr td #input-" + sphIndex + "-" + index).removeAttr(
                "disabled"
              );
            }, 10);
          }
        });
      });
    });
  }
  toggleGenerateBtn() {
    if (
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .length > 0
    ) {
      const sph_min = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(0)
        .get("min").value;
      const sph_max = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(0)
        .get("max").value;
      const add_min = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(1)
        .get("min").value;
      const add_max = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(1)
        .get("max").value;
      if (
        (sph_min && sph_max && add_min && add_max) ||
        (sph_min === 0 && sph_max && add_min === 0 && add_max) ||
        (sph_min === 0 && sph_max && add_min && add_max) ||
        (add_min === 0 && add_max && sph_min && sph_max)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  get watchRangeStockList() {
    if (this.rangeStockListPayload.length  == 0) {
      this.priceRange = [];
      this.itemQty = 0;
      this.totalAmount = 0;
      return false;
    } else {
      return true;
    }
  }

     /**
   * @name getFnPrice
   * @description price of item
   */
      getFnPrice(
        qty,
        sph: any,
        cyl: any,
        addf: any,
        sphIndex,
        addlIndex
      ) {
        if (
          (sph || sph == 0) &&
          (cyl || cyl == 0 || addf || addf == 0)
        ) {
          if (qty == 0) {
            $("table tr td #input-" + sphIndex + "-" + addlIndex).val(null);
            if (this.stockLense.length > 0) {
              let i = this.stockLense.findIndex(o => o.sph == sph && o.addf == addf);
              this.stockLense.splice(i, 1);
            }
            console.log(this.stockLense);
            const option = {
              width: 300,
              displayTime: 8000,
              message: "Qty must be greater than 0",
              type: "error",
            };
            notify(option);
            return;
          }
            this.sharedService
              .getFnPrice(
                JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId,
                this.dataSource.customerId,
                this.dataSource.itemCatalogId,
                this.dataSource.focalId,
                this.dataSource.brandMasterId,
                sph,
                cyl ? cyl : 0,
                addf ? addf : 0,
                false,
                JSON.parse(localStorage.getItem("selectedCompanyBranch"))
                  .companyBranchId
              )
              .then((response: any) => {
                if (response.data.completed && response.data.payload) {
                  if (response.data.payload.price == 0) {
                    const option = {
                      width: 300,
                      displayTime: 8000,
                      message: "Price not available for selected item!",
                      type: "warning",
                    };
                    notify(option);
                    return;
                  }
                  if (this.stockLense.length > 0) {
                    let stockItem = this.stockLense.find(
                      (item) =>
                        item.itemCatalogGroupId == this.dataSource.itemCatalogId &&
                        item.lensSideId == this.dataSource.lensSideId &&
                        item.sph == sph && item.addf == addf
                    );
                    if (stockItem) {
                      this.saveSwal.swalOptions = {
                        icon: "success",
                        titleText:
                          "Item # " +
                          stockItem.itemCatalogGroupId +
                          " already exits!. Do you want to update Qty?...",
                        showConfirmButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        showCancelButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      };
                      this.saveSwal.fire().then((res: any) => {
                        if (res && res.dismiss == "cancel") {
                          this.loadingIndicator = true;
                          let obj = {
                            qty: qty,
                            customerId: this.dataSource.customerId,
                            itemCatalogGroupId: this.dataSource.itemCatalogId,
                            focalId: this.dataSource.focalId,
                            brandMasterId: this.dataSource.brandMasterId,
                            sideName: this.dataSource.sideName,
                            size: this.dataSource.size,
                            itemName: this.dataSource.itemName,
                            itemTypeMasterId: this.dataSource.itemTypeMasterId,
                            lensSideId: this.dataSource.lensSideId,
                            salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                            salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                            vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                            sph: sph,
                            cyl: null,
                            addf: addf,
                            axis: null,
                            isBarcodeSingleSide: false,
                            unitPrice: response.data.payload.price,
                            pricingTypeId: response.data.payload.pricingTypeId,
                            mplDetailId: response.data.payload.mplDetailId,
                            total: qty * response.data.payload.price,
                            uomId: response.data.payload.uomId,
                            excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                            isTranspose: false,
                            addDateTime: AppConstant.ksaDefaultDate(),
                            addUserId: AppConstant.getUserId(),
                            updateDateTime: AppConstant.ksaDefaultDate(),
                            updateUserId: AppConstant.getUserId(),
                            active: true
                          };
                          this.stockLense.push(obj);
                          this.stockLense.map((item, index) => {
                            item["index"] = index;
                            // item["total"] = item.qty * item.unitPrice;
                          });
                          this.loadingIndicator = false;
                          // return false;
                        } else {
                          let itemQty = this.stockLense[stockItem.index].qty;
                          qty = parseInt(qty) + parseInt(itemQty);
                          this.stockLense.splice(stockItem.index, 1);
                          this.loadingIndicator = true;
                          let obj = {
                            qty: qty,
                            customerId: this.dataSource.customerId,
                            itemCatalogGroupId: this.dataSource.itemCatalogId,
                            focalId: this.dataSource.focalId,
                            brandMasterId: this.dataSource.brandMasterId,
                            sideName: this.dataSource.sideName,
                            size: this.dataSource.size,
                            itemName: this.dataSource.itemName,
                            itemTypeMasterId: this.dataSource.itemTypeMasterId,
                            lensSideId: this.dataSource.lensSideId,
                            salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                            salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                            vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                            sph: sph,
                            cyl: null,
                            addf: addf,
                            axis: null,
                            isBarcodeSingleSide: false,
                            unitPrice: response.data.payload.price,
                            pricingTypeId: response.data.payload.pricingTypeId,
                            mplDetailId: response.data.payload.mplDetailId,
                            total: qty * response.data.payload.price,
                            uomId: response.data.payload.uomId,
                            excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                            isTranspose: false,
                            addDateTime: AppConstant.ksaDefaultDate(),
                            addUserId: AppConstant.getUserId(),
                            updateDateTime: AppConstant.ksaDefaultDate(),
                            updateUserId: AppConstant.getUserId(),
                            active: true
                          };
                          this.stockLense.push(obj);
                          this.stockLense.map((item, index) => {
                            item["index"] = index;
                            // item["total"] = item.qty * item.unitPrice;
                          });
                        
                          this.loadingIndicator = false;
                        }
                      });
                      // const option = {
                      //   width: 300,
                      //   displayTime: 8000,
                      //   message: "Item already added!",
                      //   type: "error",
                      // };
                      // notify(option);
                    } else {
                      this.loadingIndicator = true;
                      let obj = {
                        qty: qty,
                        customerId: this.dataSource.customerId,
                        itemCatalogGroupId: this.dataSource.itemCatalogId,
                        focalId: this.dataSource.focalId,
                        brandMasterId: this.dataSource.brandMasterId,
                        sideName: this.dataSource.sideName,
                        size: this.dataSource.size,
                        itemName: this.dataSource.itemName,
                        itemTypeMasterId: this.dataSource.itemTypeMasterId,
                        lensSideId: this.dataSource.lensSideId,
                        salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                        salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                        vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                        sph: sph,
                        cyl: null,
                        addf: addf,
                        axis: null,
                        isBarcodeSingleSide: false,
                        unitPrice: response.data.payload.price,
                        pricingTypeId: response.data.payload.pricingTypeId,
                        mplDetailId: response.data.payload.mplDetailId,
                        total: qty * response.data.payload.price,
                        uomId: response.data.payload.uomId,
                        excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                        isTranspose: false,
                        addDateTime: AppConstant.ksaDefaultDate(),
                        addUserId: AppConstant.getUserId(),
                        updateDateTime: AppConstant.ksaDefaultDate(),
                        updateUserId: AppConstant.getUserId(),
                        active: true
                      };
                      this.stockLense.push(obj);
                      this.stockLense.map((item, index) => {
                        item["index"] = index;
                        // item["total"] = item.qty * item.unitPrice;
                      });
                      this.loadingIndicator = false;
                    }
                  } else {
                    let obj = {
                      qty: qty,
                      customerId: this.dataSource.customerId,
                      itemCatalogGroupId: this.dataSource.itemCatalogId,
                      focalId: this.dataSource.focalId,
                      brandMasterId: this.dataSource.brandMasterId,
                      sideName: this.dataSource.sideName,
                      size: this.dataSource.size,
                      itemName: this.dataSource.itemName,
                      itemTypeMasterId: this.dataSource.itemTypeMasterId,
                      lensSideId: this.dataSource.lensSideId,
                      salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                      salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                      vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                      sph: sph,
                      cyl: null,
                      addf: addf,
                      axis: null,
                      isBarcodeSingleSide: false,
                      unitPrice: response.data.payload.price,
                      pricingTypeId: response.data.payload.pricingTypeId,
                      mplDetailId: response.data.payload.mplDetailId,
                      total: qty * response.data.payload.price,
                      uomId: response.data.payload.uomId,
                      excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                      isTranspose: false,
                      addDateTime: AppConstant.ksaDefaultDate(),
                      addUserId: AppConstant.getUserId(),
                      updateDateTime: AppConstant.ksaDefaultDate(),
                      updateUserId: AppConstant.getUserId(),
                      active: true
                    };
                    this.stockLense.push(obj);
                    this.stockLense.map((item, index) => {
                      item["index"] = index;
                    });
                  }
                 
                  console.log(this.stockLense)
                  // this.normalStockForm
                  //   .get("filtersInventory")
                  //   .get("unitPrice")
                  //   .setValue(response.data.payload.price);
                  // this.normalStockForm
                  //   .get("filtersInventory")
                  //   .get("uomId")
                  //   .setValue(response.data.payload.uomId);
                  // this.normalStockForm
                  //   .get("filtersInventory")
                  //   .get("excludeBrandDiscount")
                  //   .setValue(response.data.payload.excludeBrandDiscount);
                  // this.normalStockForm
                  //   .get("filtersInventory")
                  //   .get("pricingTypeId")
                  //   .setValue(response.data.payload.pricingTypeId);
                  // this.normalStockForm
                  //   .get("filtersInventory")
                  //   .get("mplDetailId")
                  //   .setValue(response.data.payload.mplDetailId);
                } else {
                  const option = {
                    width: 300,
                    displayTime: 8000,
                    message: "No Price Found!",
                    type: "error",
                  };
                  notify(option);
                  console.error(response);
                }
              })
              .catch((error) => {
                this.loadingIndicator = false;
                const option = {
                  width: 300,
                  displayTime: 8000,
                  message: error.message,
                  type: "error",
                };
                console.log(error);
                notify(option);
              });
        }
      }
  /**
   * @name estimatePrice
   * @param {list} range list
   * @return price
   */
  estimatePrice(rangeStockListPayload: any) {
    if (this.rangeStockListPayload.length > 0) {
      let rangePriceRequest = [];
    let payload = {};
    this.priceRange = [];
    this.totalAmount = 0;
    this.itemQty = 0;
    this.loadingIndicator = true;
    console.log("rangeStockListPayload", rangeStockListPayload);
    rangeStockListPayload.forEach((element) => {
      rangePriceRequest.push({
        sph: element.sph,
        cyl: 0,
        add: element.add ? element.add : 0,
        qty: element.vendorItemCode,
      });
    });
    payload = {
      rangePriceRequestDetail: rangePriceRequest,
      itemCatalogId: this.dataSource.itemCatalogId,
      focalId: this.dataSource.focalId,
      customerId: this.dataSource.customerId,
      companyId: JSON.parse(localStorage.getItem("selectedCompanyBranch"))
        .companyId,
    };
    this.sharedService
      .getRangePrice(payload)
      .then((response: any) => {
        if (response.data.completed) {
          console.log("response.data.payload", response.data.payload);
          this.priceRange = response.data.payload;
          response.data.payload.forEach(element => {
            this.totalAmount = element.amount + this.totalAmount;
            this.itemQty = element.qty + this.itemQty;
          });
        } else {
          const option = {
            width: 300,
            displayTime: 5000,
            message: "Price not found...",
            type: "warning",
          };
          notify(option);
          this.totalAmount = 0;
          this.itemQty = 0;
        }
        this.loadingIndicator = false;
       
      })
      .catch((error) => {
        this.totalAmount = 0;
        this.itemQty = 0;
        this.loadingIndicator = false;
        console.log(error);
      });
    }
    
  }
  /**
   * @name check_number
   * @param num_value
   * @returns bool
   */
  check_number(num_value) {
    if (num_value >= 0) {
      return false;
    } else {
      return true;
    }
  }

    /**
   * @name validateRangeByPower
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateRangeByPower(payload) {
    this.rangeLoading = true;
    this.sharedService
      .validateRangeByPower(payload)
      .then((response: any) => {
        if (response.data.completed && response.data.payload.length > 0) {
          this.rangeLoading = false;
           this.setRanges(response.data.payload);
        } else {
          this.rangeLoading = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.rangeLoading = false;
        console.log(error);
      });
  }
  /**
   * @name setRanges
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setRanges(dataSource) {
    this.SPH_RANGE.forEach((sph, sphIndex) => {
      this.ADD_RANGE.forEach((addf, index) => {
        dataSource.forEach((data) => {
          let s = sph.toString().replace("-", "");
          let sp = data.sph.toFixed(2).replace("-", "");
          let add = addf.toString().replace("-", "");
          let ad = data.add.toFixed(2).replace("-", "");
          $("table tr td #input-" + sphIndex + "-" + index).attr(
            "disabled",
            "disabled"
          );
          if (sp == s && ad == add) {
            setTimeout(() => {
              if (data.isValidated) {
              $("table tr td #input-" + sphIndex + "-" + index).removeAttr(
                "disabled"
              );
              }
            }, 10);
          }
        });
      });
    });
  }

  resetAll() {
    this.dataSource = {};
    this.priceRange = [];
    this.rangeStockListPayload = [];
    this.totalQTY = 0
    this.totalAmount = 0;
    this.requestForm.reset();
    this.onResetAll.emit();
  }
  /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description destroy component
     */
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.subscription.unsubscribe();
  }
}
