// import { L10nModule } from '@progress/kendo-angular-l10n';
import 'hammerjs';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

import { AccessibilityGuard } from './auth/accessibility.guard';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { ApplicationsComponent } from './template/theme/components/applications/applications.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { BlankComponent } from './template/pages/blank/blank.component';
import { BreadcrumbComponent } from './template/theme/components/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { CustomOverlayContainer } from './template/theme/utils/custom-overlay-container';
import { DxPopupModule } from 'devextreme-angular';
import { ErrorComponent } from './template/pages/errors/error/error.component';
import { FlagsMenuComponent } from './template/theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './template/theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './template/theme/components/menu/horizontal-menu/horizontal-menu.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MessagesComponent } from './template/theme/components/messages/messages.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NotFoundComponent } from './template/pages/errors/not-found/not-found.component';
import { PagesComponent } from './template/pages/pages.component';
import { PipesModule } from './template/theme/pipes/pipes.module';
import { SearchComponent } from './template/pages/search/search.component';
import { SharedModule } from './template/shared/shared.module';
import { SidenavComponent } from './template/theme/components/sidenav/sidenav.component';
import { StorageService } from './services/branchstorage.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UserMenuComponent } from './template/theme/components/user-menu/user-menu.component';
import { VerticalMenuComponent } from './template/theme/components/menu/vertical-menu/vertical-menu.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DatePipe } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { OverlayContainer } from '@angular/cdk/overlay';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AgmCoreModule } from '@agm/core';
import { AuthInterceptor } from './Interceptors/auth.interceptor';






// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   wheelPropagation: true,
//   suppressScrollX: true
// };

// import * as Sentry from "@sentry/browser";
// import { environment } from '../environments/environment';
// import { environment } from '../environments/environment.staging';

// Sentry.init({
//   dsn: "https://d5cf922f73324659a0b822bd79c41d23@o433283.ingest.sentry.io/5388114",
//   // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
//   // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
//   // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
//   integrations: [new Sentry.Integrations.TryCatch({
//     XMLHttpRequest: false,
//   })],
// });

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}

//   extractError(error) {
//     // Try to unwrap zone.js error.
//     // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
//     if (error && error.ngOriginalError) {
//       error = error.ngOriginalError;
//     }

//     // We can handle messages and Error objects directly.
//     if (typeof error === "string" || error instanceof Error) {
//       return error;
//     }

//     // If it's http module error, extract as much information from it as we can.
//     if (error instanceof HttpErrorResponse) {
//       // The `error` property of http exception can be either an `Error` object, which we can use directly...
//       if (error.error instanceof Error) {
//         return error.error;
//       }

//       // ... or an`ErrorEvent`, which can provide us with the message but no stack...
//       if (error.error instanceof ErrorEvent) {
//         return error.error.message;
//       }

//       // ...or the request body itself, which we can use as a message instead.
//       if (typeof error.error === "string") {
//         return `Server returned code ${error.status} with body "${error.error}"`;
//       }

//       // If we don't have any detailed information, fallback to the request message itself.
//       return error.message;
//     }

//     // Skip if there's no error, and let user decide what to do with it.
//     return null;
//   }

//   handleError(error) {
//     const extractedError = this.extractError(error) || "Handled unknown error";

//     // Capture handled exception and send it to Sentry.
//     const eventId = Sentry.captureException(extractedError);

//     // When in development mode, log the error to console for immediate feedback.
//     if (!environment.production) {
//       console.error(extractedError);
//     }

//     // Optionally show user dialog to provide details on what happened.
//     Sentry.showReportDialog({ eventId });
//   }
// }

@NgModule({
  imports: [
     MatMomentDateModule,
     BrowserModule,
      BrowserAnimationsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAAYi6itRZ0rPgI76O3I83BhhzZHIgMwPg'
    // }),
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true
    }),
    NgScrollbarModule, 
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    AppRoutingModule,
    PipesModule,
    HttpClientModule,
    DxPopupModule,
    SweetAlert2Module.forRoot(),
    ChartsModule,
    DateInputsModule,
    DropDownsModule,
    InputsModule,
    IntlModule,
    // L10nModule,
    LabelModule,
    PopupModule,
    TreeViewModule
  ],
  // exports: [NgScrollbarModule],
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent
  ],
  // entryComponents: [
  //   VerticalMenuComponent
  // ],
  providers: [
    AppSettings,
    AccessibilityGuard,
    // { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
      { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService, AuthService, AuthGuardService, StorageService,
      // { provide: ErrorHandler, useClass: SentryErrorHandler }
      { provide: LOCALE_ID, useValue: 'en-US' }, // Set the timezone here
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
