<dx-load-panel
[hideOnOutsideClick]="false"
shadingColor="rgba(0,0,0,0.4)"
[(visible)]="loadingReport">
</dx-load-panel>
<form [formGroup]="filterForm">
<div class="card-body">
    <div class="row justify-content-center">
      <div class="col-md-3" *ngIf="vendorEnable">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="Vendor"></kendo-label>
            <kendo-combobox
              [data]="vendorList"
              [clearButton]="true"
              class="w-100"
              [valuePrimitive]="true"
              [valueField]="'id'"
              [textField]="'name'"
              [filterable]="true"
              formControlName="vendorId"
              [tabIndex]="1" [loading]="loadingVendor"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true"
              placeholder="Choose Vendor" 
            (filterChange)="getSearchVendors($event)"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md-2" *ngIf="isCompanyBranchByUser">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="Branch"></kendo-label>
              <kendo-combobox
              [virtual]="true"
              [clearButton]="true"
              class="w-100"
              formControlName="companyBranchId"
              [valuePrimitive]="true"
              [data]="branchList"
              [valueField]="'companyBranchId'"
              [textField]="'companyBranchName'"
              [filterable]="true"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true"
              [tabIndex]="1" (valueChange)="getAllCustomerByCompanyBranchId($event)"
              [placeholder]="'Choose Branch'"
            >
                  <kendo-formerror>Branch is required</kendo-formerror>
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md-3" *ngIf="customerEnable">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="Customer"></kendo-label>
            <kendo-combobox
              [data]="customerList"
              [clearButton]="true"
              class="w-100"
              [valuePrimitive]="true"
              [valueField]="'customerId'"
              [textField]="'customerName'"
              [filterable]="true"
              formControlName="customerId"
              [tabIndex]="2" [loading]="loadingCustomer"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true"
              placeholder="Choose Customer" 
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md-2" *ngIf="poNumber">
        <div class="dx-field">
            <kendo-formfield>
              <kendo-label
                text="Po #. *"
              ></kendo-label>
              <kendo-numerictextbox
                class="w-100"
                [tabIndex]="2"
                [maxlength]="100"
                showErrorIcon="initial"
                [format]="'null'"
                placeholder="Po #." [spinners] = "false"
                formControlName="purchaseOrderId"
              >
              </kendo-numerictextbox>
            </kendo-formfield>
          </div>
    </div>
      <div class="col-md-2" *ngIf="documentNumber">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label
              text="{{docummentTile ? docummentTile : 'Document.#'}}"
            ></kendo-label>
            <kendo-numerictextbox
              class="w-100"
              [spinners]="false"
              [tabIndex]="3"
              [min]="1"
              showErrorIcon="initial"
              SignNotAllowed="-"
              [format]="'null'"
              [rangeValidation]="false"
              formControlName="id"
              placeholder="Sr.#"
            >
            </kendo-numerictextbox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md-2">
        <div class="dx-field">
          <div>Date From <span *ngIf="isDateRangeRequired">*</span>:</div>
          <dx-date-box
            formControlName="fromDate"
            style="width: 100%"
            (onValueChanged)="onValueChanged1($event)"
            [readOnly]="
              !filterForm.get('includeDateFilter').value
            "
            [tabIndex]="4"
          >
            <dx-validator (onInitialized)="onInitialized1($event)">
              <dxi-validation-rule
                type="custom"
                message="From date must come before to date"
                [validationCallback]="dateValidation"
                [reevaluate]="true"
              ></dxi-validation-rule>
              <dxi-validation-rule type="required" message="Date From is  required"></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </div>
      <div class="col-md-2">
        <div class="dx-field">
          <div>Date To <span *ngIf="isDateRangeRequired">*</span>:</div>
          <dx-date-box
            formControlName="toDate"
            style="width: 100%"
            (onValueChanged)="onValueChanged2($event)"
            [readOnly]="
              !filterForm.get('includeDateFilter').value
            "
            [tabIndex]="5"
          >
            <dx-validator (onInitialized)="onInitialized2($event)">
              <dxi-validation-rule
                type="custom"
                message="To date must come before from date"
                [validationCallback]="dateValidation"
                [reevaluate]="true"
              ></dxi-validation-rule>
              <dxi-validation-rule type="required" message="Date To is  required"></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </div>
      <div class="col-md-2" *ngIf="isCompany">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="Company"></kendo-label>
              <kendo-combobox
              [virtual]="true"
              [clearButton]="true"
              class="w-100"
              formControlName="companyId"
              [valuePrimitive]="true"
              [data]="companyList"
              [valueField]="'companyId'"
              [textField]="'companyName'"
              [filterable]="true"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true"
              [tabIndex]="6"
              [placeholder]="'Choose Company'"
            >
                  <kendo-formerror>Branch is required</kendo-formerror>
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md" *ngIf="accountType">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="Account Type"></kendo-label>
            <kendo-combobox
              [data]="accountTypeList"
              [clearButton]="true"
              class="w-100"
              [valuePrimitive]="true"
              [valueField]="'accountId'"
              [textField]="'accountNameAndCode'"
              [filterable]="true"
              formControlName="accountType"
              [tabIndex]="7" [loading]="loadingAccountType"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true" 
              placeholder="Choose Account Type"  (valueChange)="getCOALevel6($event)"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md" *ngIf="fromAccount">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="From Account"></kendo-label>
            <kendo-combobox
              [data]="fromAccountList"
              [clearButton]="true"
              class="w-100"
              [valuePrimitive]="true"
              [valueField]="'accountCode'"
              [textField]="'accountNameAndCode'"
              [filterable]="true"
              formControlName="fromAccountCode"
              [tabIndex]="8" [loading]="loadingAccounts"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true"
              placeholder="Choose From Account" (valueChange)="validateFromAccount()"
            >
            </kendo-combobox>
            <kendo-formerror
            *ngIf="filterForm.get('fromAccountCode').errors?.invalidAccount"
            >From account can't be greater than To account</kendo-formerror
          >
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md" *ngIf="toAccount">
        <div class="dx-field">
          <kendo-formfield>
            <kendo-label text="To Account"></kendo-label>
            <kendo-combobox
              [data]="toAccountList"
              [clearButton]="true"
              class="w-100"
              [valuePrimitive]="true"
              [valueField]="'accountCode'"
              [textField]="'accountNameAndCode'"
              [filterable]="true"
              formControlName="toAccountCode"
              [tabIndex]="9" [loading]="loadingAccounts"
              [kendoDropDownFilter]="{ operator: 'contains' }"
              [suggest]="true"
              placeholder="Choose To Account" (valueChange)="validateFromAccount()"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>
      </div>
    </div>
</div>
<div class="card-footer clearfix">
  <div class="float-left mt-2" *ngIf="showIncludeFilter">
    <div class="d-inline-block">
      Include Date Filter
      <dx-check-box
        formControlName="includeDateFilter" (onValueChanged)="changeFilter($event.value)"
      ></dx-check-box>
    </div>
  </div>
 
  <dx-button
      id="button"
      [text]="'Filter'"
      type="default"
      class="float-right"
      [disabled]="loading"
      (click)="submitFilter()"
    >
    </dx-button>
    <dx-button *ngIf="printEnable"
    id="button"
    [text]="'Print'"
    type="default"
    class="float-right mr-3"
    (click)="submitPrint()"
  >
  </dx-button>
  <dx-button  *ngIf="resetEnable"
    id="button"
    [text]="'Reset'"
    type="default"
    class="float-right mr-3"
    (click)="resetFilter()"
  >
  </dx-button>
</div>
</form>