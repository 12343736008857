import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
  static getUserTimeZoneWithCompanyWise(timeZoneName: string): string {
    const timeZoneMapping: { [key: string]: string } = {
      Riyadh: 'Asia/Riyadh',
      Dubai: 'Asia/Dubai',
      Karachi: 'Asia/Karachi'
    };

    const timeZone = timeZoneMapping[timeZoneName];
    return timeZone 
      ? moment(new Date()).tz(timeZone).format('MM-DD-YYYY HH:mm:ss')
      : moment(new Date()).format('MM-DD-YYYY HH:mm:ss');
  }

  transform(value: Date | string, timeZoneName: string): string {
    return TimezonePipe.getUserTimeZoneWithCompanyWise(timeZoneName);
  }
}


// <p>{{ currentDate | timezone: 'Dubai' }}</p>
// <p>{{ currentDate | timezone: 'Riyadh' }}</p>
// <p>{{ currentDate | timezone: 'Karachi' }}</p>