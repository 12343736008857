<div class="panel">
  <div class="panel-header  d-flex align-items-center" *ngIf="header">
      <i class="fa fa-chevron-left cursor-pointer mr-3" appBackButton aria-hidden="true"  *ngIf="backArrow"></i>
    <ng-content select=".heading"></ng-content>
    <ul class="list-inline m-0"  *ngIf="toggling">
      <li class="list-inline-item cursor-pointer"><i (click)="togglePanel()" class="fa" [ngClass]="{'fa-minus': showPanel, 'fa-plus': !showPanel}" aria-hidden="true"></i></li>
      <!-- <li class="list-inline-item cursor-pointer"><div class="btn-back">Back</div></li> -->
      <!-- <li class="list-inline-item cursor-pointer"> <i class="fa fa-arrows-alt" *ngIf="!toggle" #expand  aria-hidden="true"></i> <i  *ngIf="toggle"   class="fa fa-arrows-alt" #compress aria-hidden="true"></i></li> -->
    </ul>
    
    <ng-content  select=".toolbar">

    </ng-content>
  </div>
  <div class="panel-body" *ngIf="body" [hidden]="!showPanel">
    <!-- FILTERS  -->
    <div class="filter-btn" *ngIf="filters" (click)="filterChangeEvent()"><i class="fa fa-search"></i> Filters</div>
    <div *ngIf="isFilterEnable" class="animated fadeIn">
      <multi-filters [columsType]="filterColumns"  [filterOptions]="panelFilterOptions" [filterDataSource]="filterDataSource" 
      (optSelected)="submitFilter($event)"></multi-filters>
    </div>
    <!-- / FILTERS -->
    <ng-content  select=".body">
      
    </ng-content>
  </div>
  <div class="panel-footer" *ngIf="footer">
    <ng-content  select=".footer">
     
    </ng-content>
  </div>
</div>