import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

import { Router } from "@angular/router";
import { SharedService } from "./../shared.service";

declare var $: any;
@Directive({
  selector: "[UserAccessibility]"
})
export class UserAccessibilityDirective implements OnInit {
  @Input("UserAccessibility") label: any;
  roles: any;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private sharedService: SharedService
  ) {}
  ngOnInit() {
    this.getPermition(this.router.url.replace("/portal/", "").replace('/index', ''));
  }

  /**
   * @function getPermition
   * @returns void
   * @param {*} formUrl
   * @memberof UserAccessibilityDirective
   */
  getPermition(formUrl: any): void {
    this.sharedService.getUserRoles().subscribe(res => {
      res.forEach(component => {
        if (component.formUrl) {
            component.formUrl = component['formUrl'].replace('/index', '');
            if (component.formUrl == formUrl) {
              this.roles = component;
            }
        }
      });
      // this.roles = res.find(x => x.formUrl  ? x.formUrl.replace('/index', '') : '' == formUrl);
      if (JSON.parse(localStorage.getItem("user")).isAdministrator) {
        return true;
      }
      if (this.roles) {
        switch (this.label) {
          case "canDelete":
            if (!this.roles.canDelete) {
              this.renderer.setStyle(
                this.el.nativeElement,
                "display",
                "none"
              );
              $(this.el.nativeElement).remove();
            }
            break;
          case "canAdd":
            if (!this.roles.canAdd) {
              this.renderer.setStyle(
                this.el.nativeElement,
                "display",
                "none"
              );
              $(this.el.nativeElement).remove();
            }
            break;
          case "canView":
            if (!this.roles.canView) {
              this.renderer.setStyle(
                this.el.nativeElement,
                "display",
                "none"
              );
              $(this.el.nativeElement).remove();
            }
            break;
          case "canModify":
            if (!this.roles.canModify) {
              this.renderer.setStyle(
                this.el.nativeElement,
                "display",
                "none"
              );
              $(this.el.nativeElement).remove();
              // tslint:disable-next-line: indent
            }
            break;
        }
      }
      
    });
  }
}
