import { Component, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";

import { MessagesService } from "./messages.service";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MessagesService],
})
export class MessagesComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab: number = 1;
  public notifications: Array<Object>;
  @Input() isBranchChange : boolean;
  constructor(private messagesService: MessagesService) {
    // this.messages = messagesService.getMessages();
    // this.files = messagesService.getFiles();
    // this.meetings = messagesService.getMeetings();
  }

  ngOnInit() {
    this.notifications = [];
    // if (
    //   localStorage.getItem("selectedCompanyBranch") &&
    //   (JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId == 5 || 
    //   JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId == 3 || 
    //   JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId == 1)
    // ) {
      this.getPendingStockTask(
        JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId,
        JSON.parse(localStorage.getItem("user")).userId
      );
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
      if(changes && changes.isBranchChange && changes.isBranchChange.currentValue) {
        this.getPendingStockTask(
          JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId,
          JSON.parse(localStorage.getItem("user")).userId
        );
      }
  }
  openMessagesMenu() {
    this.trigger.openMenu();
    this.selectedTab = 0;
  }

  onMouseLeave() {
    this.trigger.closeMenu();
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  routerLinkEvent(link: any) {
    window.location.href = link;
  }
  /**
   * @name getPendingStockTask
   * @param {number} companyBranchId
   * @param {number} userId
   * @returns notification
   */
  getPendingStockTask(companyBranchId: number, userId: number) {
    this.notifications = [];
    this.messagesService
      .getPendingStockTask(companyBranchId, userId)
      .then((response: any) => {
        if (response.data.completed) {
          this.notifications = response.data.payload;
        }
      });
  }
}
