import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { DialogAddRows } from './dilog/add-rows.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from './../shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cx-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['./form-grid.component.scss']
})
export class FormGridComponent implements OnInit, OnChanges, OnDestroy {
  requestForm: FormGroup;
  @Input() dataSource: any;
  @Input() data: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  loadingIndicator: boolean;
  showGenerateBtn: boolean;
  cxList: any;
  items: any;
  isEdit: boolean;
  cxHeader: any;
  tempcxTypeDetail: any;
  total: number;
  popupTarget: any;
  actionSheetVisible: boolean;
  userRemarks: any;
  showWt: any;
  // tslint:disable-next-line: max-line-length
  constructor(private sharedService: SharedService, public dialog: MatDialog) {}

  ngOnInit() {
    this.total = 0;
    this.showGenerateBtn = false;
    // this.cxHeader = [];
    
    // INIT FORM
    

    // SUBSRIPTION IF DROPWOWN CHANGE
    // this.subscription = this.sharedService.getData().subscribe(dataSource => {
    //    this.dataSource = [];
    //    this.requestForm.removeControl('sections');
    //    this.requestForm.addControl('sections', new FormArray([]));
    //    console.log(this.requestForm.value);
    //    this.dataSource = dataSource;
    //    this.addSection(dataSource);
    //   });

      this.getAllCxTypes();
  }

  getInitForm() {
    this.requestForm = new FormGroup({
      sections: new FormArray([])
    });
  }
  /**
   * @name ngOnChanges
   * @desc Fires when change in input properties are detected
   * @return void
   */
  ngOnChanges (changes: SimpleChanges) {
    this.dataSource = [];
    this.getInitForm();
    if (Object.keys(changes.dataSource.currentValue).length > 0 ) {
      this.addSection(changes.dataSource.currentValue);
      this.tempcxTypeDetail = changes.dataSource.currentValue.cxTypeDetail;
      if (changes.dataSource.currentValue.showWt) {
        this.showWt = true;
            this.cxHeader = [
              {
                name: 'CX Type'
              },
              {
                name: 'Bar Code'
              },
              {
                name: 'CX'
              },
              {
                name: 'TC'
              },
              {
                name: 'CC'
              },
              {
                name: 'CT'
              },
              {
                name: 'ET'
              },
              {
                name: 'ADD'
              },
              {
                name: 'WT'
                // visible itemTypeMasterId == 4
              },
              {
                name: 'Remarks'
              }
            ];
      } else {
        this.showWt = false;
        this.cxHeader = [
          {
            name: 'CX Type'
          },
          {
            name: 'Bar Code'
          },
          {
            name: 'CX'
          },
          {
            name: 'TC'
          },
          {
            name: 'CC'
          },
          {
            name: 'CT'
          },
          {
            name: 'ET'
          },
          {
            name: 'ADD'
          },
          {
            name: 'Remarks'
          }
        ];
      }
      if (changes.dataSource.currentValue.cxTypeDetail && changes.dataSource.currentValue.cxTypeDetail.length) {
        for (
          let i = 0;
          i < changes.dataSource.currentValue.cxTypeDetail.length;
          i++
        ) {
          this.createCxTypeDetail(
            changes.dataSource.currentValue.cxTypeDetail[i]
          );
        }
      } 
      // else {
      //   const option = {
      //     width: 300,
      //     displayTime: 3000,
      //     message: 'Cx Details not found...',
      //     type: 'error'
      //     };
      //     notify(option);
      // }
      if(changes.dataSource.currentValue.isEdit) {
        // this.generateGrid(changes.dataSource.currentValue.childern[0].min, changes.dataSource.currentValue.childern[0].max);
        this.isEdit = true;
        // ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').disable();
        // ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').disable();
       
      }
    }
    }

  /**
   * @name addSection
   * @param data 
   * @returns void
   * @description create section array
   */
  addSection(data?: any): void {
    if (data) {
    // data.forEach((controls: any) => {
    if (data.active) {
        console.log(data);
        this.showGenerateBtn = data.generateBtn;
        this.sectionsArray.push(this.createSection(data));
        // const sectionsList = this.requestForm.get('sections') as FormArray;
        // sectionsList.push(this.createSection(data));
      }
      // });
    }
 }

 /**
  * @name createSection
  * @param data 
  * @return FormGroup
  * @description section controls
  */
 createSection(data?: any): FormGroup {
    return new FormGroup({
      condition: new FormControl(data ? data.condition : '', []),
      active: new FormControl(data ? data.active : false, []),
      childern: new FormArray(this.addChildern(data.childern)),
      cxTypeDetail: new FormArray([])
   });
}

/**
 * @name addChildern
 * @param data
 * @returns childern array 
 */
addChildern(data?: any) {
  const formArray = [];
  data.forEach( child => {
    formArray.push(this.createChild(child));
  });
  return formArray;
}

/**
 * @name createChild
 * @param child 
 * @return  childer controls
 * @deprecated child formcontrols
 */
createChild(child?): FormGroup {
  return new FormGroup({
    name: new FormControl(child ? child.name : '', []),
    min: new FormControl(child ? child.min : '', [Validators.required]),
    max: new FormControl(child ? child.max : '', [Validators.required]),
  });
}

/**
 * @name sectionsArray
 * @returns section array controls
 */
  get sectionsArray() {
    return this.requestForm.get('sections') as FormArray;
  }

  /**
 * @name getChildern
 * @param form section item index
 * @returns childern array controls
 */
   getChildern(form) {
     return form.controls.childern.controls;
  }

    /**
 * @name getCxTypeDetail
 * @param form section item index
 * @returns cxTyeDetail array controls
 */
  getCxTypeDetail(form) {
    return form.controls.cxTypeDetail.controls;
  }

  /**
   * @name createCxTypeDetail
   * @require add cxtypedetail array
   */
  createCxTypeDetail(data?: any) {
    const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    item.push(this.addCxTypeDetail(data));
    
  }

   /**
  * @name addCxTypeDetail
  * @param data 
  * @return FormGroup
  * @description cxtypedetail controls
  */
  addCxTypeDetail(data?: any): FormGroup {
    return  new FormGroup({
      cx: new FormControl(data ? data.cx : '', [ ]),
      cxtypeId: new FormControl(data ? data.cxtypeId : 3, []) ,
      vendorItemCode: new FormControl(data ? data.vendorItemCode : '', []),
      cc: new FormControl(data ? data.cc : '', []),
      add: new FormControl(data ? data.addf : '', []),
      // axis: new FormControl(data ? data.axis : '', []),
      // cyl: new FormControl(data ? data.cyl : '', []),
      ct: new FormControl(data ? data.ct : '', []),
      et: new FormControl(data ? data.et : '', []),
      tc: new FormControl(data ? data.tc : '', []),
      wt: new FormControl(data ? data.wt : '', []),
      remarks: new FormControl(data ? data.remarks : '', []),
      vendorItemCatalogId: new FormControl(data ? data.vendorItemCatalogId : 0, []),
    });
  }

  /**
   * @name saveStockRequest
   * @param {FormGroup} requestForm 
   * @returns payload form cxtypedetail
   */
  saveStockRequest(requestForm: FormGroup) {
    if (requestForm.invalid) {
      return false;
    }
    this.optSelected.emit(requestForm.value);
  }






  /**
   * @name clearField
   * @return void
   */
  clearField(): void {
    const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    while (item.length !== 0) {
      item.removeAt(0);
    }
    // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    // while (item.length !== 0) {
    //   item.removeAt(0);
    // }
    // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
    // while (child.length !== 0) {
    //   child.removeAt(0);
    // }
    ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').setValue('');
    ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue('');
    // ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').setValue('');
    // ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue('');
   
}
/**
 * @description validateSubmitBtn
 * @returns bool
 */
get validateSubmitBtn() {
 let val = false;
   const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    item.controls.forEach((element) => {
    // console.log(element.value);
    if (element.value.vendorItemCode || element.value.add || element.value.cc
       || element.value.ct || element.value.cx || element.value.et || element.value.tc || element.value.wt) {
       val = true;
    }
   });
   return val;
}
/**
 * @description destroy component
 */
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
   // this.subscription.unsubscribe();
}

/**
 * @name generateGrid
 * @param {number} min 
 * @param {number} max 
 * @return grid 
 */
  generateGrid(min: number, max: number) {
    for (let i = min; max >= i ; i++) {
      this.createCxTypeDetail(this.tempcxTypeDetail ? this.tempcxTypeDetail[i] : '');
      //this.validateSubmitBtn;
    }
  }

/**
   * @name getAllCxTypes
   * @description item cx type
   * @method GET
   * @returns Promise
   */
  getAllCxTypes() {
    this.loadingIndicator = true;
    this.sharedService.getAllCxTypes()
        .then((response) => {
          if (response.data.completed) {
            this.cxList = response.data.payload;
            this.loadingIndicator = false;
          }
        })
        .catch((err) => {
          this.loadingIndicator = false;
          console.log('err ', err);
        });
  }
  /**
   * @name addRows 
   * @return number of rows
   * @description modal popup
   */
  addRows() {
    const dialogRef = this.dialog.open(DialogAddRows, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.rows) {
        for (let i = 0; i < parseInt(result.rows); i++) {
            this.createCxTypeDetail();
            this.updatedMaxVal('increment');
        }
     }
    });
  }

  /**
   * @name incrementMaxVal
   * @returns updated max value
   */
  updatedMaxVal(label: string) {
    const val  = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
    if (label === 'increment') {
      return  ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) + 1);
    }
    if (label === 'decrement') {
      return  ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) - 1);
    }
  }

  /**
   * @name removeIndex
   * @param index 
   * @return void
   * @description removing row index
   */
  removeIndex(index) {
    const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    item.removeAt(index);
    this.updatedMaxVal('decrement');
    // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
  }


   /**
   * @name getTotal
   * @param {Array} list
   * @param {key} string
   * @returns total
   */
  getTotal() {
    let tot = 0;
     const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    item.value.forEach(item => {
      let val = parseInt(item.cx ? item.cx : 0) + parseInt(item.cc ? item.cc : 0) + parseInt(item.add ? item.add : 0) + parseInt(item.ct ? item.ct : 0)
       + parseInt(item.et ? item.et : 0) + parseInt(item.tc ? item.tc : 0) + parseInt(item.wt ? item.wt : 0);
       tot += val;
    });
    this.total =  tot;
}
/**
   * @name showRemarks
   * @param {FormGroup} section
   * @param {number} index
   * @returns void
   */
showRemarks(section: FormGroup, index: number) {
  this.userRemarks = null;
  this.popupTarget = '#comment-' + index;
  this.actionSheetVisible = true;
  this.userRemarks = section.value.cxTypeDetail[index].remarks;
}
}
