import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";

import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userTimeZone = moment.tz.guess();
    const date = new Date();
    const timeZoneName = new Intl.DateTimeFormat("en-US", {
      timeZone: userTimeZone,
      timeZoneName: "long",
    })
      .formatToParts(date)
      .find((part) => part.type === "timeZoneName").value;

    let headers = {};
    try {
      const selectedCompanyBranch = JSON.parse(localStorage.getItem("selectedCompanyBranch") || "{}");
      const selectedUserObj = JSON.parse(localStorage.getItem("user") || "{}");
      if (selectedCompanyBranch && selectedUserObj) {
        headers = {
          CompanyId: selectedCompanyBranch.companyId.toString(),
          CompanyBranchId: selectedCompanyBranch.companyBranchId.toString(),
          UserId: selectedUserObj.userId.toString(),
          UserDateTime: new Date().toString(),
          UserTimeZone: userTimeZone.toString(),
          TimeZoneName: selectedCompanyBranch.timeZoneName,
          timeZoneId: selectedCompanyBranch.timeZoneId.toString(),
          Authorization :  localStorage.getItem("token"),
          // 'Content-Type': "application/json; charset=utf-8",
        //     Accept: "application/json",
        };
      }
    } catch (error) {
      console.error("Error parsing local storage items", error);
    }

    req = req.clone({
      setHeaders: headers,
    });

    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
            //we can handle response here generally
          // if (evt.body?.isSessionExpired) {
          //     this._router.navigate(['/auth/sign-in'])
          //   }
        }
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          //to handle errors
          //console.error('error status = ', error.status);
          // console.log('---> filter:', req.params.get('filter'));

          if (error.status >= 300 && error.status !== 404) {
            // redirect to error page
            // OR you can use a toast
            // console.error('redirect');
            // this.router.navigate(['http-error'], { queryParams: { status: error.status } });
          }
          console.log("Here is Error in INTERCEPTOR", error);
        }
        return throwError(error);
      })
    );
  }
}
