<div class="dx-grid-container" id="dx-grid">
  <dx-load-panel
    [hideOnOutsideClick]="false"
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="loading"
  >
  </dx-load-panel>
  <app-panel [footer]="false" [backArrow]="false" *ngIf="focalId == 1">
    <div class="heading">Both</div>
    <div class="body">
      <div class="row">
        <div class="col-md-12">
          <div class="upload-csv">
            <button type="button" mat-button (click)="csvfileInput.click()"
            matTooltip="directly upload csv file" matTooltipPosition="left">
            <i class="material-icons">cloud_upload</i>
            <span class="ml-2">upload csv</span>
            <input #csvfileInput type="file" accept=".csv" (change)="readCSV($event, 'pivotListBoth')" style="display:none;" />
          </button>
          </div>
          <dx-data-grid
            [dataSource]="pivotListBoth"
            [allowColumnReordering]="true"
            id="cx-grid"
            [allowColumnResizing]="true"
            [hoverStateEnabled]="true"
            [showBorders]="true"
            [columnAutoWidth]="true"
            [customizeColumns]="customizeColumns"
            (onEditingStart)="setColumnDataType($event)" (onExporting)="onExporting($event)" 
          >
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-column-chooser
              [enabled]="true"
              mode="select"
            ></dxo-column-chooser>
            <dxo-pager
              [showPageSizeSelector]="true"
              [showInfo]="true"
              [allowedPageSizes]="[5, 10, 20]"
            ></dxo-pager>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxo-export [enabled]="true" fileName="both"></dxo-export>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </app-panel>
  <app-panel
    [footer]="false"
    [backArrow]="false"
    *ngIf="focalId != 1 && focalId > 0"
  >
    <div class="heading">Right</div>
    <div class="body">
      <div class="row">
        <div class="col-md-12">
          <div class="upload-csv">
            <button type="button" mat-button (click)="csvfileInput.click()"
            matTooltip="directly upload csv file" matTooltipPosition="left">
            <i class="material-icons">cloud_upload</i>
            <span class="ml-2">upload csv</span>
            <input #csvfileInput type="file" accept=".csv" (change)="readCSV($event, 'pivotListRight')" style="display:none;" />
          </button>
          </div>
          <dx-data-grid
            [dataSource]="pivotListRight"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true"
            [hoverStateEnabled]="true"
            [showBorders]="true"
            [columnAutoWidth]="true"
            [customizeColumns]="customizeColumns"
            (onEditingStart)="setColumnDataType($event)" (onExporting)="onExporting($event)" 
          >
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-column-chooser
              [enabled]="true"
              mode="select"
            ></dxo-column-chooser>
            <dxo-pager
              [showPageSizeSelector]="true"
              [showInfo]="true"
              [allowedPageSizes]="[5, 10, 20]"
            ></dxo-pager>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxo-export [enabled]="true" fileName="right"></dxo-export>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </app-panel>

  <div class="mt-3"></div>
  <app-panel
    [footer]="false"
    [backArrow]="false"
    *ngIf="focalId != 1 && focalId > 0"
  >
    <div class="heading">Left</div>
    <div class="body">
      <div class="row">
        <div class="col-md-12">
          <div class="upload-csv">
            <button type="button" mat-button (click)="csvfileInput.click()"
            matTooltip="directly upload csv file" matTooltipPosition="left">
            <i class="material-icons">cloud_upload</i>
            <span class="ml-2">upload csv</span>
            <input #csvfileInput type="file" accept=".csv" (change)="readCSV($event, 'pivotListLeft')" style="display:none;" />
          </button>
          </div>
          <dx-data-grid
            [dataSource]="pivotListLeft"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true"
            [hoverStateEnabled]="true"
            [showBorders]="true"
            [columnAutoWidth]="true"
            [customizeColumns]="customizeColumns" (onExporting)="onExporting($event)"
          >
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-column-chooser
              [enabled]="true"
              mode="select"
            ></dxo-column-chooser>
            <dxo-pager
              [showPageSizeSelector]="true"
              [showInfo]="true"
              [allowedPageSizes]="[5, 10, 20]"
            ></dxo-pager>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxo-export [enabled]="true" fileName="left"></dxo-export>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </app-panel>
  <div class="mt-3"></div>
  <dx-speed-dial-action
    icon="add"
    label="Save"
    [index]="1"
    (onClick)="savebothSidesForm()"
  >
  </dx-speed-dial-action>
</div>
