import { Papa } from 'ngx-papaparse';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { DialogAddRows } from '../dilog/add-rows.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from './../../shared.service';

declare var $: any;




@Component({
    selector: 'app-cyl-with-checbox',
    templateUrl: './cyl-with-checbox.component.html',
    styleUrls: ['./cyl-with-checbox.component.scss']
})
export class CylWithChecboxComponent implements OnInit, OnChanges, OnDestroy {
    requestForm: FormGroup;
    @Input() dataSource: any;
    @Output() optSelected: EventEmitter<any> = new EventEmitter();
    @Input() itemCatalogId: any;
    loadingIndicator: boolean;
    showGenerateBtn: boolean;
    isEdit: boolean;
    SPH_RANGE = [];
    CYL_RANGE = [];
    rangeStockListPayload = [];
    toggleTypesOfValue;
    isCsv: boolean;
    actionSheetVisible: boolean;
    actionSheetTarget: any;
    sphError: boolean;
    cylError: boolean;
    stockIcon: boolean;
    inputType: string;
    totalQTY: number;
    totalToggle: boolean;
    loadingStockAvailable: boolean;
    availableStock: number;
    previousStock: boolean;
    currentStock: boolean;
    showPreviousStock: boolean;
    showCurrentStock: boolean;
    readonly: boolean;
    displayOnly: boolean;
    constructor(
        private sharedService: SharedService,
        public dialog: MatDialog,
        private papa: Papa
    ) {

    }

    ngOnInit() {
        this.totalQTY = 0;
        this.availableStock = 0;
        this.showGenerateBtn = false;
        // this.displayOnly = false;
    }

    readCSV(event) {
        let files: FileList = event.target.files;
        if (files && files.length > 0) {
            let file: File = files.item(0);
            let reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                let csvData: string = reader.result as string;
                // console.log(csvData);
                this.papa.parse(csvData, {
                    complete: (result) => {
                        // console.log('Parsed: ', result);
                        result.data.map((parentArray, parentArrayIndex) => {
                            parentArray.map((value, childArrayIndex) => {
                                $('table tr td #input-' + parentArrayIndex + '-' + childArrayIndex).val(value);
                                this.getRangeValues(value, this.CYL_RANGE[childArrayIndex], this.SPH_RANGE[parentArrayIndex])
                            })
                        });
                    }
                });
            }
        }
    }

    getInitForm() {
        this.requestForm = new FormGroup({
            sections: new FormArray([])
        });
    }

    /**
     * @name ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
        this.getInitForm();
        if (Object.keys(changes.dataSource.currentValue).length > 0) {
            this.rangeStockListPayload = [];
            // console.log('cyl form', changes.dataSource.currentValue)
            this.inputType = changes.dataSource.currentValue.inputType;
            this.stockIcon = changes.dataSource.currentValue.stockIcon;
            this.totalToggle = changes.dataSource.currentValue.totalToggle;
            this.showCurrentStock = changes.dataSource.currentValue.showCurrentStock;
            this.showPreviousStock = changes.dataSource.currentValue.showPreviousStock;
            this.readonly = changes.dataSource.currentValue.readonly;
            this.displayOnly = changes.dataSource.currentValue.displayOnly;
            this.isEdit = changes.dataSource.currentValue.isEdit;
            this.addSection(changes.dataSource.currentValue);
            this.checkRange();

            if (changes.dataSource.currentValue.isEdit) {
                if (changes.dataSource.currentValue.rangeStockListPayload) {
                    this.setInventoryFormData(changes.dataSource.currentValue.rangeStockListPayload);
                }
                
            }
        }
    }

    /**
     * @name addSection
     * @param data
     * @returns void
     * @description create section array
     */
    addSection(data?: any): void {
        if (data) {
            if (data.active) {
                // console.log(data);
                this.showGenerateBtn = data.generateBtn;
                this.isCsv = data.csv;
                this.sectionsArray.push(this.createSection(data))
                // const sectionsList = this.requestForm.get('sections') as FormArray;
                // sectionsList.push(this.createSection(data));
                // console.log(' this.requestForm', this.requestForm.value)
                // console.log(' sectionsList', sectionsList)

            }
        }
    }

    /**
     * @name createSection
     * @param data
     * @return FormGroup
     * @description section controls
     */
    createSection(data?: any): FormGroup {
        return new FormGroup({
            condition: new FormControl(data ? data.condition : '', []),
            active: new FormControl(data ? data.active : false, []),
            childern: new FormArray(this.addChildern(data.childern)),
        });
    }


    /**
     * @name addChildern
     * @param data
     * @returns childern array
     */
    addChildern(data?: any) {
        const formArray = [];
        data.forEach(child => {
            formArray.push(this.createChild(child));
        });
        return formArray;
    }

    /**
     * @name createChild
     * @param child
     * @return  childer controls
     * @deprecated child formcontrols
     */
    createChild(child?): FormGroup {
        return new FormGroup({
            name: new FormControl(child ? child.name : '', []),
            min: new FormControl(child ? child.min : '', [Validators.required]),
            max: new FormControl(child ? child.max : '', [Validators.required]),
            // min: new FormControl(child ? (child.valueType ? child.min : child.min.toString().replace('-','')) : '', [Validators.required]),
            // max: new FormControl(child ? (child.valueType ? child.max : child.max.toString().replace('-','')) : '', [Validators.required]),
            valueType: new FormControl(child.valueType, [])
            // valueType: new FormControl(child.name === 'CYL' ? true : false, [])
        });
    }


    /**
     * @name getAvailableStock
     * @param {*} e
     * @param {*} sph
     * @param {*} cyl
     * @memberof CylFormComponent
     */
    getAvailableStock(e, sph: any, cyl: any) {
        this.availableStock = 0;
        this.loadingStockAvailable = true;
        let payload = {
            itemCatalogId: this.itemCatalogId,
            sph: sph,
            cylOrAdd: cyl
        };
        this.sharedService.getAvailableStock(payload)
            .then((response: any) => {
                if (response.data.completed) {
                    this.loadingStockAvailable = false;
                    this.availableStock = response.data.payload;
                    console.log('getAvailableStock', response.data.payload);
                    this.actionSheetTarget = '#img-' + e;
                    this.actionSheetVisible = true;
                } else {
                    this.loadingStockAvailable = false;
                    this.availableStock = 0;
                    console.error(response);
                }
            })
            .catch(error => {
                this.availableStock = 0;
                this.loadingStockAvailable = false;
                console.log(error);

            });

    }
    /**
     * @name sectionsArray
     * @returns section array controls
     */
    get sectionsArray() {
        return this.requestForm.get('sections') as FormArray;
    }

    /**
     * @name getChildern
     * @param form section item index
     * @returns childern array controls
     */
    getChildern(form) {
        return form.controls.childern.controls;
    }

    /**
     * @name saveStockRequest
     * @param {FormGroup} requestForm
     * @returns payload form cxtypedetail
     */
    saveStockRequest(requestForm: FormGroup) {
        const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
        const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        const cyl_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
        const cyl_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
        const cyl_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value;
        const sph_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('valueType').value;
        const payload = {
            sphmin: sph_valueType ? -sph_min : sph_min,
            sphmax: sph_valueType ? -sph_max : sph_max,
            cylmin: cyl_valueType ? -cyl_min : cyl_min,
            cylmax: cyl_valueType ? -cyl_max : cyl_max,
            rangeStockListPayload: this.rangeStockListPayload,
            totalQTY: this.totalQTY
        };
        // console.log('requestForm', requestForm.value);
        // console.log('payload', payload);
        if (requestForm.invalid) {
            return false;
        }
        this.optSelected.emit(payload);
    }


    /**
     * @name clearField
     * @return void
     */
    clearField(): void {
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue('');
        // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
        // while (child.length !== 0) {
        //     child.removeAt(0);
        // }
        this.rangeStockListPayload = [];
        this.SPH_RANGE = [];
        this.CYL_RANGE = [];
    }
    get availableStockInHand() {
        return Math.floor(Math.random() * 500);
    }

    /**
     * @name addRows
     * @return number of rows
     * @description modal popup
     */
    addRows() {
        const dialogRef = this.dialog.open(DialogAddRows, {
            width: '250px',
            data: { label: 'CYL' }
        });

        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed', result);
            if (result.rows) {
                for (let i = 0; i < parseInt(result.rows); i++) {
                    // this.createCxTypeDetail();
                    this.updatedMaxVal('increment');
                }
            }
        });
    }

    /**
     * @name incrementMaxVal
     * @returns updated max value
     */
    updatedMaxVal(label: string) {
        const val = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        if (label === 'increment') {
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) + 1);
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue(parseInt(val) + 1);
        }
        if (label === 'decrement') {
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) - 1);
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue(parseInt(val) - 1);
        }
    }

    /**
     * @name removeIndex
     * @param index
     * @return void
     * @description removing row index
     */
    removeIndex(index) {
        // this.SPH_RANGE.splice(index, 1);
        // this.CYL_RANGE.splice(index, 1);
        // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
        // item.removeAt(index);
        this.updatedMaxVal('decrement');
        // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
    }

    getRangeValues(val, cyl, sph,cylIndex?: number, sphIndex?: number) {
        let value = $('table tr td #input-' + sphIndex + '-' + cylIndex).is(':checked');
        // if (!value) {
        //     // if value empty then remove it from collection
        //     for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        //         if (
        //             this.rangeStockListPayload[i].cyl === cyl &&
        //             this.rangeStockListPayload[i].sph === sph
        //         ) {
        //             this.rangeStockListPayload.splice(i, 1);
        //             break;
        //         }
        //     }
        //     this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        //     // console.log('rangeStockListPayload ', this.rangeStockListPayload);
        //     return;
        // }
        // check whether the value already exist then modify it
        if (this.rangeStockListPayload.some(o => o.cyl === cyl && o.sph === sph)) {
            for (let i = 0; i < this.rangeStockListPayload.length; i++) {
                if (
                    this.rangeStockListPayload[i].cyl === cyl &&
                    this.rangeStockListPayload[i].sph === sph
                ) {
                    
                    if ($('table tr td #input-' + sphIndex + '-' + cylIndex).is(':checked')) {
                        this.rangeStockListPayload[i]['active'] = true;
                        $('table tr td #input-' + sphIndex + '-' + cylIndex).parent().parent().parent().css('background', '#5cb85c');
                      } else {
                        this.rangeStockListPayload[i]['active'] = false;
                        $('table tr td #input-' + sphIndex + '-' + cylIndex).parent().parent().parent().css('background', '#d9534f');
                      }
                    break;
                }
            }
            this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        } else {
            // else not found so push it
            const rangePairObj = {
                cx: null,
                active: value,
                add: null,
                cyl: cyl,
                sph: sph,
                // qty: eval(val)
            };
            //this.totalQTY += val;
            this.rangeStockListPayload.push(rangePairObj);
            this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        }
        // console.log('rangeStockListPayload ', this.rangeStockListPayload);

    }

    checkRange() {
        this.SPH_RANGE = [];
        this.CYL_RANGE = [];
        const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
        const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        const sph_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('valueType').value;
        const cyl_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
        const cyl_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
        const cyl_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value;
        if (sph_max < sph_min) {
            return this.sphError = true;
        } else {
            this.sphError = false;
            if ((sph_min && sph_max || sph_min === 0 && sph_max || sph_min === 0 && sph_max == 0)) {
                this.SPH_RANGE = this.sharedService.generateRange(sph_valueType ? -sph_max : sph_min, sph_valueType ? -sph_min : sph_max, 0.25);
                // console.log('SphRange ', this.SPH_RANGE);
                if (sph_valueType) {
                    this.SPH_RANGE.reverse();
                }
            } else {
                this.SPH_RANGE = [];
            }
        }
        if (cyl_max < cyl_min) {
            return this.cylError = true;
        } else {
            this.cylError = false;
            if ((cyl_min && cyl_max || cyl_min === 0 && cyl_max || cyl_min === 0 && cyl_max === 0)) {
                // (cyl_max < cyl_min) ? this.isCylInvalid = true : this.isCylInvalid = false;
                this.CYL_RANGE = this.sharedService.generateRange(cyl_valueType ? -cyl_max : cyl_min, cyl_valueType ? -cyl_min : cyl_max, 0.25);
                if (cyl_valueType) {
                    this.CYL_RANGE.reverse();
                }
                // console.log('CylRange ', this.CYL_RANGE);
            } else {
                this.CYL_RANGE = [];
            }
        }
        //  this.fixVerticalTabindex('.reset-tabindex');
        if (!this.isEdit && this.SPH_RANGE.length > 0 && this.CYL_RANGE.length > 0) {
            this.SPH_RANGE.forEach((sph, sphIndex) => {
                this.CYL_RANGE.forEach((cyl, cylIndex) => {
                    setTimeout(() => {
                        $('table tr td #input-' + sphIndex + '-' + cylIndex).prop("checked", true).parent().parent().parent().css('background', '#5cb85c');
                        this.getRangeValues(true, cyl, sph, cylIndex, sphIndex);
                    }, 10);
                });
            });
        }

    }

    toggleStock(label: string) {
        switch (label) {
            case 'previous':
                this.previousStock = !this.previousStock;
                break;
            case 'current':
                this.currentStock = !this.currentStock;
                break;
        }
    }

    /**
     * @name setInventoryFormData
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setInventoryFormData(dataSource) {
        this.SPH_RANGE.forEach((sph, sphIndex) => {
            this.CYL_RANGE.forEach((cyl, index) => {
                dataSource.forEach(data => {
                    if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
                        setTimeout(() => {
                            $('table tr td #input-' + sphIndex + '-' + index).prop("checked", data.active);
                            this.getRangeValues(data.active, cyl, sph, index, sphIndex);
                            if (data.active) {
                                $('table tr td #input-' + sphIndex + '-' + index).parent().parent().parent().css('background', '#5cb85c');
                            } else {
                                $('table tr td #input-' + sphIndex + '-' + index).parent().parent().parent().css('background', '#d9534f');
                            }
                            if (this.isEdit) {
                                this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
                            }
                        }, 10);
                    }
                });
            });
        });

    }

    toggleGenerateBtn() {
        if (((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).length > 0) {
            const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
            const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
            const cyl_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
            const cyl_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
            if (
                (sph_min && sph_max && cyl_min && cyl_max) ||
                (sph_min === 0 && sph_max && cyl_min === 0 && cyl_max) ||
                (sph_min === 0 && sph_max && cyl_min && cyl_max) ||
                (cyl_min === 0 && cyl_max && sph_min && sph_max) || (sph_min === 0 && sph_max === 0 && cyl_min === 0 && cyl_max === 0)) {
                return true;
            } else {
                return false;
            }
        }
    }

    fixVerticalTabindex(selector) {
        if (typeof selector == 'undefined') {
            selector = '.reset-tabindex';
        }
        let tabindex = 1;
        $(selector).each((i, tbl) => {
            $(tbl).find('tr').first().find('td').each((clmn, el) => {
                $(tbl).find('tr td:nth-child(' + (clmn + 1) + ') div input').each((j, input) => {
                    $(input).attr('placeholder', tabindex);
                    $(input).attr('tabindex', tabindex++);
                });
            });
        });
    }
    /**
     * @name destroy component
     */
    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        // this.subscription.unsubscribe();
    }
}
