import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@progress/kendo-angular-inputs";

import { SharedService } from "../shared.service";

@Component({
  selector: "app-address-prof",
  templateUrl: "./address-prof.component.html",
  styleUrls: ["./address-prof.component.scss"],
})
export class AddressProfComponent implements OnInit {
  addressHolderForm: FormGroup;
  public maxlength = 1;
  districList: any[];
  cityList: any[];
  countryList: any[];
  @Input() data: any;
  @Input() ceoData: any;

  @Output() valueChange = new EventEmitter();
  @ViewChild("nameEng") nameEng: TextBoxComponent;
  @ViewChild("nameArb") nameArb: TextBoxComponent;
  @ViewChild("streetEng") streetEng: TextBoxComponent;
  @ViewChild("streetArb") streetArb: TextBoxComponent;
  @ViewChild("districtArb") districtArb: TextBoxComponent;
  @ViewChild("cityArb") cityArb: TextBoxComponent;
  @ViewChild("countryArb") countryArb: TextBoxComponent;
  @ViewChild("idNo1") idNo1: NumericTextBoxComponent;
  @ViewChild("idNo2") idNo2: NumericTextBoxComponent;
  @ViewChild("idNo3") idNo3: NumericTextBoxComponent;
  @ViewChild("idNo4") idNo4: NumericTextBoxComponent;
  @ViewChild("idNo5") idNo5: NumericTextBoxComponent;
  @ViewChild("idNo6") idNo6: NumericTextBoxComponent;
  @ViewChild("idNo7") idNo7: NumericTextBoxComponent;
  @ViewChild("idNo8") idNo8: NumericTextBoxComponent;
  @ViewChild("idNo9") idNo9: NumericTextBoxComponent;
  @ViewChild("idNo10") idNo10: NumericTextBoxComponent;
  @ViewChild("shortAdd1") shortAdd1: NumericTextBoxComponent;
  @ViewChild("shortAdd2") shortAdd2: NumericTextBoxComponent;
  @ViewChild("shortAdd3") shortAdd3: NumericTextBoxComponent;
  @ViewChild("shortAdd4") shortAdd4: NumericTextBoxComponent;
  @ViewChild("shortAdd5") shortAdd5: NumericTextBoxComponent;
  @ViewChild("shortAdd6") shortAdd6: NumericTextBoxComponent;
  @ViewChild("shortAdd7") shortAdd7: NumericTextBoxComponent;
  @ViewChild("shortAdd8") shortAdd8: NumericTextBoxComponent;
  @ViewChild("buildingNo1Eng") buildingNo1Eng: NumericTextBoxComponent;
  @ViewChild("buildingNo2Eng") buildingNo2Eng: NumericTextBoxComponent;
  @ViewChild("buildingNo3Eng") buildingNo3Eng: NumericTextBoxComponent;
  @ViewChild("buildingNo4Eng") buildingNo4Eng: NumericTextBoxComponent;
  @ViewChild("buildingNo1Arb") buildingNo1Arb: NumericTextBoxComponent;
  @ViewChild("buildingNo2Arb") buildingNo2Arb: NumericTextBoxComponent;
  @ViewChild("buildingNo3Arb") buildingNo3Arb: NumericTextBoxComponent;
  @ViewChild("buildingNo4Arb") buildingNo4Arb: NumericTextBoxComponent;
  @ViewChild("sceondaryNo1Eng") sceondaryNo1Eng: NumericTextBoxComponent;
  @ViewChild("sceondaryNo2Eng") sceondaryNo2Eng: NumericTextBoxComponent;
  @ViewChild("sceondaryNo3Eng") sceondaryNo3Eng: NumericTextBoxComponent;
  @ViewChild("sceondaryNo4Eng") sceondaryNo4Eng: NumericTextBoxComponent;
  @ViewChild("sceondaryNo1Arb") sceondaryNo1Arb: NumericTextBoxComponent;
  @ViewChild("sceondaryNo2Arb") sceondaryNo2Arb: NumericTextBoxComponent;
  @ViewChild("sceondaryNo3Arb") sceondaryNo3Arb: NumericTextBoxComponent;
  @ViewChild("sceondaryNo4Arb") sceondaryNo4Arb: NumericTextBoxComponent;
  @ViewChild("postalCodeNo1Eng") postalCodeNo1Eng: NumericTextBoxComponent;
  @ViewChild("postalCodeNo2Eng") postalCodeNo2Eng: NumericTextBoxComponent;
  @ViewChild("postalCodeNo3Eng") postalCodeNo3Eng: NumericTextBoxComponent;
  @ViewChild("postalCodeNo4Eng") postalCodeNo4Eng: NumericTextBoxComponent;
  @ViewChild("postalCodeNo5Eng") postalCodeNo5Eng: NumericTextBoxComponent;
  @ViewChild("postalCodeNo1Arb") postalCodeNo1Arb: NumericTextBoxComponent;
  @ViewChild("postalCodeNo2Arb") postalCodeNo2Arb: NumericTextBoxComponent;
  @ViewChild("postalCodeNo3Arb") postalCodeNo3Arb: NumericTextBoxComponent;
  @ViewChild("postalCodeNo4Arb") postalCodeNo4Arb: NumericTextBoxComponent;
  @ViewChild("postalCodeNo5Arb") postalCodeNo5Arb: NumericTextBoxComponent;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.districList = [];
    this.cityList = [];
    this.countryList = [];
    this.sharedService.registerTabIndex();
    this.shortAdd1.numericInput.nativeElement.autofocus = true;
    this.initMaxLength();
    this.initAutoFocusFalse();

    this.addressHolderForm = new FormGroup({
      nameEng: new FormControl(null, []),
      nameArb: new FormControl(null, []),
      idNo1: new FormControl(null, []),
      idNo2: new FormControl(null, []),
      idNo3: new FormControl(null, []),
      idNo4: new FormControl(null, []),
      idNo5: new FormControl(null, []),
      idNo6: new FormControl(null, []),
      idNo7: new FormControl(null, []),
      idNo8: new FormControl(null, []),
      idNo9: new FormControl(null, []),
      idNo10: new FormControl(null, []),
      regDate: new FormControl(null, []),
      shortAdd1: new FormControl(null, [Validators.required]),
      shortAdd2: new FormControl(null, [Validators.required]),
      shortAdd3: new FormControl(null, [Validators.required]),
      shortAdd4: new FormControl(null, [Validators.required]),
      shortAdd5: new FormControl(null, [Validators.required]),
      shortAdd6: new FormControl(null, [Validators.required]),
      shortAdd7: new FormControl(null, [Validators.required]),
      shortAdd8: new FormControl(null, [Validators.required]),
      buildingNo1Eng: new FormControl(null, [Validators.required]),
      buildingNo2Eng: new FormControl(null, [Validators.required]),
      buildingNo3Eng: new FormControl(null, [Validators.required]),
      buildingNo4Eng: new FormControl(null, [Validators.required]),
      streetEng: new FormControl(null, [Validators.required]),
      buildingNo1Arb: new FormControl(null, [Validators.required]),
      buildingNo2Arb: new FormControl(null, [Validators.required]),
      buildingNo3Arb: new FormControl(null, [Validators.required]),
      buildingNo4Arb: new FormControl(null, [Validators.required]),
      streetArb: new FormControl(null, [Validators.required]),
      sceondaryNo1Eng: new FormControl(null, [Validators.required]),
      sceondaryNo2Eng: new FormControl(null, [Validators.required]),
      sceondaryNo3Eng: new FormControl(null, [Validators.required]),
      sceondaryNo4Eng: new FormControl(null, [Validators.required]),
      districtEng: new FormControl(null, [Validators.required]),
      sceondaryNo1Arb: new FormControl(null, [Validators.required]),
      sceondaryNo2Arb: new FormControl(null, [Validators.required]),
      sceondaryNo3Arb: new FormControl(null, [Validators.required]),
      sceondaryNo4Arb: new FormControl(null, [Validators.required]),
      districtArb: new FormControl(null, [Validators.required]),
      postalCodeNo1Eng: new FormControl(null, [Validators.required]),
      postalCodeNo2Eng: new FormControl(null, [Validators.required]),
      postalCodeNo3Eng: new FormControl(null, [Validators.required]),
      postalCodeNo4Eng: new FormControl(null, [Validators.required]),
      postalCodeNo5Eng: new FormControl(null, [Validators.required]),
      postalCodeNo1Arb: new FormControl(null, [Validators.required]),
      postalCodeNo2Arb: new FormControl(null, [Validators.required]),
      postalCodeNo3Arb: new FormControl(null, [Validators.required]),
      postalCodeNo4Arb: new FormControl(null, [Validators.required]),
      postalCodeNo5Arb: new FormControl(null, [Validators.required]),
      cityEng: new FormControl(null, [Validators.required]),
      cityArb: new FormControl(null, [Validators.required]),
      countryEng: new FormControl(null, [Validators.required]),
      countryArb: new FormControl(null, [Validators.required]),
    });

    this.getAllDistricts();
    this.getAllCities();
    this.getAllCountries();
    this.addressHolderForm.valueChanges.subscribe((res) => {
      if (this.addressHolderForm.valid) {
        this.valueChange.emit(this.addressHolderForm.value);
      }
    });
  }

  initMaxLength() {
    this.idNo1.numericInput.nativeElement.maxLength = 1;
    this.idNo2.numericInput.nativeElement.maxLength = 1;
    this.idNo3.numericInput.nativeElement.maxLength = 1;
    this.idNo4.numericInput.nativeElement.maxLength = 1;
    this.idNo5.numericInput.nativeElement.maxLength = 1;
    this.idNo6.numericInput.nativeElement.maxLength = 1;
    this.idNo7.numericInput.nativeElement.maxLength = 1;
    this.idNo8.numericInput.nativeElement.maxLength = 1;
    this.idNo9.numericInput.nativeElement.maxLength = 1;
    this.idNo10.numericInput.nativeElement.maxLength = 1;
    this.shortAdd1.numericInput.nativeElement.maxLength = 1;
    this.shortAdd2.numericInput.nativeElement.maxLength = 1;
    this.shortAdd3.numericInput.nativeElement.maxLength = 1;
    this.shortAdd4.numericInput.nativeElement.maxLength = 1;
    this.shortAdd5.numericInput.nativeElement.maxLength = 1;
    this.shortAdd6.numericInput.nativeElement.maxLength = 1;
    this.shortAdd7.numericInput.nativeElement.maxLength = 1;
    this.shortAdd8.numericInput.nativeElement.maxLength = 1;
    this.buildingNo1Eng.numericInput.nativeElement.maxLength = 1;
    this.buildingNo2Eng.numericInput.nativeElement.maxLength = 1;
    this.buildingNo3Eng.numericInput.nativeElement.maxLength = 1;
    this.buildingNo4Eng.numericInput.nativeElement.maxLength = 1;
    this.buildingNo1Arb.numericInput.nativeElement.maxLength = 1;
    this.buildingNo2Arb.numericInput.nativeElement.maxLength = 1;
    this.buildingNo3Arb.numericInput.nativeElement.maxLength = 1;
    this.buildingNo4Arb.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo1Eng.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo2Eng.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo3Eng.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo4Eng.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo1Arb.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo2Arb.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo3Arb.numericInput.nativeElement.maxLength = 1;
    this.sceondaryNo4Arb.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo1Eng.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo2Eng.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo3Eng.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo4Eng.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo5Eng.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo1Arb.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo2Arb.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo3Arb.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo4Arb.numericInput.nativeElement.maxLength = 1;
    this.postalCodeNo5Arb.numericInput.nativeElement.maxLength = 1;
  }

  initAutoFocusFalse() {
    this.nameEng.input.nativeElement.autocomplete = "new-password";
    this.nameArb.input.nativeElement.autocomplete = "new-password";
    this.streetEng.input.nativeElement.autocomplete = "new-password";
    this.streetArb.input.nativeElement.autocomplete = "new-password";
    this.districtArb.input.nativeElement.autocomplete = "new-password";
    this.cityArb.input.nativeElement.autocomplete = "new-password";
    this.countryArb.input.nativeElement.autocomplete = "new-password";
    this.postalCodeNo1Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo2Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo3Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo4Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo5Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo1Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo2Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo3Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo4Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.postalCodeNo5Arb.numericInput.nativeElement.autocomplete =
      "new-password";

    this.idNo1.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo2.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo3.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo4.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo5.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo6.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo7.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo8.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo9.numericInput.nativeElement.autocomplete = "new-password";
    this.idNo10.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd1.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd2.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd3.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd4.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd5.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd6.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd7.numericInput.nativeElement.autocomplete = "new-password";
    this.shortAdd8.numericInput.nativeElement.autocomplete = "new-password";
    this.buildingNo1Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo2Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo3Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo4Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo1Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo2Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo3Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.buildingNo4Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo1Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo2Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo3Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo4Eng.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo1Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo2Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo3Arb.numericInput.nativeElement.autocomplete =
      "new-password";
    this.sceondaryNo4Arb.numericInput.nativeElement.autocomplete =
      "new-password";
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes", changes);
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.sharedService.registerTabIndex();
    if (changes && changes.data && changes.data.currentValue) {
      this.setData(changes.data.currentValue);
    }
    if (changes && changes.ceoData && changes.ceoData.currentValue) {
      this.setCeoData(changes.ceoData.currentValue);
    }
  }

  setValue(value, controlName) {
    this.addressHolderForm.get(controlName).setValue(value);
  }

  /**
   * @name setData
   * @param data
   */
  setData(data) {
    if (data) {
      // this.addressHolderForm.get('nameEng').setValue(data.accountHolderName);
    // this.addressHolderForm.get('nameArb').setValue(data.accountHolderArabicName);
    // this.addressHolderForm.get('idNo1').setValue(data.accountHolderId.split("")[0]);
    // this.addressHolderForm.get('idNo2').setValue(data.accountHolderId.split("")[1]);
    // this.addressHolderForm.get('idNo3').setValue(data.accountHolderId.split("")[2]);
    // this.addressHolderForm.get('idNo4').setValue(data.accountHolderId.split("")[3]);
    // this.addressHolderForm.get('idNo5').setValue(data.accountHolderId.split("")[4]);
    // this.addressHolderForm.get('idNo6').setValue(data.accountHolderId.split("")[5]);
    // this.addressHolderForm.get('idNo7').setValue(data.accountHolderId.split("")[6]);
    // this.addressHolderForm.get('idNo8').setValue(data.accountHolderId.split("")[7]);
    // this.addressHolderForm.get('idNo9').setValue(data.accountHolderId.split("")[8]);
    // this.addressHolderForm.get('idNo10').setValue(data.accountHolderId.split("")[9]);
    // this.addressHolderForm.get('regDate').setValue(new Date(data.accountRegDate));
    this.addressHolderForm
    .get("shortAdd1")
    .setValue(data.shortAddress ? data.shortAddress.split("")[0] :  null);
  this.addressHolderForm
    .get("shortAdd2")
    .setValue(data.shortAddress ? data.shortAddress.split("")[1] : null);
  this.addressHolderForm
    .get("shortAdd3")
    .setValue(data.shortAddress ? data.shortAddress.split("")[2] : null);
  this.addressHolderForm
    .get("shortAdd4")
    .setValue(data.shortAddress ? data.shortAddress.split("")[3] : null);
  this.addressHolderForm
    .get("shortAdd5")
    .setValue(data.shortAddress ? data.shortAddress.split("")[4] : null);
  this.addressHolderForm
    .get("shortAdd6")
    .setValue(data.shortAddress ? data.shortAddress.split("")[5] : null);
  this.addressHolderForm
    .get("shortAdd7")
    .setValue(data.shortAddress ? data.shortAddress.split("")[6] : null);
  this.addressHolderForm
    .get("shortAdd8")
    .setValue(data.shortAddress ? data.shortAddress.split("")[7] : null);

  this.addressHolderForm
    .get("buildingNo1Eng")
    .setValue(data.buildingNo ? data.buildingNo.split("")[0] : null);
  this.addressHolderForm
    .get("buildingNo2Eng")
    .setValue(data.buildingNo ? data.buildingNo.split("")[1] : null);
  this.addressHolderForm
    .get("buildingNo3Eng")
    .setValue(data.buildingNo ? data.buildingNo.split("")[2] : null);
  this.addressHolderForm
    .get("buildingNo4Eng")
    .setValue(data.buildingNo ? data.buildingNo.split("")[3] : null);
  this.addressHolderForm.get("streetEng").setValue(data.street ? data.street : null);

  this.addressHolderForm
    .get("buildingNo1Arb")
    .setValue(data.buildingNo ? data.buildingNo.split("")[0] : null);
  this.addressHolderForm
    .get("buildingNo2Arb")
    .setValue(data.buildingNo ? data.buildingNo.split("")[1] : null);
  this.addressHolderForm
    .get("buildingNo3Arb")
    .setValue(data.buildingNo ? data.buildingNo.split("")[2] : null);
  this.addressHolderForm
    .get("buildingNo4Arb")
    .setValue(data.buildingNo ? data.buildingNo.split("")[3] : null);
  this.addressHolderForm.get("streetArb").setValue(data.streetArabic ? data.streetArabic : null);

  this.addressHolderForm
    .get("sceondaryNo1Eng")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[0] : null);
  this.addressHolderForm
    .get("sceondaryNo2Eng")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[1] : null);
  this.addressHolderForm
    .get("sceondaryNo3Eng")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[2] : null);
  this.addressHolderForm
    .get("sceondaryNo4Eng")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[3] : null);
  this.addressHolderForm.get("districtEng").setValue(data.districtId ? data.districtId : null);

  this.addressHolderForm
    .get("sceondaryNo1Arb")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[0] : null);
  this.addressHolderForm
    .get("sceondaryNo2Arb")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[1] : null);
  this.addressHolderForm
    .get("sceondaryNo3Arb")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[2] : null);
  this.addressHolderForm
    .get("sceondaryNo4Arb")
    .setValue(data.secondaryNo ? data.secondaryNo.split("")[3] : null);
  this.addressHolderForm.get("districtArb").setValue(data.districtArabic ? data.districtArabic : null);

  this.addressHolderForm
    .get("postalCodeNo1Eng")
    .setValue(data.postalCode ? data.postalCode.split("")[0] : null);
  this.addressHolderForm
    .get("postalCodeNo2Eng")
    .setValue(data.postalCode ? data.postalCode.split("")[1] : null);
  this.addressHolderForm
    .get("postalCodeNo3Eng")
    .setValue(data.postalCode ? data.postalCode.split("")[2] : null);
  this.addressHolderForm
    .get("postalCodeNo4Eng")
    .setValue(data.postalCode ? data.postalCode.split("")[3] : null);
  this.addressHolderForm
    .get("postalCodeNo5Eng")
    .setValue(data.postalCode ? data.postalCode.split("")[4] : null);

  this.addressHolderForm
    .get("postalCodeNo1Arb")
    .setValue(data.postalCode ? data.postalCode.split("")[0] : null);
  this.addressHolderForm
    .get("postalCodeNo2Arb")
    .setValue(data.postalCode ? data.postalCode.split("")[1] : null);
  this.addressHolderForm
    .get("postalCodeNo3Arb")
    .setValue(data.postalCode ? data.postalCode.split("")[2] : null);
  this.addressHolderForm
    .get("postalCodeNo4Arb")
    .setValue(data.postalCode ? data.postalCode.split("")[3] : null);
  this.addressHolderForm
    .get("postalCodeNo5Arb")
    .setValue(data.postalCode ? data.postalCode.split("")[4] : null);
  this.addressHolderForm.get("cityEng").setValue(data.cityId ? data.cityId : null);
  this.addressHolderForm.get("cityArb").setValue(data.cityArabic ? data.cityArabic : null);

  this.addressHolderForm.get("countryEng").setValue(data.countryId ? data.countryId : null);
  this.addressHolderForm.get("countryArb").setValue(data.countryArabic ? data.countryArabic : null);
    }
  }
  /**
   * @name getAllCustomerContactCEOByCustomerBranchId
   * @param {number} customerBranchId
   * @returns filter list
   * @memberof salesService
   */
  setCeoData(data) {
    if (data) {
      console.log("response.data.payload", data);
      this.addressHolderForm.get("nameEng").setValue(data.contactName);
      this.addressHolderForm
        .get("nameArb")
        .setValue(data.contactNameArabic);
      this.addressHolderForm
        .get("idNo1")
        .setValue(data.iqamaNo.split("")[0]);
      this.addressHolderForm
        .get("idNo2")
        .setValue(data.iqamaNo.split("")[1]);
      this.addressHolderForm
        .get("idNo3")
        .setValue(data.iqamaNo.split("")[2]);
      this.addressHolderForm
        .get("idNo4")
        .setValue(data.iqamaNo.split("")[3]);
      this.addressHolderForm
        .get("idNo5")
        .setValue(data.iqamaNo.split("")[4]);
      this.addressHolderForm
        .get("idNo6")
        .setValue(data.iqamaNo.split("")[5]);
      this.addressHolderForm
        .get("idNo7")
        .setValue(data.iqamaNo.split("")[6]);
      this.addressHolderForm
        .get("idNo8")
        .setValue(data.iqamaNo.split("")[7]);
      this.addressHolderForm
        .get("idNo9")
        .setValue(data.iqamaNo.split("")[8]);
      this.addressHolderForm
        .get("idNo10")
        .setValue(data.iqamaNo.split("")[9]);
      this.addressHolderForm
        .get("regDate")
        .setValue(new Date(data.iqamaExpiryDate));
    }
  }

  /**
   * @name getAllDistricts
   * @returns  list
   * @memberof salesService
   */
  getAllDistricts() {
    this.sharedService
      .getAllDistricts()
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            this.districList = response.data.payload;
          }
        }
      })
      .catch((err) => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllCities
   * @returns  list
   * @memberof salesService
   */
  getAllCities() {
    this.sharedService
      .getAllCities()
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            this.cityList = response.data.payload;
          }
        }
      })
      .catch((err) => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllCountries
   * @returns  list
   * @memberof salesService
   */
  getAllCountries() {
    this.sharedService
      .getAllCountries()
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            this.countryList = response.data.payload;
          }
        }
      })
      .catch((err) => {
        console.error("Error:  ", err);
      });
  }
}
