<button mat-icon-button [matMenuTriggerFor]="messagesMenu" (click)="openMessagesMenu()">
    <mat-icon>notifications</mat-icon><div class="count">{{notifications?.length > 200 ? '200+' : notifications?.length}}</div>
    <!-- {{notifications?.length > 9 ? '9+' : notifications?.length}} -->
</button>
<mat-menu #messagesMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu messages">
        <mat-card class="content">
            <ng-scrollbar pointerEventsMethod="scrollbar">
                <div fxLayout="column" fxLayoutAlign="none">
                    <div class="notification"  *ngFor="let n of notifications">
                        <!-- <div class="typeTitle">{{n?.stockType}}</div> -->
                        <!-- <img src="{{ ( n.image || (n.name | profilePicture)) }}" width="50">    -->
                        <div fxLayout="column" fxLayoutAlign="none" class="w-100">
                            <span class="description">{{n?.stockType ? n?.stockType : ''}}</span>
                            <div fxLayout="row" fxLayoutAlign="space-between start">
                                <span class="name">{{n?.customerName ? n?.customerName : ''}}</span>
                                <span class="info"><mat-icon>access_time</mat-icon> {{n?.pendingTime}}</span>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between start"> 
                                <span class="text muted-text">Order .# {{n?.salesOrderId}}</span>
                                <span class="text muted-text"> Issue .# <a href="javascript:void(0)">{{n?.stockIssueId}}</a></span>
                             </div> 
                             <div fxLayout="row" fxLayoutAlign="space-between start"> 
                                <span class="text muted-text"> Return .# <a href="javascript:void(0)">{{n?.salesReturnId}}</a></span>
                                <span class="text muted-text">Branch .# {{n?.customerBranchId}}</span>
                             </div>
                             <div fxLayout="row" fxLayoutAlign="space-between start"> 
                                <span class="text muted-text"> Request .# <a href="javascript:void(0)" (click)="routerLinkEvent('/portal/inventory/stock-receive/notification/' + n?.stockRequestId)">{{n?.stockRequestId}}</a></span>
                             </div>
                        </div>
                    </div>
                  </div>    
            </ng-scrollbar>
          </mat-card>
   
</mat-menu>