<div class="right-nav">
  <div class="card">
    <div class="card-header">
      <div class="float-left">Order Amount</div>
      <!-- <div class="float-right">
        <div id="dealInner">
          <i class="fa fa-angle-down fa-3" aria-hidden="true"></i>
        </div>
        <div id="close">
          <i class="fa fa-angle-up fa-3" aria-hidden="true"></i>
        </div>
      </div> -->
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Side</th>
            <th>
              Item
            </th>
            <th class="text-center">
              Qty
            </th>
            <th class="text-center">
              Rate
            </th>
            <th class="text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
           <!-- START RIGHT SIDE -->
         <ng-container *ngIf="selectedTemplate == 'both' || selectedTemplate == 'right'">
            <tr *ngIf="rightArrayPush.length > 0">
              <td [attr.rowspan]="rightArrayPush.length + 1" style="vertical-align: middle;">
                Right
              </td>
            </tr>
            <tr *ngIf="lensePriceRight && lensePriceRight > 0">
              <td>
                Lense
              </td>
              <td class="text-center">
               {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (lensePriceRight | number: "1.2-2") : (lensePriceRight | number: "1.3-3") }}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((lensePriceRight * qtyRight) | number: "1.2-2") : ((lensePriceRight * qtyRight) | number: "1.3-3") }}
               </td>
            </tr>
            <tr *ngIf="rightOval && rightOval > 0">
              <td>
                OVAL/DIA
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightOval | number: "1.2-2") : (rightOval | number: "1.3-3") }}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((rightOval * qtyRight) | number: "1.2-2") : ((rightOval * qtyRight) | number: "1.3-3") }}
               </td>
            </tr>
            <tr *ngIf="rightEtPrice && rightEtPrice > 0">
              <td>
                ET
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightEtPrice | number: "1.2-2") : (rightEtPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((rightEtPrice * qtyRight) | number: "1.2-2") : ((rightEtPrice * qtyRight) | number: "1.3-3") }}
               </td>
            </tr>
            <tr *ngIf="rightCtPrice && rightCtPrice > 0">
              <td>
                CT
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightCtPrice | number: "1.2-2") : (rightCtPrice | number: "1.3-3") }}
                {{rightCtPrice}}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((rightCtPrice * qtyRight) | number: "1.2-2") : ((rightCtPrice * qtyRight) | number: "1.3-3") }}
               </td>
            </tr>
            <tr *ngIf="rightDecPrice && rightDecPrice > 0">
              <td>
                DEC
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightDecPrice | number: "1.2-2") : (rightDecPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((rightDecPrice * qtyRight) | number: "1.2-2") : ((rightDecPrice * qtyRight) | number: "1.3-3") }}
               </td>
            </tr>
            <tr *ngIf="rightPrismPrice && rightPrismPrice > 0">
              <td>
                Prism
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightPrismPrice | number: "1.2-2") : (rightPrismPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((rightPrismPrice * qtyRight) | number: "1.2-2") : ((rightPrismPrice * qtyRight) | number: "1.3-3") }}
               </td>
            </tr>
            <tr *ngIf="rightBaseCurvePrice && rightBaseCurvePrice > 0">
              <td>
                Base Curve
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightBaseCurvePrice | number: "1.2-2") : (rightBaseCurvePrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                {{ userDecimalcount == 2 ? ((rightBaseCurvePrice * qtyRight) | number: "1.2-2") : ((rightBaseCurvePrice * qtyRight) | number: "1.3-3") }}
               </td>
               
            </tr>
            <tr *ngIf="rightCoatingPrice && rightCoatingPrice > 0">
              <td>
                Coating 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightCoatingPrice | number: "1.2-2") : (rightCoatingPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((rightCoatingPrice * qtyRight) | number: "1.2-2") : ((rightCoatingPrice * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((rightCoatingPrice * qtyRight) | number: "1.2-2") : ((rightCoatingPrice * qtyRight) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            <tr *ngIf="rightMirrorPrice && rightMirrorPrice > 0">
              <td>
                Mirror 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightMirrorPrice | number: "1.2-2") : (rightMirrorPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((rightMirrorPrice * qtyRight) | number: "1.2-2") : ((rightMirrorPrice * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((rightMirrorPrice * qtyRight) | number: "1.2-2") : ((rightMirrorPrice * qtyRight) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            <tr *ngIf="rightTintPrice && rightTintPrice > 0">
              <td>
                Tint 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (rightTintPrice | number: "1.2-2") : (rightTintPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((rightTintPrice * qtyRight) | number: "1.2-2") : ((rightTintPrice * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((rightTintPrice * qtyRight) | number: "1.2-2") : ((rightTintPrice * qtyRight) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            <tr *ngIf="engraveName && engraveName > 0">
              <td>
                Engrave Name 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (engraveName | number: "1.2-2") : (engraveName | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((engraveName * qtyRight) | number: "1.2-2") : ((engraveName * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((engraveName * qtyRight) | number: "1.2-2") : ((engraveName * qtyRight) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            <tr *ngIf="specialLogo && specialLogo > 0">
              <td>
                Special Logo 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <!-- <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span> -->
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (specialLogo | number: "1.2-2") : (specialLogo | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((specialLogo * qtyRight) | number: "1.2-2") : ((specialLogo * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((specialLogo * qtyRight) | number: "1.2-2") : ((specialLogo * qtyRight) | number: "1.3-3") }}
                </span>
                <!-- <span *ngIf="selectedTemplate == 'left'">
                  {{specialLogo * qtyLeft}}
                </span> -->
               </td>
            </tr>
            <tr *ngIf="rightArrayPush.length > 0">
              <td colspan="4">
                <strong>Sub Total</strong>
               </td>
               <td class="text-right">
                <strong>{{ userDecimalcount == 2 ? (rightTotal | number: "1.2-2") : (rightTotal | number: "1.3-3") }}</strong>
               </td>
            </tr>
            <!-- <tr *ngIf="rightFittingPrice && rightFittingPrice > 0">
              <td>
                Frame
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
               {{rightFittingPrice / qtyRight}}
              </td>
              <td class="text-right">
                {{rightFittingPrice}}
               </td>
            </tr> -->
            <!-- END RIGHT SIDE -->
         </ng-container>
         <!-- / END RIGHT SIDE -->

          <!-- START LEFT SIDE -->
         <ng-container *ngIf="selectedTemplate == 'both' || selectedTemplate == 'left'">
          <tr *ngIf="leftArrayPush.length > 0">
             <td [attr.rowspan]="leftArrayPush.length + 1" style="vertical-align: middle;">
               Left
             </td>
           </tr>
          <tr *ngIf="lensePriceLeft && lensePriceLeft > 0">
            <td>
              Lense
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (lensePriceLeft | number: "1.2-2") : (lensePriceLeft | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((lensePriceLeft * qtyLeft) | number: "1.2-2") : ((lensePriceLeft * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftOval && leftOval > 0">
            <td>
              OVAL/DIA
            </td>
            <td class="text-center"> 
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftOval | number: "1.2-2") : (leftOval | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((leftOval * qtyLeft) | number: "1.2-2") : ((leftOval * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftEtPrice && leftEtPrice > 0">
            <td>
              ET
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftEtPrice | number: "1.2-2") : (leftEtPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((leftEtPrice * qtyLeft) | number: "1.2-2") : ((leftEtPrice * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftCtPrice && leftCtPrice > 0">
            <td>
              CT
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftCtPrice | number: "1.2-2") : (leftCtPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((leftCtPrice * qtyLeft) | number: "1.2-2") : ((leftCtPrice * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftDecPrice && leftDecPrice > 0">
            <td>
              DEC
            </td>
            <td class="text-center"> 
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftDecPrice | number: "1.2-2") : (leftDecPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((leftDecPrice * qtyLeft) | number: "1.2-2") : ((leftDecPrice * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftPrismPrice && leftPrismPrice > 0">
            <td>
              Prism
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftPrismPrice | number: "1.2-2") : (leftPrismPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((leftPrismPrice * qtyLeft) | number: "1.2-2") : ((leftPrismPrice * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftBaseCurvePrice && leftBaseCurvePrice > 0">
            <td>
              Base Curve
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftBaseCurvePrice | number: "1.2-2") : (leftBaseCurvePrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((leftBaseCurvePrice * qtyLeft) | number: "1.2-2") : ((leftBaseCurvePrice * qtyLeft) | number: "1.3-3") }}
             </td>
          </tr>
          <tr *ngIf="leftCoatingPrice && leftCoatingPrice > 0">
            <td>
              Coating 
            </td>
            <td class="text-center">
              <span *ngIf="selectedTemplate == 'both'">
                {{qtyLeft}}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{qtyLeft}}
              </span>
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftCoatingPrice | number: "1.2-2") : (leftCoatingPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              <span *ngIf="selectedTemplate == 'both'">
                {{ userDecimalcount == 2 ? ((leftCoatingPrice * qtyLeft) | number: "1.2-2") : ((leftCoatingPrice * qtyLeft) | number: "1.3-3") }}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{ userDecimalcount == 2 ? ((leftCoatingPrice * qtyLeft) | number: "1.2-2") : ((leftCoatingPrice * qtyLeft) | number: "1.3-3") }}
              </span>
             </td>
          </tr>
          <tr *ngIf="leftMirrorPrice && leftMirrorPrice > 0">
            <td>
              Mirror 
            </td>
            <td class="text-center">
              <span *ngIf="selectedTemplate == 'both'">
                {{qtyLeft}}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{qtyLeft}}
              </span>
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftMirrorPrice | number: "1.2-2") : (leftMirrorPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              <span *ngIf="selectedTemplate == 'both'">
                {{ userDecimalcount == 2 ? ((leftMirrorPrice * qtyLeft) | number: "1.2-2") : ((leftMirrorPrice * qtyLeft) | number: "1.3-3") }}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{ userDecimalcount == 2 ? ((leftMirrorPrice * qtyLeft) | number: "1.2-2") : ((leftMirrorPrice * qtyLeft) | number: "1.3-3") }}
              </span>
             </td>
          </tr>
          <tr *ngIf="leftTintPrice && leftTintPrice > 0">
            <td>
              Tint 
            </td>
            <td class="text-center">
              <span *ngIf="selectedTemplate == 'both'">
                {{qtyLeft}}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{qtyLeft}}
              </span>
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (leftTintPrice | number: "1.2-2") : (leftTintPrice | number: "1.3-3") }}
            </td>
            <td class="text-right">
              <span *ngIf="selectedTemplate == 'both'">
                {{ userDecimalcount == 2 ? ((leftTintPrice * qtyLeft) | number: "1.2-2") : ((leftTintPrice * qtyLeft) | number: "1.3-3") }}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{ userDecimalcount == 2 ? ((leftTintPrice * qtyLeft) | number: "1.2-2") : ((leftTintPrice * qtyLeft) | number: "1.3-3") }}
              </span>
             </td>
          </tr>

          <tr *ngIf="engraveName && engraveName > 0">
            <td>
              Engrave Name 
            </td>
            <td class="text-center">
              <span *ngIf="selectedTemplate == 'both'">
                {{qtyLeft}}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{qtyLeft}}
              </span>
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (engraveName | number: "1.2-2") : (engraveName | number: "1.3-3") }}
            </td>
            <td class="text-right">
              <span *ngIf="selectedTemplate == 'both'">
                {{ userDecimalcount == 2 ? ((engraveName * qtyLeft) | number: "1.2-2") : ((engraveName * qtyLeft) | number: "1.3-3") }}
              </span>
              <span *ngIf="selectedTemplate == 'left'">
                {{ userDecimalcount == 2 ? ((engraveName * qtyLeft) | number: "1.2-2") : ((engraveName * qtyLeft) | number: "1.3-3") }}
              </span>
             </td>
          </tr>
          <tr *ngIf="specialLogo && specialLogo > 0">
            <td>
              Special Logo 
            </td>
            <td class="text-center">
              <span *ngIf="selectedTemplate == 'both'">
                {{qtyRight}}
              </span>
              <!-- <span *ngIf="selectedTemplate == 'right'">
                {{qtyRight}}
              </span> -->
              <span *ngIf="selectedTemplate == 'left'">
                {{qtyLeft}}
              </span>
            </td>
            <td class="text-center">
              {{ userDecimalcount == 2 ? (specialLogo | number: "1.2-2") : (specialLogo | number: "1.3-3") }}
            </td>
            <td class="text-right">
              <span *ngIf="selectedTemplate == 'both'">
                {{ userDecimalcount == 2 ? ((specialLogo * qtyLeft) | number: "1.2-2") : ((specialLogo * qtyLeft) | number: "1.3-3") }}
              </span>
              <!-- <span *ngIf="selectedTemplate == 'right'">
                {{specialLogo * qtyRight}}
              </span> -->
              <span *ngIf="selectedTemplate == 'left'">
                {{ userDecimalcount == 2 ? ((specialLogo * qtyLeft) | number: "1.2-2") : ((specialLogo * qtyLeft) | number: "1.3-3") }}
              </span>
             </td>
          </tr>
          <tr *ngIf="leftArrayPush.length > 0">
            <td colspan="4">
              <strong>Sub Total</strong>
             </td>
             <td class="text-right">
            <strong> {{ userDecimalcount == 2 ? (leftTotal | number: "1.2-2") : (leftTotal | number: "1.3-3") }}</strong>
             </td>
          </tr>
       
          <!-- / END LEFT SIDE -->
         </ng-container>
          <!-- / END LEFT SIDE -->

          <tr *ngIf="framePush.length > 0">
            <td colspan="2">
              Frame
            </td>
            <td class="text-center">
              {{selectedTemplate == 'right' ? qtyRight : qtyLeft}}
            </td>
            <td class="text-center" *ngIf="!editFittingPrice">
              {{ userDecimalcount == 2 ? (fittingPrice | number: "1.2-2") : (fittingPrice | number: "1.3-3") }}
            </td>
            <!-- watch calculation dnt remove this block -->
            <!-- <ng-container *ngIf="framePush" >

            </ng-container> -->
            <!-- watch calculation dnt remove this block -->
            <td class="text-center" *ngIf="editFittingPrice">
              <div class="dx-field">
              <kendo-formfield>
                <kendo-numerictextbox
                  [spinners]="false"
                  class="w-50-per"
                  [rangeValidation]="false"
                  [value]="fittingPrice"
                  SignNotAllowed="-" [min]="1"
                  [decimals]="userDecimalcount" [format]="$n2"
                  showErrorIcon="initial"
                  (valueChange)="valueChanged($event, 'fitting')"
                >
                </kendo-numerictextbox>
              </kendo-formfield>
            </div>
              <!-- <input
              type="number" data-decimal="2" step="0.01"
              id="fittingPrice"
              class="text-right w-50-per"
              [value]="fittingPrice"
              [min]="1"
              (input)="valueChanged($event.target.value, 'fitting');enforceNumberValidation($event.target.value)"
            /> -->
            </td>
            <td class="text-right">
              {{ userDecimalcount == 2 ? ((fittingPrice * (selectedTemplate == 'right' ? qtyRight : qtyLeft)) | number: "1.2-2") : ((fittingPrice * (selectedTemplate == 'right' ? qtyRight : qtyLeft)) | number: "1.3-3") }}
             </td>
          </tr>
         <!-- SERVICES -->
         <ng-container *ngIf="servicesPush.length > 0">
          <tr>
            <td [attr.rowspan]="servicesPush.length + 1" style="vertical-align: middle;">
              Services
            </td>
          </tr>
            <!-- SERVICES -->
            <!-- <tr *ngIf="coatingPrice && coatingPrice > 0">
              <td>
                Coating {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                {{coatingPrice}}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{coatingPrice * (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{coatingPrice * qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{coatingPrice * qtyLeft}}
                </span>
               </td>
            </tr>
            <tr *ngIf="mirrorPrice && mirrorPrice > 0">
              <td>
                Mirror {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                {{mirrorPrice}}
                
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{mirrorPrice * (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{mirrorPrice * qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{mirrorPrice * qtyLeft}}
                </span>
               </td>
            </tr>
            <tr *ngIf="tintPrice && tintPrice > 0">
              <td>
                Tint {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                {{tintPrice}}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{tintPrice * (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{tintPrice * qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{tintPrice * qtyLeft}}
                </span>
               </td>
            </tr> -->
            <tr *ngIf="hcPrice && hcPrice > 0">
              <td>
                HC {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (hcPrice | number: "1.2-2") : (hcPrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((hcPrice * (qtyRight * 2)) | number: "1.2-2") : ((hcPrice * (qtyRight * 2)) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((hcPrice * qtyRight) | number: "1.2-2") : ((hcPrice * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{ userDecimalcount == 2 ? ((hcPrice * qtyLeft) | number: "1.2-2") : ((hcPrice * qtyLeft) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            <tr *ngIf="selectedOtherServicePrice && selectedOtherServicePrice > 0">
              <td>
                Others {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (selectedOtherServicePrice | number: "1.2-2") : (selectedOtherServicePrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((selectedOtherServicePrice * (qtyRight * 2)) | number: "1.2-2") : ((selectedOtherServicePrice * (qtyRight * 2)) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((selectedOtherServicePrice * qtyRight) | number: "1.2-2") : ((selectedOtherServicePrice * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{ userDecimalcount == 2 ? ((selectedOtherServicePrice * qtyLeft) | number: "1.2-2") : ((selectedOtherServicePrice * qtyLeft) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            

           

           
            <tr *ngIf="tailorMadePrice && tailorMadePrice > 0">
              <td>
                Tailor Made {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                {{ userDecimalcount == 2 ? (tailorMadePrice | number: "1.2-2") : (tailorMadePrice | number: "1.3-3") }}
              </td>
              <td class="text-right">
                <span *ngIf="selectedTemplate == 'both'">
                  {{ userDecimalcount == 2 ? ((tailorMadePrice * (qtyRight * 2)) | number: "1.2-2") : ((tailorMadePrice * (qtyRight * 2)) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{ userDecimalcount == 2 ? ((tailorMadePrice * qtyRight) | number: "1.2-2") : ((tailorMadePrice * qtyRight) | number: "1.3-3") }}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{ userDecimalcount == 2 ? ((tailorMadePrice * qtyLeft) | number: "1.2-2") : ((tailorMadePrice * qtyLeft) | number: "1.3-3") }}
                </span>
               </td>
            </tr>
            <!-- <tr *ngIf="fittingPrice && fittingPrice > 0">
              <td>
                Fitting {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{fittingPrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{fittingPrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{fittingPrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{fittingPrice}}
               </td>
            </tr> -->
            
            <!-- / SERVICES -->
         </ng-container>
        <!-- / SERVICES -->
          <!-- <tr>
            <td>
              Additional
            </td>
            <td colspan="4">
              <input
              type="number"
              class="text-right w-100"
              (input)="valueChanged($event.target.value)"
              [(ngModel)]="additional"
              NumberValidate
            />
            </td>
          </tr> -->
          <!-- <tr>
            <td>
              Discont
            </td>
            <td colspan="4">
              <input
              type="number"
              class="text-right w-100"
              (input)="valueChanged($event.target.value)"
              [(ngModel)]="discount"
              NumberValidate
            />
            </td>
          </tr> -->
        </tbody>
        <tfoot *ngIf="leftArrayPush.length > 0 || rightArrayPush.length > 0 || servicesPush.length > 0 || fittingTotal > 0">
          <tr>
            <td><strong>Total</strong> </td>
            <td class="text-right" colspan="4">
            <strong>{{ userDecimalcount == 2 ? (Total | number: "1.2-2") : (Total | number: "1.3-3") }}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
