import { AppConstant } from 'src/app/app.const';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnInit } from '@angular/core';
/* tslint:disable:radix */
import { SharedService } from './../shared.service';
import {Component, Inject} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import notify from "devextreme/ui/notify";

@Component({
  selector: 'range-template-fn-special-dialog',
  templateUrl: 'range-template-fn-special.html',
})
export class RangeTemplateFNSpecialDialog implements OnInit {

  itemRangeForm: FormGroup;
  selectedRowData: any;
  addfRangeList: any[];
  cylRangeList: any[];
  sphRangeList: any[];
  loadingCylRange: boolean;
  loadingAddfRange: boolean;
  loadingSphRange: boolean;
  loading: boolean;
  cspRanges: any[];
  sphError: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RangeTemplateFNSpecialDialog>, private _sharedService: SharedService) {
      this.selectedRowData = data;
  }

  ngOnInit() {
    this.cylRangeList = [];
    this.sphRangeList = [];
    this.addfRangeList = [];
    this.cspRanges = [];
    this.itemRangeForm = new FormGroup({
      sphMin: new FormControl(null, [Validators.required]),
      sphMax: new FormControl(null, [Validators.required]),
    });

    if (this.selectedRowData && this.selectedRowData.focalId == 1) {
      this.getSPH(parseInt(this.selectedRowData.strsphMin), parseInt(this.selectedRowData.strsphMax));
      this.getCYL(parseInt(this.selectedRowData.strcylMin), parseInt(this.selectedRowData.strcylMax));
      this.itemRangeForm.removeControl('addMin');
      this.itemRangeForm.removeControl('addMax');
      this.itemRangeForm.addControl('cylMin', new FormControl(null, [Validators.required]));
      this.itemRangeForm.addControl('cylMax', new FormControl(null, [Validators.required]));
    } else {
      this.getSPH(parseInt(this.selectedRowData.strsphMin), parseInt(this.selectedRowData.strsphMax));
      this.getADDF(parseInt(this.selectedRowData.straddMin), parseInt(this.selectedRowData.straddMax));
      this.itemRangeForm.removeControl('cylMin');
      this.itemRangeForm.removeControl('cylMax');
      this.itemRangeForm.addControl('addMin', new FormControl(null, [Validators.required]));
      this.itemRangeForm.addControl('addMax', new FormControl(null, [Validators.required]));
    }
    this.getCSPRange(this.selectedRowData.customerId, this.selectedRowData.itemCatalogGroupId);
  }
 
   /**
   * @name getSPH
   * @param {number} range1
   * @param {number} range2
   * @returns sph range
   * @memberof salesService
   */
  getSPH(range1: number, range2: number) {
    this.loadingSphRange = true;
    this._sharedService
      .getSPH(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.sphRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.sphRangeList = response.data.payload;
            }
          }
          this.loadingSphRange = false;
        }
      })
      .catch((error) => {
        this.loadingSphRange = false;
        const option = {
          width: 300,
          displayTime: 5000,
          message: error.message,
          type: "error",
        };
        console.log(error);
        notify(option);
      });
  }
  /**
   * @name getCYL
   * @param {number} range1
   * @param {number} range2
   * @returns cyl range
   * @memberof salesService
   */
  getCYL(range1: number, range2: number) {
    this.loadingCylRange = true;
    this._sharedService
      .getCYL(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.cylRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.cylRangeList = response.data.payload;
            }
          }
          this.loadingCylRange = false;
        }
      })
      .catch((error) => {
        this.loadingCylRange = false;
        const option = {
          width: 300,
          displayTime: 5000,
          message: error.message,
          type: "error",
        };
        console.log(error);
        notify(option);
      });
  }

  /**
   * @name getADDF
   * @param {number} range1
   * @param {number} range2
   * @returns addf range
   * @memberof salesService
   */
  getADDF(range1: number, range2: number) {
    this.loadingAddfRange = true;
    this._sharedService
      .getADDF(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.addfRangeList = list;
           
          } else {
            if (response.data.payload.active) {
              this.addfRangeList = response.data.payload;
            }
          }
          this.loadingAddfRange = false;
        }
      })
      .catch((error) => {
        this.loadingAddfRange = false;
        const option = {
          width: 300,
          displayTime: 5000,
          message: error.message,
          type: "error",
        };
        console.log(error);
        notify(option);
      });
  }

  /**
   * @name closePopup
   * @return void
   * @description close model
   */
  closePopup(): void {
    this.dialogRef.close();
  }

  /**
   * @name validateSPHMax
   * @param {control} min
   * @param {control} max
   * @return void
   */
  validateSPHMax(sphMin: any, sphMax: any): void {
    if (sphMin && sphMax) {
      const sph_max = parseFloat(sphMax);
      const sph_min = parseFloat(sphMin);
      if (sph_max < sph_min) {
        this.itemRangeForm.get('sphMax').setErrors({inValidPower: true});
    } else {
      this.itemRangeForm.get('sphMax').setErrors(null);
    }

  }
  }
   /**
   * @name validateCylMax
   * @param {control} min
   * @param {control} max
   * @return void
   */
  validateCylMax(cylMin: any, cylMax: any): void {
    if (cylMin && cylMax) {
      const cyl_max = parseFloat(cylMax);
      const cyl_min = parseFloat(cylMin);
      if (cyl_max < cyl_min) {
        this.itemRangeForm.get('cylMax').setErrors({inValidPower: true});
    } else {
      this.itemRangeForm.get('cylMax').setErrors(null);
    }
  }
  }
   /**
   * @name validateAddMax
   * @param {control} min
   * @param {control} max
   * @return void
   */
  validateAddMax(addMin: any, addMax: any): void {
    if (addMin && addMax) {
      const add_max = parseFloat(addMax);
      const add_min = parseFloat(addMin);
      if (add_max < add_min) {
        this.itemRangeForm.get('addMax').setErrors({inValidPower: true});
    } else {
      this.itemRangeForm.get('addMax').setErrors(null);
    }
  }
  }

   /**
   * @name getCSPRange
   * @param {number} customerId
   * @param {number} itemCatalogGroupId
   * @returns validate ranges
   * @memberof salesService
   */
  getCSPRange(customerId: number, itemCatalogGroupId: number) {
    this._sharedService
      .getCSPRange(customerId, itemCatalogGroupId)
      .then(response => {
        if (response.data.completed) {
          this.cspRanges = response.data.payload;
        } else {
          const option = {
            width: 300,
            displayTime: 3000,
            message: response.data.message,
            type: 'error'
          };
          notify(option);
        }
      })
      .catch(error => {
        const option = {
          width: 300,
          displayTime: 3000,
          message: error.message,
          type: 'error'
        };
        notify(option);
      });
  }

  /**
   * @name addItems
   * @param {FormGroup} formData
   * @return add ranges
   */
  addItems(formData: FormGroup) {
    this.loading = true;
    const payload = {
      "cspPowerRangeId": 0,
      "companyBranchId": JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId,
      "cityId": 0,
      "customerId": this.selectedRowData.customerId,
      "itemCatalogGroupId": this.selectedRowData.itemCatalogGroupId,
      "itemCatalogRangeId": this.selectedRowData.itemCatalogRangeId,
      "sphMin": formData.value.sphMin,
      "sphMax": formData.value.sphMax,
      "cylMin": formData.value.cylMin,
      "cylMax": formData.value.cylMax,
      "addMin": formData.value.addMin,
      "addMax": formData.value.addMax,
      "focalId": this.selectedRowData.focalId,
      "priceGroupId": this.selectedRowData.priceGroupId ? this.selectedRowData.priceGroupId : 0,
      "addDateTime": AppConstant.ksaDefaultDate(),
      "addUserId": JSON.parse(localStorage.getItem("user")).userId,
      "updateDateTime": AppConstant.ksaDefaultDate(),
      "updateUserId": JSON.parse(localStorage.getItem("user")).userId,
      "active": true
    };
    this._sharedService
        .addCSPPowerRange(payload)
        .then((response: any) => {
          if (response.data.completed) {
            this.loading = false;
            this.getCSPRange(this.selectedRowData.customerId, this.selectedRowData.itemCatalogGroupId);
          } else {
            this.loading = false;
            const option = {
              width: 300,
              displayTime: 3000,
              message: response.data.message,
              type: "error"
            };
            notify(option);
            console.error(response);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
          const option = {
            width: 300,
            displayTime: 3000,
            message: error.message,
            type: "error"
          };
          notify(option);
        });
  }
}

