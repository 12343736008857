import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild, AfterViewInit
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { AppConstant } from "src/app/app.const";
import { DialogAddRows } from "../dilog/add-rows.component";
import { MatDialog } from "@angular/material/dialog";
import { Papa } from "ngx-papaparse";
import { Pipe } from '@angular/core';
import { SharedService } from "./../../shared.service";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import {fromEvent} from 'rxjs';
import notify from "devextreme/ui/notify";

declare var $: any;





@Component({
  selector: "app-bulk-cyl-sph",
  templateUrl: "./bulk-cyl-sph.component.html",
  styleUrls: ["./bulk-cyl-sph.component.scss"],
})
export class BulkCylSphComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild("saveSwal") private saveSwal: SwalComponent;
  requestForm: FormGroup;
  @Input() dataSource: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @Output() onResetAll: EventEmitter<any> = new EventEmitter();
  @Input() hidePriceBtn: boolean;
  // @Input() itemCatalogId: any;
  // @Input() focalId: any;
  // @Input() itemTypeMasterId: any;
  @Input() companyBranchId: any;
  @Input() acknowledgement: any;
  // @Input() customerId: any;
  loadingIndicator: boolean;
  showGenerateBtn: boolean;
  isEdit: boolean;
  SPH_RANGE = [];
  CYL_RANGE = [];
  barCodeSph: any;
  barCodeCyl: any;
  rangeStockListPayload = [];
  toggleTypesOfValue;
  isCsv: boolean;
  actionSheetVisible: boolean;
  actionSheetTarget: any;
  sphError: boolean;
  cylError: boolean;
  stockIcon: boolean;
  inputType: boolean;
  totalQTY: number;
  totalToggle: boolean;
  loadingStockAvailable: boolean;
  availableStock: number;
  previousStock: boolean;
  currentStock: boolean;
  showPreviousStock: boolean;
  showCurrentStock: boolean;
  readonly: boolean;
  displayOnly: boolean;
  rangeLoading: boolean;
  loadingAllAvailableStock: boolean;
  hideClearBtn: boolean;
  sphRangeList: any[];
  cylRangeList: any[];
  loadingCylRange: boolean;
  loadingSphRange: boolean;
  priceRange: any[];
  totalAmount: number;
  itemQty: any;
  holdSalesOrder: boolean;
  stockLense: any;
  appCons: AppConstant;
  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    
    this.totalQTY = 0;
    this.availableStock = 0;
    this.showGenerateBtn = false;
    this.barCodeCyl = [];
    this.barCodeSph = [];
    this.sphRangeList = [];
    this.cylRangeList = [];
    this.priceRange = [];
   
    this.totalAmount = 0;
    this.getSPH(-35, 35);
    this.getCYL(-15, 15);
    
    // this.displayOnly = false;
    $(document).ready(() => {
      $('#dealInner').removeClass('hide');
      $('#close').addClass('hide');
      const countRightNav = $('.right-nav .card-body').height() + 10;
      const countHeight = '-' + countRightNav + 'px';
      $('.right-nav').css({ bottom: countHeight });
      $('#dealInner').on('click', () => {
        $('.right-nav')
          .stop()
          .animate({
            bottom: '4px',
            slow: '300'
          });
        $('#dealInner').addClass('hide');
        $('#close').removeClass('hide');
      });
      $('#close').on('click', () => {
        var r = $('.right-nav').height() - 50;
        $('.right-nav')
          .stop()
          .animate({
            bottom: '-' + r,
            slow: '300'
          });
        $('#dealInner').removeClass('hide');
        $('#close').addClass('hide');
      });
    });
  }

  ngAfterViewInit() {
    $(document).ready(() => {
        var element = null;
        $('table tr td input').focus((e) => {
           element =  e.target.parentNode.parentNode.parentNode;
           $(element).find('th').css('background-color', 'lightgreen');
        }).blur(() => {
           $(element).find('th').css('background-color', 'inherit');
        })
    })
}

  readCSV(event) {
    let files: FileList = event.target.files;
    if (files && files.length > 0) {
      let file: File = files.item(0);
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let csvData: string = reader.result as string;
        this.papa.parse(csvData, {
          complete: (result) => {
            result.data.map((parentArray, parentArrayIndex) => {
              parentArray.map((value, childArrayIndex) => {
                $(
                  "table tr td #input-" +
                    parentArrayIndex +
                    "-" +
                    childArrayIndex
                ).val(value);
                this.getRangeValues(
                  value,
                  this.CYL_RANGE[childArrayIndex],
                  this.SPH_RANGE[parentArrayIndex]
                );
              });
            });
          },
        });
      };
    }
  }

  getInitForm() {
    this.requestForm = new FormGroup({
      sections: new FormArray([]),
    });
  }

  /**
   * @name ngOnChanges
   * @desc Fires when change in input properties are detected
   * @return void
   */
  ngOnChanges(changes: SimpleChanges) {
    this.stockLense = [];
    this.getInitForm();
    if (changes && changes.dataSource && Object.keys(changes.dataSource.currentValue).length > 0) {
      this.rangeStockListPayload = [];
      this.previousStock = false;
      this.inputType = changes.dataSource.currentValue.inputType;
      this.stockIcon = changes.dataSource.currentValue.stockIcon;
      this.totalToggle = changes.dataSource.currentValue.totalToggle;
      this.showCurrentStock = changes.dataSource.currentValue.showCurrentStock;
      this.showPreviousStock =
        changes.dataSource.currentValue.showPreviousStock;
      this.hideClearBtn = changes.dataSource.currentValue.hideClearBtn;
      // this.previousStock = changes.dataSource.currentValue.previousStock;
      this.readonly = changes.dataSource.currentValue.readonly;
      this.displayOnly = changes.dataSource.currentValue.displayOnly;
      this.addSection(changes.dataSource.currentValue);
      this.checkRange();
      if (changes.dataSource.currentValue.isEdit) {
        if (changes.dataSource.currentValue.rangeStockListPayload) {
          this.setInventoryFormData(
            changes.dataSource.currentValue.rangeStockListPayload
          );
          changes.dataSource.currentValue.rangeStockListPayload.forEach(element => {
            let obj = {
              qty: element.qty,
              customerId: this.dataSource.customerId,
              itemCatalogGroupId: element.itemCatalogGroupId,
              focalId: element.focalId,
              brandMasterId: element.brandMasterId,
              sideName: element.sideName,
              size: element.size,
              itemName: element.itemName,
              itemTypeMasterId: element.itemTypeMasterId ? element.itemTypeMasterId : 0,
              lensSideId: element.lensSideId,
              salesOrderId: element.salesOrderId ? element.salesOrderId : 0,
              salesOrderLensId: element.salesOrderLensId ? element.salesOrderLensId : 0,
              vendorItemCatalogId: element.vendorItemCatalogId ? element.vendorItemCatalogId : 0,
              sph: element.sph,
              cyl: element.cyl,
              addf: null,
              axis: null,
              isBarcodeSingleSide: false,
              unitPrice: element.unitPrice,
              pricingTypeId: element.pricingTypeId ? element.pricingTypeId : 0,
              mplDetailId: element.mplDetailId ? element.mplDetailId : 0,
              total: element.qty * element.unitPrice,
              uomId: element.uomId,
              isTranspose: false,
              addDateTime: element.addDateTime,
              addUserId: element.addUserId,
              updateDateTime: element.updateDateTime,
              updateUserId: element.updateUserId,
              active: true
            };
            this.stockLense.push(obj);
            this.stockLense.map((item, index) => {
              item["index"] = index;
            });
          });
         
        }
        // this.estimatePrice(changes.dataSource.currentValue.rangeStockListPayload);
        this.isEdit = true;
        this.holdSalesOrder = changes.dataSource.currentValue.holdSalesOrder;
      }
      // if (changes.dataSource.currentValue.isValidate) {
      //   const payload = {
      //     itemCatalogId: this.dataSource.itemCatalogId,
      //     focalId: this.dataSource.focalId,
      //     sphmin:
      //       changes.dataSource.currentValue.validateRange.sphmin ||
      //       changes.dataSource.currentValue.validateRange.sphmin == 0
      //         ? changes.dataSource.currentValue.validateRange.sphmin
      //         : 0,
      //     sphmax:
      //       changes.dataSource.currentValue.validateRange.sphmax ||
      //       changes.dataSource.currentValue.validateRange.sphmax == 0
      //         ? changes.dataSource.currentValue.validateRange.sphmax
      //         : 0,
      //     cylmin:
      //       changes.dataSource.currentValue.validateRange.cylmin ||
      //       changes.dataSource.currentValue.validateRange.cylmin == 0
      //         ? changes.dataSource.currentValue.validateRange.cylmin
      //         : 0,
      //     cylmax:
      //       changes.dataSource.currentValue.validateRange.cylmax ||
      //       changes.dataSource.currentValue.validateRange.cylmax == 0
      //         ? changes.dataSource.currentValue.validateRange.cylmax
      //         : 0,
      //     addmin:
      //       changes.dataSource.currentValue.validateRange.addmin ||
      //       changes.dataSource.currentValue.validateRange.addmin == 0
      //         ? changes.dataSource.currentValue.validateRange.addmin
      //         : 0,
      //     addmax:
      //       changes.dataSource.currentValue.validateRange.addmax ||
      //       changes.dataSource.currentValue.validateRange.addmax == 0
      //         ? changes.dataSource.currentValue.validateRange.addmax
      //         : 0,
      //     // cxmin:
      //     //   changes.dataSource.currentValue.validateRange.cxmin ||
      //     //   changes.dataSource.currentValue.validateRange.cxmin == 0
      //     //     ? changes.dataSource.currentValue.validateRange.cxmin
      //     //     : 0,
      //     // cxmax:
      //     //   changes.dataSource.currentValue.validateRange.cxmax ||
      //     //   changes.dataSource.currentValue.validateRange.cxmax == 0
      //     //     ? changes.dataSource.currentValue.validateRange.cxmax
      //     //     : 0,
      //   };
      //   // this.barCodeSph = this.sharedService.generateRange((changes.dataSource.currentValue.sphmin as string).replace('-',''),(changes.dataSource.currentValue.sphmax as string).replace('-',''), 0.25);
      //   // this.barCodeCyl = this.sharedService.generateRange((changes.dataSource.currentValue.cylmin as string).replace('-',''),(changes.dataSource.currentValue.cylmax as string).replace('-',''), 0.25);
      //   this.validateRangeByPower(payload);
      // }
    }
  }

  /**
   * @name getSPH
   * @param {number} range1
   * @param {number} range2
   * @returns sph range
   * @memberof salesService
   */
  getSPH(range1: number, range2: number) {
    this.loadingSphRange = true;
    this.sharedService
      .getSPH(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.sphRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.sphRangeList = response.data.payload;
            }
          }
          this.loadingSphRange = false;
        }
      })
      .catch((error) => {
        this.loadingSphRange = false;
        console.log(error);
      });
  }
  /**
   * @name getCYL
   * @param {number} range1
   * @param {number} range2
   * @returns cyl range
   * @memberof salesService
   */
  getCYL(range1: number, range2: number) {
    this.loadingCylRange = true;
    this.sharedService
      .getCYL(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.cylRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.cylRangeList = response.data.payload;
            }
          }
          this.loadingCylRange = false;
        }
      })
      .catch((error) => {
        this.loadingCylRange = false;
        console.log(error);
      });
  }

  /**
   * @name addSection
   * @param data
   * @returns void
   * @description create section array
   */
  addSection(data?: any): void {
    if (data) {
      if (data.active) {
        this.showGenerateBtn = data.generateBtn;
        this.isCsv = data.csv;
        this.sectionsArray.push(this.createSection(data));
      }
    }
  }

  /**
   * @name createSection
   * @param data
   * @return FormGroup
   * @description section controls
   */
  createSection(data?: any): FormGroup {
    return new FormGroup({
      condition: new FormControl(data ? data.condition : "", []),
      active: new FormControl(data ? data.active : false, []),
      childern: new FormArray(this.addChildern(data.childern)),
    });
  }

  /**
   * @name addChildern
   * @param data
   * @returns childern array
   */
  addChildern(data?: any) {
    const formArray = [];
    data.forEach((child) => {
      formArray.push(this.createChild(child));
    });
    return formArray;
  }

  /**
   * @name createChild
   * @param child
   * @return  childer controls
   * @deprecated child formcontrols
   */
  createChild(child?): FormGroup {
    return new FormGroup({
      name: new FormControl(child ? child.name : "", []),
      min: new FormControl(child ? child.min : "", [Validators.required]),
      max: new FormControl(child ? child.max : "", [Validators.required]),
      // min: new FormControl(child ? (child.valueType ? child.min : child.min.toString().replace('-','')) : '', [Validators.required]),
      // max: new FormControl(child ? (child.valueType ? child.max : child.max.toString().replace('-','')) : '', [Validators.required]),
      valueType: new FormControl(child.valueType, []),
      // valueType: new FormControl(child.name === 'CYL' ? true : false, [])
    });
  }

  /**
   * @name getAvailableStock
   * @param {*} e
   * @param {*} sph
   * @param {*} cyl
   * @memberof CylFormComponent
   */
  getAvailableStock(e, sph: any, cyl: any) {
    this.availableStock = 0;
    this.loadingStockAvailable = true;
    let payload = {
      itemCatalogId: this.dataSource.itemCatalogId,
      sph: sph,
      cylOrAdd: cyl,
    };
    this.sharedService
      .getAvailableStock(payload)
      .then((response: any) => {
        if (response.data.completed) {
          this.loadingStockAvailable = false;
          this.availableStock = response.data.payload;
          this.actionSheetTarget = "#img-" + e;
          this.actionSheetVisible = true;
        } else {
          this.loadingStockAvailable = false;
          this.availableStock = 0;
          console.error(response);
        }
      })
      .catch((error) => {
        this.availableStock = 0;
        this.loadingStockAvailable = false;
        console.log(error);
      });
  }

  /**
   * @name validateBarcodeRequestRange
   * @param {number} itemCatalogId
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateBarcodeRequestRange(payload) {
    this.rangeLoading = true;
    this.sharedService
      .validateBarcodeRequestRange(payload)
      .then((response: any) => {
        if (response.data.completed && response.data.payload.length > 0) {
          this.rangeLoading = false;
          this.setBarCodeRange(response.data.payload);
        } else {
          this.rangeLoading = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.rangeLoading = false;
        console.log(error);
      });
  }
  /**
   * @name sectionsArray
   * @returns section array controls
   */
  get sectionsArray() {
    return this.requestForm.get("sections") as FormArray;
  }

  /**
   * @name getChildern
   * @param form section item index
   * @returns childern array controls
   */
  getChildern(form) {
    return form.controls.childern.controls;
  }

  /**
   * @name saveStockRequest
   * @param {FormGroup} requestForm
   * @returns payload form cxtypedetail
   */
  saveStockRequest(requestForm: FormGroup) {
    this.loadingIndicator = true;
    let sph_min = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("min").value;
      let sph_max = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
      let cyl_min = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("min").value;
      let cyl_max = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("max").value;
      let cyl_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("valueType").value;
      let sph_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("valueType").value;
      this.stockLense.forEach(element => {
        this.totalAmount = (element.qty * element.unitPrice) + this.totalAmount;
        this.itemQty = element.qty + this.itemQty;
      });
    let payload = {
      sphmin: parseFloat(sph_min),
      sphmax: parseFloat(sph_max),
      cylmin: parseFloat(cyl_min),
      cylmax: parseFloat(cyl_max),
      strSphmin: sph_min,
      strSphmax: sph_max,
      strCylmin: cyl_min,
      strCylmax: cyl_max,
      rangeStockListPayload:  this.stockLense,//this.rangeStockListPayload,
      totalQTY: this.totalQTY,
      totalAmount: this.totalAmount
    };
    
    if (requestForm.invalid) {
      return false;
    }
    if (this.totalAmount == 0) {
      const option = {
        width: 300,
        displayTime: 5000,
        message: "something was wrong in total amount..",
        type: "error",
      };
      notify(option);
      return false;
    }
    this.optSelected.emit(payload);
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 300);
  }

  /**
   * @name clearField
   * @return void
   */
  clearField(): void {
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(0)
      .get("min")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(0)
      .get("max")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(1)
      .get("min")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(1)
      .get("max")
      .setValue("");
    // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
    // while (child.length !== 0) {
    //     child.removeAt(0);
    // }
    this.rangeStockListPayload = [];
    this.SPH_RANGE = [];
    this.CYL_RANGE = [];
  }
  get availableStockInHand() {
    return Math.floor(Math.random() * 500);
  }

  /**
   * @name addRows
   * @return number of rows
   * @description modal popup
   */
  addRows() {
    const dialogRef = this.dialog.open(DialogAddRows, {
      width: "250px",
      data: { label: "CYL" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.rows) {
        for (let i = 0; i < parseInt(result.rows); i++) {
          // this.createCxTypeDetail();
          this.updatedMaxVal("increment");
        }
      }
    });
  }

  /**
   * @name incrementMaxVal
   * @returns updated max value
   */
  updatedMaxVal(label: string) {
    const val = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
    if (label === "increment") {
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("max")
        .setValue(parseInt(val) + 1);
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("max")
        .setValue(parseInt(val) + 1);
    }
    if (label === "decrement") {
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("max")
        .setValue(parseInt(val) - 1);
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("max")
        .setValue(parseInt(val) - 1);
    }
  }

  /**
   * @name removeIndex
   * @param index
   * @return void
   * @description removing row index
   */
  removeIndex(index) {
    // this.SPH_RANGE.splice(index, 1);
    // this.CYL_RANGE.splice(index, 1);
    // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    // item.removeAt(index);
    this.updatedMaxVal("decrement");
    // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
  }

  getRangeValues(val, cyl, sph) {
    if (!val) {
      // if value empty then remove it from collection
      for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        if (
          this.rangeStockListPayload[i].cyl === cyl &&
          this.rangeStockListPayload[i].sph === sph
        ) {
          this.rangeStockListPayload.splice(i, 1);
          break;
        }
      }
      this.totalQTY = this.sharedService.getTotal(
        this.rangeStockListPayload,
        "vendorItemCode"
      );
      return;
    }
    // check whether the value already exist then modify it
    if (
      this.rangeStockListPayload.some((o) => o.cyl === cyl && o.sph === sph)
    ) {
      for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        if (
          this.rangeStockListPayload[i].cyl === cyl &&
          this.rangeStockListPayload[i].sph === sph
        ) {
          this.rangeStockListPayload[i]["vendorItemCode"] = val;
          break;
        }
      }
      this.totalQTY = this.sharedService.getTotal(
        this.rangeStockListPayload,
        "vendorItemCode"
      );
    } else {
      // else not found so push it
      const rangePairObj = {
        cx: null,
        cxtypeId: null,
        vendorItemCode: val,
        cc: null,
        add: null,
        axis: null,
        cyl: cyl,
        ct: null,
        et: null,
        tc: null,
        wt: null,
        sph: sph,
        // qty: eval(val)
      };
      //this.totalQTY += val;
      this.rangeStockListPayload.push(rangePairObj);
      this.totalQTY = this.sharedService.getTotal(
        this.rangeStockListPayload,
        "vendorItemCode"
      );
    }
  }

  checkRange() {
    this.SPH_RANGE = [];
    this.CYL_RANGE = [];
    const sph_min = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("min").value
    );
    const sph_max = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(0)
        .get("max").value
    );
    const sph_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("valueType").value;
    const cyl_min = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("min").value
    );
    const cyl_max = parseInt(
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .at(1)
        .get("max").value
    );
    const cyl_valueType = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("valueType").value;
    if (sph_max < sph_min) {
      return (this.sphError = true);
    } else {
      this.sphError = false;
      // if ((sph_min && sph_max || sph_min === 0 && sph_max || sph_min === 0 && sph_max == 0))
      if (sph_min !== null && sph_max !== null) {
        this.SPH_RANGE = this.sharedService.generateRange(
          sph_min,
          sph_max,
          0.25
        );
        // this.SPH_RANGE = this.sharedService.generateRange(sph_valueType ? -sph_max : sph_min, sph_valueType ? -sph_min : sph_max, 0.25);
        if (this.check_number(sph_min)) {
          // this.getSPH(0, -35);
            this.SPH_RANGE.reverse();
          
            ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("valueType").setValue(true);
        } else {
      //     this.getSPH(0, 35);
          ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("valueType").setValue(false);
        }
      } else {
        this.SPH_RANGE = null;
      }
    }
    if (cyl_max < cyl_min) {
      return (this.cylError = true);
    } else {
      this.cylError = false;
      // if ((cyl_min && cyl_max || cyl_min === 0 && cyl_max || cyl_min === 0 && cyl_max === 0)) {
      if (cyl_min !== null && cyl_max !== null) {
        // (cyl_max < cyl_min) ? this.isCylInvalid = true : this.isCylInvalid = false;
        this.CYL_RANGE = this.sharedService.generateRange(
          cyl_min,
          cyl_max,
          0.25
        );
        if (this.check_number(cyl_min)) {
          this.CYL_RANGE.reverse();
          // this.getCYL(0, -15);
          ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(1)
      .get("valueType").setValue(true);
        } else {
          // this.getCYL(0, 15);
          ((this.sectionsArray as FormArray)
          .at(0)
          .get("childern") as FormArray)
          .at(1)
          .get("valueType").setValue(false);
        }
      } else {
        this.CYL_RANGE = null;
      }
    }

      const payload = {
        itemCatalogId: this.dataSource.itemCatalogId,
        focalId: this.dataSource.focalId,
        sphmin: sph_min || sph_min == 0
            ? sph_min
            : 0,
        sphmax:
          sph_max ||
          sph_max == 0
            ? sph_max
            : 0,
        cylmin:
          cyl_min ||
          cyl_min == 0
            ? cyl_min
            : 0,
        cylmax:
          cyl_max ||
          cyl_max == 0
            ? cyl_max
            : 0,
        addmin: 0,
        addmax: 0,
      };
      // this.validateRangeByPower(payload);

  }

  toggleStock(label: string) {
    switch (label) {
      case "previous":
        this.previousStock = !this.previousStock;
        this.getAllAvailableStock(
          this.companyBranchId,
          this.dataSource.itemCatalogId,
          ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value > 0 ? true : false
        );
        break;
      case "current":
        this.currentStock = !this.currentStock;
        this.setCurrentStock(this.dataSource.rangeStockListPayload);
        break;
    }
  }
  /**
   * @name getAllAvailableStock
   * @param {number} companyBranchId
   * @param {number} itemCatalogId
   * @param {number} isPositiveRange
   * @returns
   * @memberof SharedService
   */
  getAllAvailableStock(
    companyBranchId: number,
    itemCatalogId: number,
    isPositiveRange: boolean
  ) {
    this.loadingAllAvailableStock = true;
    this.sharedService
      .getAllAvailableStock(companyBranchId, itemCatalogId, isPositiveRange)
      .then((response: any) => {
        if (response.data.completed) {
          this.checkRange();
          this.loadingAllAvailableStock = false;
          this.setAvailableStock(response.data.payload.availableStock);
        } else {
          this.loadingAllAvailableStock = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.loadingAllAvailableStock = false;
        console.log(error);
      });
  }
  /**
   * @name setCurrentStock
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setAvailableStock(dataSource) {
    // if (this.SPH_RANGE && this.SPH_RANGE.length > 0 && this.CYL_RANGE && this.CYL_RANGE.length > 0) {
    dataSource.forEach((data) => {
      this.SPH_RANGE.forEach((sph, sphIndex) => {
        this.CYL_RANGE.forEach((cyl, index) => {
          
            if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
              setTimeout(() => {
                $("table tr td #availableStock-" + sphIndex + "-" + index).text(
                  data.availableStock
                );
              }, 10);
            }
          });
        });
    });
    // } else {
    //     console.error('Line: 634-SPH AND CYL LENGHT 0');
    // }
  }
  /**
   * @name setCurrentStock
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setCurrentStock(dataSource) {
    if (
      this.SPH_RANGE &&
      this.SPH_RANGE.length > 0 &&
      this.CYL_RANGE &&
      this.CYL_RANGE.length > 0
    ) {
      this.SPH_RANGE.forEach((sph, sphIndex) => {
        this.CYL_RANGE.forEach((cyl, index) => {
          dataSource.forEach((data) => {
            if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
              setTimeout(() => {
                $("table tr td #currentStock-" + sphIndex + "-" + index).text(
                  data.vendorItemCode
                );
              }, 10);
            }
          });
        });
      });
    } else {
      console.error("Line: 617-SPH AND CYL LENGHT 0");
    }
  }
  /**
   * @name setInventoryFormData
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setInventoryFormData(dataSource) {
    this.SPH_RANGE.forEach((sph, sphIndex) => {
      this.CYL_RANGE.forEach((cyl, index) => {
        dataSource.forEach((data) => {
          if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
            this.getRangeValues(data.vendorItemCode, cyl, sph);
            setTimeout(() => {
              $("table tr td #input-" + sphIndex + "-" + index).val(
                data.vendorItemCode
              );
              if (this.isEdit) {
                this.totalQTY = this.sharedService.getTotal(
                  this.rangeStockListPayload,
                  "vendorItemCode"
                );
              }
            }, 10);
          }
        });
      });
    });
  }
  /**
   * @name setInventoryFormData
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setBarCodeRange(dataSource) {
    // if (data.sph && data.cyl) {
    this.SPH_RANGE.forEach((sphValue, sphIndex) => {
      this.CYL_RANGE.forEach((cylValue, index) => {
        dataSource.forEach((data) => {
          let s = sphValue.toString().replace("-", "");
          let c = cylValue.toString().replace("-", "");
          let sp = data.sph.toFixed(2).replace("-", "");
          let cy = data.cyl.toFixed(2).replace("-", "");
          $("table tr td #input-" + sphIndex + "-" + index).attr(
            "disabled",
            "disabled"
          );
          if (sp == s && cy == c) {
            // console.log('s', sp + ' ' + s)
            // console.log('c', cy + ' ' + c)
            setTimeout(() => {
              $("table tr td #input-" + sphIndex + "-" + index).removeAttr(
                "disabled"
              );
            }, 10);
          }
        });
      });
      // }
    });
  }

  /**
   * @name validateRangeByPower
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateRangeByPower(payload) {
    this.rangeLoading = true;
    this.sharedService
      .validateRangeByPower(payload)
      .then((response: any) => {
        if (response.data.completed && response.data.payload.length > 0) {
          this.rangeLoading = false;
           this.setRanges(response.data.payload);
        } else {
          this.rangeLoading = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.rangeLoading = false;
        console.log(error);
      });
  }
  
  /**
   * @name setRanges
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setRanges(dataSource) {
    // if (data.sph && data.cyl) {
      
    this.SPH_RANGE.forEach((sphValue, sphIndex) => {
      this.CYL_RANGE.forEach((cylValue, index) => {
        dataSource.forEach((data) => {
          const s = sphValue.toString().replace("-", "");
          const c = cylValue.toString().replace("-", "");
          const sp = data.sph.toFixed(2).replace("-", "");
          const cy = data.cyl.toFixed(2).replace("-", "");
          $("table tr td #input-" + sphIndex + "-" + index).attr(
            "disabled",
            "disabled"
          );
          if (sp == s && cy == c) {
            setTimeout(() => {
              if (data.isValidated) {
                $("table tr td #input-" + sphIndex + "-" + index).removeAttr("disabled");
              }
            }, 10);
          }
        });
      });
      // }
    });
  }
  toggleGenerateBtn() {
    if (
      ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
        .length > 0
    ) {
      const sph_min = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(0)
        .get("min").value;
      const sph_max = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(0)
        .get("max").value;
      const cyl_min = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(1)
        .get("min").value;
      const cyl_max = ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(1)
        .get("max").value;
      if (
        (sph_min && sph_max && cyl_min && cyl_max) ||
        (sph_min === 0 && sph_max && cyl_min === 0 && cyl_max) ||
        (sph_min === 0 && sph_max && cyl_min && cyl_max) ||
        (cyl_min === 0 && cyl_max && sph_min && sph_max) ||
        (sph_min === 0 && sph_max === 0 && cyl_min === 0 && cyl_max === 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  fixVerticalTabindex(selector) {
    if (typeof selector == "undefined") {
      selector = ".reset-tabindex";
    }
    let tabindex = 1;
    $(selector).each((i, tbl) => {
      $(tbl)
        .find("tr")
        .first()
        .find("td")
        .each((clmn, el) => {
          $(tbl)
            .find("tr td:nth-child(" + (clmn + 1) + ") div input")
            .each((j, input) => {
              $(input).attr("placeholder", tabindex);
              $(input).attr("tabindex", tabindex++);
            });
        });
    });
  }


  get watchRangeStockList() {
    if (this.rangeStockListPayload.length  == 0) {
      this.priceRange = [];
      this.itemQty = 0;
      this.totalAmount = 0;
      return false;
    } else {
      return true;
    }
  }

  customizeText(e) {
    return e.value;
  }
  customizeUnit(e) {
    return "Total: ";
  }

    /**
   * @name removeStockLensItem
   * @return void
   */
     removeStockLensItem(index: number) {
      this.stockLense.forEach((item, i) => {
        if (item.index == index) {
          $(item.id).val(null);
          this.stockLense.splice(i, 1);
        }
      });
    }

   /**
   * @name getFnPrice
   * @description price of item
   */
    getFnPrice(
      event: any,
      sph: any,
      cyl: any,
      addf: any,
      sphIndex,
      cylIndex
    ) {
    //   fromEvent(event,'keyup')
    // .pipe(
    //     filter(Boolean),
    //     debounceTime(300),
    //     distinctUntilChanged(),
    //     tap((e:KeyboardEvent) => {
          if (
            (sph || sph == 0) &&
            (cyl || cyl == 0 || addf || addf == 0)
          ) {
            if (event.value == 0 && event.value !== "") {
              $("table tr td #input-" + sphIndex + "-" + cylIndex).val(null);
              if (this.stockLense.length > 0) {
                let i = this.stockLense.findIndex(o => o.sph == sph && o.cyl == cyl);
                if (i !== -1) {
                  this.stockLense.splice(i, 1);
                }
              }
              const option = {
                width: 300,
                displayTime: 8000,
                message: "Qty must be greater than 0",
                type: "error",
              };
              notify(option);
              return;
            }
            if (event.value == "" && event.value !== 0) {
              $("table tr td #input-" + sphIndex + "-" + cylIndex).val(null);
              if (this.stockLense.length > 0) {
                let i = this.stockLense.findIndex(o => o.sph == sph && o.cyl == cyl);
                if (i !== -1) {
                  this.stockLense.splice(i, 1);
                }
               
              }
              return;
            }
              this.sharedService
                .getFnPrice(
                  JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId,
                  this.dataSource.customerId,
                  this.dataSource.itemCatalogId,
                  this.dataSource.focalId,
                  this.dataSource.brandMasterId,
                  sph,
                  cyl ? cyl : 0,
                  addf ? addf : 0,
                  false,
                  JSON.parse(localStorage.getItem("selectedCompanyBranch"))
                    .companyBranchId
                )
                .then((response: any) => {
                  if (response.data.completed && response.data.payload) {
                    if (response.data.payload.price == 0) {
                      const option = {
                        width: 300,
                        displayTime: 8000,
                        message: "Price not available for selected item!",
                        type: "warning",
                      };
                      notify(option);
                      return;
                    }
                    if (this.stockLense.length > 0) {
                      let stockItem = this.stockLense.find(
                        (item) =>
                          item.itemCatalogGroupId == this.dataSource.itemCatalogId &&
                          item.lensSideId == this.dataSource.lensSideId &&
                          item.sph == sph && item.cyl == cyl
                      );
                      if (stockItem) {
                        // let itemQty = this.stockLense[stockItem.index].qty;
                        // event.value = parseInt(event.value) + parseInt(itemQty);
                        this.stockLense.splice(stockItem.index, 1);
                        this.loadingIndicator = true;
                        let obj = {
                          id: "table tr td #input-" + sphIndex + "-" + cylIndex,
                          qty: event.value,
                          customerId: this.dataSource.customerId,
                          itemCatalogGroupId: this.dataSource.itemCatalogId,
                          focalId: this.dataSource.focalId,
                          brandMasterId: this.dataSource.brandMasterId,
                          sideName: this.dataSource.sideName,
                          size: this.dataSource.size,
                          itemName: this.dataSource.itemName,
                          itemTypeMasterId: this.dataSource.itemTypeMasterId,
                          lensSideId: this.dataSource.lensSideId,
                          salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                          salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                          vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                          sph: sph,
                          cyl: cyl,
                          addf: null,
                          axis: null,
                          isBarcodeSingleSide: false,
                          unitPrice: response.data.payload.price,
                          pricingTypeId: response.data.payload.pricingTypeId,
                          mplDetailId: response.data.payload.mplDetailId,
                          total: event.value * response.data.payload.price,
                          uomId: response.data.payload.uomId,
                          excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                          isTranspose: false,
                          addDateTime: AppConstant.ksaDefaultDate(),
                          addUserId: AppConstant.getUserId(),
                          updateDateTime: AppConstant.ksaDefaultDate(),
                          updateUserId: AppConstant.getUserId(),
                          active: true
                        };
                        this.stockLense.push(obj);
                        this.stockLense.map((item, index) => {
                          item["index"] = index;
                          // item["total"] = item.qty * item.unitPrice;
                        });
                      
                        this.loadingIndicator = false;
                        // this.saveSwal.swalOptions = {
                        //   icon: "success",
                        //   titleText:
                        //     "Item # " +
                        //     stockItem.itemCatalogGroupId +
                        //     " already exits!. Do you want to update Qty?...",
                        //   showConfirmButton: true,
                        //   confirmButtonText: "Yes",
                        //   cancelButtonText: "No",
                        //   showCancelButton: true,
                        //   allowOutsideClick: false,
                        //   allowEscapeKey: false,
                        // };
                        // this.saveSwal.fire().then((res: any) => {
                        //   if (res && res.dismiss == "cancel") {
                        //     this.loadingIndicator = true;
                        //     let obj = {
                        //       qty: event.value,
                        //       customerId: this.dataSource.customerId,
                        //       itemCatalogGroupId: this.dataSource.itemCatalogId,
                        //       focalId: this.dataSource.focalId,
                        //       brandMasterId: this.dataSource.brandMasterId,
                        //       sideName: this.dataSource.sideName,
                        //       size: this.dataSource.size,
                        //       itemName: this.dataSource.itemName,
                        //       itemTypeMasterId: this.dataSource.itemTypeMasterId,
                        //       lensSideId: this.dataSource.lensSideId,
                        //       salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                        //       salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                        //       vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                        //       sph: sph,
                        //       cyl: cyl,
                        //       addf: null,
                        //       axis: null,
                        //       isBarcodeSingleSide: false,
                        //       unitPrice: response.data.payload.price,
                        //       pricingTypeId: response.data.payload.pricingTypeId,
                        //       mplDetailId: response.data.payload.mplDetailId,
                        //       total: event.value * response.data.payload.price,
                        //       uomId: response.data.payload.uomId,
                        //       excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                        //       isTranspose: false,
                        //       addDateTime: AppConstant.ksaDefaultDate(),
                        //       addUserId: AppConstant.getUserId(),
                        //       updateDateTime: AppConstant.ksaDefaultDate(),
                        //       updateUserId: AppConstant.getUserId(),
                        //       active: true
                        //     };
                        //     this.stockLense.push(obj);
                        //     this.stockLense.map((item, index) => {
                        //       item["index"] = index;
                        //       // item["total"] = item.qty * item.unitPrice;
                        //     });
                        //     this.loadingIndicator = false;
                        //     // return false;
                        //   } else {
                        //     let itemQty = this.stockLense[stockItem.index].qty;
                        //     event.value = parseInt(event.value) + parseInt(itemQty);
                        //     this.stockLense.splice(stockItem.index, 1);
                        //     this.loadingIndicator = true;
                        //     let obj = {
                        //       qty: event.value,
                        //       customerId: this.dataSource.customerId,
                        //       itemCatalogGroupId: this.dataSource.itemCatalogId,
                        //       focalId: this.dataSource.focalId,
                        //       brandMasterId: this.dataSource.brandMasterId,
                        //       sideName: this.dataSource.sideName,
                        //       size: this.dataSource.size,
                        //       itemName: this.dataSource.itemName,
                        //       itemTypeMasterId: this.dataSource.itemTypeMasterId,
                        //       lensSideId: this.dataSource.lensSideId,
                        //       salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                        //       salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                        //       vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                        //       sph: sph,
                        //       cyl: cyl,
                        //       addf: null,
                        //       axis: null,
                        //       isBarcodeSingleSide: false,
                        //       unitPrice: response.data.payload.price,
                        //       pricingTypeId: response.data.payload.pricingTypeId,
                        //       mplDetailId: response.data.payload.mplDetailId,
                        //       total: event.value * response.data.payload.price,
                        //       uomId: response.data.payload.uomId,
                        //       excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                        //       isTranspose: false,
                        //       addDateTime: AppConstant.ksaDefaultDate(),
                        //       addUserId: AppConstant.getUserId(),
                        //       updateDateTime: AppConstant.ksaDefaultDate(),
                        //       updateUserId: AppConstant.getUserId(),
                        //       active: true
                        //     };
                        //     this.stockLense.push(obj);
                        //     this.stockLense.map((item, index) => {
                        //       item["index"] = index;
                        //       // item["total"] = item.qty * item.unitPrice;
                        //     });
                          
                        //     this.loadingIndicator = false;
                        //   }
                        // });
                        // const option = {
                        //   width: 300,
                        //   displayTime: 8000,
                        //   message: "Item already added!",
                        //   type: "error",
                        // };
                        // notify(option);
                      } else {
                        this.loadingIndicator = true;
                        let obj = {
                          id: "table tr td #input-" + sphIndex + "-" + cylIndex,
                          qty: event.value,
                          customerId: this.dataSource.customerId,
                          itemCatalogGroupId: this.dataSource.itemCatalogId,
                          focalId: this.dataSource.focalId,
                          brandMasterId: this.dataSource.brandMasterId,
                          sideName: this.dataSource.sideName,
                          size: this.dataSource.size,
                          itemName: this.dataSource.itemName,
                          itemTypeMasterId: this.dataSource.itemTypeMasterId,
                          lensSideId: this.dataSource.lensSideId,
                          salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                          salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                          vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                          sph: sph,
                          cyl: cyl,
                          addf: null,
                          axis: null,
                          isBarcodeSingleSide: false,
                          unitPrice: response.data.payload.price,
                          pricingTypeId: response.data.payload.pricingTypeId,
                          mplDetailId: response.data.payload.mplDetailId,
                          total: event.value * response.data.payload.price,
                          uomId: response.data.payload.uomId,
                          excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                          isTranspose: false,
                          addDateTime: AppConstant.ksaDefaultDate(),
                          addUserId: AppConstant.getUserId(),
                          updateDateTime: AppConstant.ksaDefaultDate(),
                          updateUserId: AppConstant.getUserId(),
                          active: true
                        };
                        this.stockLense.push(obj);
                        this.stockLense.map((item, index) => {
                          item["index"] = index;
                          // item["total"] = item.qty * item.unitPrice;
                        });
                        this.loadingIndicator = false;
                      }
                    } else {
                      let obj = {
                        id: "table tr td #input-" + sphIndex + "-" + cylIndex,
                        qty: event.value,
                        customerId: this.dataSource.customerId,
                        itemCatalogGroupId: this.dataSource.itemCatalogId,
                        focalId: this.dataSource.focalId,
                        brandMasterId: this.dataSource.brandMasterId,
                        sideName: this.dataSource.sideName,
                        size: this.dataSource.size,
                        itemName: this.dataSource.itemName,
                        itemTypeMasterId: this.dataSource.itemTypeMasterId,
                        lensSideId: this.dataSource.lensSideId,
                        salesOrderId: this.dataSource.salesOrderId ? this.dataSource.salesOrderId : 0,
                        salesOrderLensId: this.dataSource.salesOrderLensId ? this.dataSource.salesOrderLensId : 0,
                        vendorItemCatalogId: this.dataSource.vendorItemCatalogId ? this.dataSource.vendorItemCatalogId : 0,
                        sph: sph,
                        cyl: cyl,
                        addf: null,
                        axis: null,
                        isBarcodeSingleSide: false,
                        unitPrice: response.data.payload.price,
                        pricingTypeId: response.data.payload.pricingTypeId,
                        mplDetailId: response.data.payload.mplDetailId,
                        total: event.value * response.data.payload.price,
                        uomId: response.data.payload.uomId,
                        excludeBrandDiscount: response.data.payload.excludeBrandDiscount,
                        isTranspose: false,
                        addDateTime: AppConstant.ksaDefaultDate(),
                        addUserId: AppConstant.getUserId(),
                        updateDateTime: AppConstant.ksaDefaultDate(),
                        updateUserId: AppConstant.getUserId(),
                        active: true
                      };
                      this.stockLense.push(obj);
                      this.stockLense.map((item, index) => {
                        item["index"] = index;
                      });
                    }
                   
                    console.log(this.stockLense)
                    // this.normalStockForm
                    //   .get("filtersInventory")
                    //   .get("unitPrice")
                    //   .setValue(response.data.payload.price);
                    // this.normalStockForm
                    //   .get("filtersInventory")
                    //   .get("uomId")
                    //   .setValue(response.data.payload.uomId);
                    // this.normalStockForm
                    //   .get("filtersInventory")
                    //   .get("excludeBrandDiscount")
                    //   .setValue(response.data.payload.excludeBrandDiscount);
                    // this.normalStockForm
                    //   .get("filtersInventory")
                    //   .get("pricingTypeId")
                    //   .setValue(response.data.payload.pricingTypeId);
                    // this.normalStockForm
                    //   .get("filtersInventory")
                    //   .get("mplDetailId")
                    //   .setValue(response.data.payload.mplDetailId);
                  } else {
                    const option = {
                      width: 300,
                      displayTime: 8000,
                      message: "No Price Found!",
                      type: "error",
                    };
                    notify(option);
                    console.error(response);
                  }
                })
                .catch((error) => {
                  this.loadingIndicator = false;
                  const option = {
                    width: 300,
                    displayTime: 8000,
                    message: error.message,
                    type: "error",
                  };
                  console.log(error);
                  notify(option);
                });
          }
       // })
    // )
    // .subscribe();
    
    }
  /**
   * @name estimatePrice
   * @param {list} range list
   * @return price
   */
  estimatePrice(rangeStockListPayload: any) {
    if (this.rangeStockListPayload.length > 0) {
      let rangePriceRequest = [];
      let payload = {};
      this.priceRange = [];
      this.totalAmount = 0;
      this.itemQty = 0;
      this.loadingIndicator = true;
      console.log("rangeStockListPayload", rangeStockListPayload);
      rangeStockListPayload.forEach((element) => {
        rangePriceRequest.push({
          sph: element.sph,
          cyl: element.cyl,
          add: 0,
          qty: element.vendorItemCode,
        });
      });
      payload = {
        rangePriceRequestDetail: rangePriceRequest,
        itemCatalogId: this.dataSource.itemCatalogId,
        focalId: this.dataSource.focalId,
        customerId: this.dataSource.customerId,
        companyId: JSON.parse(localStorage.getItem("selectedCompanyBranch"))
          .companyId,
      };
      this.sharedService
        .getRangePrice(payload)
        .then((response: any) => {
          if (response.data.completed) {
            console.log("response.data.payload", response.data.payload);
            this.priceRange = response.data.payload;
            response.data.payload.forEach(element => {
              this.totalAmount = element.amount + this.totalAmount;
              this.itemQty = element.qty + this.itemQty;
            });
            
          } else {
            const option = {
              width: 300,
              displayTime: 5000,
              message: "Price not found...",
              type: "warning",
            };
            notify(option);
            this.totalAmount = 0;
            this.itemQty = 0;
          }
          this.loadingIndicator = false;
         
        })
        .catch((error) => {
          this.totalAmount = 0;
          this.itemQty = 0;
          this.loadingIndicator = false;
          console.log(error);
        });
    }
  }
   /**
   * @name check_number
   * @param num_value
   * @returns bool
   */
  check_number(num_value) {
    if (num_value >= 0) {
      return false;
    } else {
      return true;
    }
  }

  resetAll() {
    this.dataSource = {};
    this.priceRange = [];
    this.rangeStockListPayload = [];
    this.totalQTY = 0
    this.totalAmount = 0;
    this.requestForm.reset();
    this.onResetAll.emit();
  }
  /**
   * @name destroy component
   */
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.subscription.unsubscribe();
  }
}
