
<form [formGroup]="filterForm">
<app-panel [footer]="true" [backArrow]="false">
  <div class="heading">
     {{heading}}
  </div>
  <div class="body">
      <div class="row">
          <div class="col-md-2">
              <div class="dx-field">
                  <div>Material <span>*</span> :</div>
                  <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="allMaterials"
                      placeholder="{{loadingMaterials ? 'Please wait...' : 'Choose Material'}}"
                      [showClearButton]="true" displayExpr="materialName" valueExpr="materialId"
                      [searchEnabled]="true"
                      formControlName="materialId"
                      (onValueChanged)="
                        getAllIndexByMaterialId(
                            filterForm
                            .get('materialId').value
                        )">
                      <dx-validator>
                          <dxi-validation-rule type="required" message="Material is required">
                          </dxi-validation-rule>
                      </dx-validator>
                  </dx-select-box>
              </div>
          </div>
          <!-- <div class='col-md-3'>
              <div class="dx-field">
                  <div>Item Type <span>*</span>:</div>
                  <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="itemTypesList"
                      placeholder="{{loadingItemTypeMaster ? 'Please wait...' : 'Choose Item Type'}}"
                      [showClearButton]="true" displayExpr="itemTypeName" valueExpr="itemTypeMasterId"
                      [searchEnabled]="true" formControlName="itemTypeMasterId"
                      [readOnly]="!itemTypesList?.length"
                      (onValueChanged)="getFocalByItemTypeMasterId($event.value, type)">
                      <dx-validator>
                          <dxi-validation-rule type="required" message="Item Type is required">
                          </dxi-validation-rule>
                      </dx-validator>
                  </dx-select-box>
              </div>
          </div> -->
          <div class='col-md-2'>
              <div class="dx-field">
                  <div>Focal <span>*</span>:</div>
                  <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="focalIdsList"
                      placeholder="{{loadingFocal ? 'Please wait...' : 'Choose Focal'}}"
                      [showClearButton]="true" displayExpr="focalName" valueExpr="focalId"
                      [readOnly]="!focalIdsList?.length" [searchEnabled]="true"
                      formControlName="focalId"
                      (onValueChanged)="filterAllItemCatalogs($event, type, filterForm.get('focalId').value)"
                      >
                      <dx-validator>
                          <dxi-validation-rule type="required" message="Focal ID is required">
                          </dxi-validation-rule>
                      </dx-validator>
                  </dx-select-box>
              </div>
          </div>
          <!-- <div class="col-md-3">
              <div class="dx-field">
                  <div>Brands <span>*</span>:</div>
                  <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="brandsList"
                      placeholder="{{loadingBrand ? 'Please wait...' : 'Choose Brand Name'}}"
                      [showClearButton]="true" displayExpr="brandName" valueExpr="brandMasterId"
                      [readOnly]="!brandsList?.length" [searchEnabled]="true"
                      formControlName="brandMasterId"
                      (onValueChanged)="getSubBrandByItemTypeMasterIdANDbrandMasterId(filterForm.get('brandMasterId').value, type);getVendorByBrandMasterID(filterForm.get('brandMasterId').value, type)">
                  </dx-select-box>
              </div>
          </div> -->
          <div class="col-md-2">
              <div class="dx-field">
                  <div>Sub Brand Name:</div>
                  <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="subBrandsList"
                      placeholder="{{loadingSubBrand ? 'Please wait...' : 'Choose Sub Brand Name'}}"
                      [showClearButton]="true" displayExpr="subBrandName" valueExpr="subBrandMasterId"
                      [readOnly]="!subBrandsList?.length" [searchEnabled]="true"
                      formControlName="subBrandMasterId"
                      (onValueChanged)="filterAllItemCatalogs($event, type, filterForm.get('subBrandMasterId').value)">
                  </dx-select-box>
              </div>
          </div>
          <ng-template [ngIf]="itemTypeMasterId == 3 || itemTypeMasterId == 4">
              <div class="col-md-6">
                  <div class="dx-field">
                      <div>Vendor <span>*</span>:</div>
                      <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="vendorList"
                       placeholder="{{loadingVendor ? 'Please wait...' : 'Choose Vendor'}}" [showClearButton]="true"
                          displayExpr="vendorName" valueExpr="vendorId" [searchEnabled]="true" 
                          [readOnly]="!vendorList?.length"
                          formControlName="vendorId" (onSelectionChanged)="filterAllItemCatalogs($event, type)">
                          <dx-validator>
                              <dxi-validation-rule type="required" message="Vendor is required">
                              </dxi-validation-rule>
                          </dx-validator>
                      </dx-select-box>
                  </div>
              </div>
          </ng-template>
          <div class="col-md-2" *ngIf="priceGroup">
            <div class="dx-field">
              <div>Indexs:</div>
              <dx-select-box
                [inputAttr]="{ autocomplete: 'off' }"
                [items]="indexList"
                placeholder="{{
                  loadingIndex ? 'Please wait...' : 'Choose Indexs'
                }}"
                [showClearButton]="true"
                displayExpr="indexValue"
                valueExpr="indexId"
                [readOnly]="!indexList?.length"
                [searchEnabled]="true"
                formControlName="indexId"
                (onValueChanged)="filterAllItemCatalogs($event, type)"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="col-md-4">
              <div class="dx-field" *ngIf="!priceGroup">
                  <!--getLensSidesByItemCatalogId(filterForm.get('itemCatalogGroupId').value)-->
                  <div>Group<span>*</span>:</div>
                  <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [dataSource]="itemCatalogList"
                      placeholder="{{loadingItemCatalog ? 'Please wait...' : 'Choose Item Catalog'}}"
                      [showClearButton]="true" displayExpr="itemName" valueExpr="itemCatalogGroupId"
                      [searchEnabled]="true" formControlName="itemCatalogGroupId"
                      (onValueChanged)="getPowerRanges($event)"
                      >
                      <dx-validator>
                          <dxi-validation-rule type="required" message="Item Catalog is required">
                          </dxi-validation-rule>
                      </dx-validator>
                  </dx-select-box>
              </div>
              <div class="dx-field" *ngIf="priceGroup">
                <!--getLensSidesByItemCatalogId(filterForm.get('itemCatalogGroupId').value)-->
                <div>Group<span>*</span>:</div>
                <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="priceGroupList"
                    placeholder="{{loadingItemCatalog ? 'Please wait...' : 'Choose Group'}}"
                    [showClearButton]="true" displayExpr="priceGroupName" valueExpr="priceGroupId"
                    [searchEnabled]="true" formControlName="itemCatalogGroupId"
                    [readOnly]="!priceGroupList?.length"
                    (onValueChanged)="getPowerRanges($event)"
                    >
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Group is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
          </div>
          <div class="col-md-2">
            <div class="dx-field">
                <div>Power Range<span>*</span>:</div>
                <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [items]="powerRangeList"
                    placeholder="{{loadingPowerRange ? 'Please wait...' : 'Choose Power Range'}}"
                    [showClearButton]="true" displayExpr="powerRange" valueExpr="rowIndex"
                    [searchEnabled]="true" formControlName="powerRange"
                    [readOnly]="!powerRangeList?.length"
                    (onSelectionChanged)='pushPowerRange($event)'
                    >
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Power Range is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
          </div>
      </div>
  </div>
  <div class="footer">
    <dx-button id="button" [disabled]="loading" text="Add" type="default" class="float-right ml-3"
    (click)="submitData(filterForm)" [useSubmitBehavior]="true">
  </dx-button>
  <dx-button id="isEdit" text="Refresh" type="normal" class="float-right"
  (click)="refreshData()">
    </dx-button>
  </div>
</app-panel>
</form>