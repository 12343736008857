import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Menu } from "./menu.model";
import { Router } from "@angular/router";

@Injectable()
export class MenuService {
  constructor(private location: Location, private router: Router) { }

  public getVerticalMenuItems(): Array<Menu> {
    let mainMenu: Array<Menu> = [];
    if (JSON.parse(localStorage.getItem("userRights")).length > 0) {
      let menu = JSON.parse(localStorage.getItem("userRights"));
      if (menu) {
        this.createMenu(menu, mainMenu);
      }
    }
    return mainMenu;
  }

  public getHorizontalMenuItems(): Array<Menu> {
    let mainMenu: Array<Menu> = [];
    if (JSON.parse(localStorage.getItem("userRights")).length > 0) {
      let menu = JSON.parse(localStorage.getItem("userRights"));
      if (menu) {
        this.createMenu(menu, mainMenu);
      }
    }
    return mainMenu;
  }
  createMenu(menu, mainMenu: Array<Menu>) {
    menu.forEach(element => {
      mainMenu.push(new Menu(element.formId, element.title,element.parentId > 0 ? element.formUrl : "/", null, null, null, element.formUrl ? false : true, element.parentId));
      if (menu.items)
        this.createMenu(menu.items, mainMenu);
    });
  }
  publicVerifyMenu(routerLink: string) {
    const userMenu: any = JSON.parse(localStorage.getItem("userRights"));
    userMenu.forEach((item) => {
      if (item.canView && item.formUrl == routerLink) {
        return true;
      } else {
        return false;
      }
    });
  }
  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter((item) => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(
          (item) => item.id == menuItem.parentId
        )[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById("menu-item-" + menuId);
    let subMenu = document.getElementById("sub-menu-" + menuId);
    if (subMenu) {
      if (subMenu.classList.contains("show")) {
        subMenu.classList.remove("show");
        menuItem.classList.remove("expanded");
      } else {
        subMenu.classList.add("show");
        menuItem.classList.add("expanded");
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter((item) => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach((item) => {
        if (item.id != menuId) {
          let subMenu = document.getElementById("sub-menu-" + item.id);
          let menuItem = document.getElementById("menu-item-" + item.id);
          if (subMenu) {
            if (subMenu.classList.contains("show")) {
              subMenu.classList.remove("show");
              menuItem.classList.remove("expanded");
            }
          }
        }
      });
    }
  }
}
