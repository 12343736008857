<div class="filter-page-wrapper">
  <h1 mat-dialog-title>
    Simulation Record
    <div class="float-right cursor-pointer" mat-dialog-close>
      <i class="fa fa-close"></i>
    </div>
  </h1>

  <mat-dialog-content>
    <dx-data-grid
      id="glpost"
      #tempGrid
      [dataSource]="simulationList"
      [showBorders]="true"
      [hoverStateEnabled]="true"
      [wordWrapEnabled]="true"
      [allowColumnResizing]="true"
      [columnResizingMode]="'widget'"
    >
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>>
      <dxi-column
        dataField="accountNumber"
        caption="Acc. Code"
        cssClass="text-center"
        [width]="150"
      >
      </dxi-column>
      <dxi-column
        dataField="accountName"
        caption="Account Name"
        cssClass="text-left"
      >
      </dxi-column>
      <dxi-column
        dataField="description"
        caption="Remarks"
        cssClass="text-left"
      >
      </dxi-column>

      <dxi-column
        dataField="dbAmount"
        caption="DB.Amount"
        [width]="150"
        cssClass="text-center"
        [allowFiltering]="false"
        format="#0.00"
      >
      </dxi-column>
      <dxi-column
        dataField="crAmount"
        caption="CR.Amount"
        [width]="150"
        cssClass="text-center"
        [allowFiltering]="false"
        format="#0.00"
      >
      </dxi-column>
      <dxi-column
        dataField="dbAmountBase"
        caption="{{currency}}-DB Amount"
        [width]="150"
        cssClass="text-center"
        [allowFiltering]="false"
        format="#0.00"
      >
      </dxi-column>
      <dxi-column
        dataField="crAmountBase"
        caption="{{currency}}-CR Amount"
        [width]="150"
        cssClass="text-center"
        [allowFiltering]="false"
        format="#0.00"
      >
      </dxi-column>
      <dxo-summary [recalculateWhileEditing]="true">
        <dxi-total-item
          column="description"
          [customizeText]="customizeText"
          alignment="right"
        >
        </dxi-total-item>
        <dxi-total-item
          column="dbAmountBase"
          alignment="center"
          [customizeText]="customizeAmount"
          summaryType="sum"
        >
        </dxi-total-item>
        <dxi-total-item
          column="crAmountBase"
          alignment="center"
          [customizeText]="customizeAmount"
          summaryType="sum"
        >
        </dxi-total-item>
        <dxi-total-item
          column="dbAmount"
          alignment="center"
          [customizeText]="customizeAmount"
          summaryType="sum"
        >
        </dxi-total-item>
        <dxi-total-item
          column="crAmount"
          alignment="center"
          [customizeText]="customizeAmount"
          summaryType="sum"
        >
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </mat-dialog-content>
</div>
