import * as momentTimeZone from "moment-timezone";

import { Component, OnInit } from "@angular/core";
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

import { AppSettings } from "./app.settings";
import { AuthService } from "./auth/auth.service";
import { Settings } from "./app.settings.model";
import axios from "axios";
import { environment } from "./../environments/environment";
import DateBox from "devextreme/ui/date_box";
// axios.defaults.headers.common["CompanyId"] = localStorage.getItem("selectedCompanyBranch") ? JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId : 0;
// axios.defaults.headers.common["CompanyBranchId"] = localStorage.getItem("selectedCompanyBranch") ? JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId : 0;
// axios.defaults.headers.common["UserId"] = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).userId : 0;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public settings: Settings;
  loading: boolean;
  constructor(
    public appSettings: AppSettings,
    private router: Router,
    public auth: AuthService
  ) {
   
    let defaultDisplayFormat:any = DateBox;
    defaultDisplayFormat.defaultOptions({  
      options: {  
        displayFormat:'dd/MMM/yyyy'
      }  
    })  
    this.settings = this.appSettings.settings;
    this.settings.orderTracking = true;
  }

  ngOnInit() {
    this.setDefaultConfiguration();
    // this.configureRequestHeader();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.loading = true;
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.loading = false;
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        this.loading = false;
        // Present error to user
        console.log(event.error);
      }
      if (!this.auth.isAuthenticated()) {
        this.loading = false;
      }
    });
  }

  get isUserAdmin() {
    // http://localhost:4200/authentication/login
    if (
      this.router.url !== "authentication/login" &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).isAdministrator
    ) {
      return true;
    } else {
      return false;
    }
  }

  setDefaultConfiguration() {
    axios.defaults.baseURL = environment.baseUrl;
    axios.defaults.headers.post["Content-Type"] = "application/json-patch+json";
  }

  configureRequestHeader() {
    //let isRefreshing = false;
    if (localStorage.getItem("token")) {
      axios.interceptors.request.use((config) => {
        // var date = momentTimeZone(new Date())
        //   .tz("Asia/Riyadh")
        //   .format("MM-DD-YYYY HH:mm:ss") as any;
        // let tokenExpiaryInSec =
        //   parseInt(localStorage.getItem("tokenExpireIn")) -
        //   Math.floor((new Date(date) as any) / 1000);
        // if (
        //   tokenExpiaryInSec > 0 &&
        //   tokenExpiaryInSec <= 600 &&
        //   !isRefreshing
        // ) {
        //   isRefreshing = true;
        //   const payload = {
        //     userToken: localStorage.getItem("token"),
        //   };
        //   return axios.post("Account/RefreshToke", payload).then((response) => {
        //     if (response.status == 200) {
        //       localStorage.setItem("token", response.data.payload.userToken);
        //       let expiarytime = null;
        //       expiarytime = new Date(response.data.payload.expiresIn);
        //       let expiresIn = Math.floor(expiarytime / 1000);
        //       localStorage.setItem("tokenExpireIn", expiresIn.toString());
        //       isRefreshing = false;
        //       config.headers.common[
        //         "Authorization"
        //       ] = `Bearer ${localStorage.getItem("token")}`;
        //       return config;
        //     }
        //   });
        // }
        // Do something before request is sent
        config.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
        return config;
      });
    }
  }
}
