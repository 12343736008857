import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
@Injectable()
export class AuthGuardService  {
    constructor(public auth: AuthService, public router: Router) {}
    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/authentication/login']);
            return false;
        }
        return true;
    }
    // canActivate(
    //     route: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot
    //   ): Observable<boolean> | Promise<boolean> | boolean {
    //     const token = localStorage.getItem('token') || false;
    //     if (token) {
    //       return true;
    //     } else {
    //       this.router.navigate(['/authentication/login']);
    //     }
    //   }
}