import * as moment from 'moment-timezone';
import * as momentTimeZone from 'moment-timezone';

export class AppConstant {
  static getUserTimeZoneWithCompanyWise(timeZoneName: string) {
    const timeZoneMapping: { [key: string]: string } = {
      Riyadh: 'Asia/Riyadh',
      Dubai: 'Asia/Dubai',
      Karachi: 'Karachi'
    };

    const timeZone = timeZoneMapping[timeZoneName];
    return timeZone ? momentTimeZone(new Date()).tz(timeZone).format('MM-DD-YYYY HH:mm:ss') : momentTimeZone(new Date()).format('MM-DD-YYYY HH:mm:ss');
  }

  static getUserTimeZoneWithId(timeZoneId: number) {
    if(timeZoneId == 1 || timeZoneId == 2 || timeZoneId == 4) {
      return  momentTimeZone(new Date()).tz('Asia/Riyadh').format('MM-DD-YYYY HH:mm:ss');
    }
    if(timeZoneId == 3) {
      return  momentTimeZone(new Date()).tz('Asia/Dubai').format('MM-DD-YYYY HH:mm:ss');
    }
  }

  static getUserDateConvert(timeZoneId: number, date: string) {
    if(timeZoneId == 1 || timeZoneId == 2 || timeZoneId == 4) {
      return  momentTimeZone(date).tz('Asia/Riyadh').format('MM-DD-YYYY HH:mm:ss');
    }
    if(timeZoneId == 3) {
      return  momentTimeZone(date).tz('Asia/Dubai').format('MM-DD-YYYY HH:mm:ss');
    }
  }
  static getDate() {
    return momentTimeZone(new Date()).tz('Asia/Riyadh').format('MM-DD-YYYY HH:mm:ss');
  }
  static getUserId() {
    return JSON.parse(localStorage.getItem("user")).userId;
  }

  static getAccountId() {
    return JSON.parse(localStorage.getItem("user")).accountId;
  }

  static getOnlyDate(date: Date) : string {
   return moment(date).format('DD/MMM/yyyy');
  }

  static getDateGMT(): string {
    // Get current date and time
    var currentDate = new Date();

    // Get GMT offset in minutes (e.g., -300 for GMT+5:00)
    var gmtOffsetInMinutes = Math.abs(new Date().getTimezoneOffset());

    // Calculate the GMT offset in milliseconds
    var gmtOffsetInMilliseconds = gmtOffsetInMinutes * 60 * 1000;

    // Create a new Date object with the GMT offset
    var dateWithOffset = new Date(currentDate.getTime() + gmtOffsetInMilliseconds);
    return dateWithOffset.toJSON();
  }

  static getDateGMTInput(year: number, month:number, day:number): string {
    // Get current date and time
    var currentDate = new Date(year, month, day);

    // Get GMT offset in minutes (e.g., -300 for GMT+5:00)
    var gmtOffsetInMinutes = Math.abs(new Date().getTimezoneOffset());

    // Calculate the GMT offset in milliseconds
    var gmtOffsetInMilliseconds = gmtOffsetInMinutes * 60 * 1000;

    // Create a new Date object with the GMT offset
    var dateWithOffset = new Date(currentDate.getTime() + gmtOffsetInMilliseconds);
    return dateWithOffset.toJSON();
  }

  static getKSADateGMT(): string {
    // Import moment-timezone library

    // Get the user's time zone
    const userTimeZone = moment.tz.guess();

    // Check if the user's time zone is 'Asia/Riyadh'
    if (userTimeZone === 'Asia/Riyadh') {
        // Return the current date and time without any conversion
        return new Date().toISOString();
    } else {
        // Convert the current date and time to 'Asia/Riyadh' time zone
        // Get current date and time
        var currentDate = new Date();

        // Get GMT offset in minutes (e.g., -300 for GMT+5:00)
        var gmtOffsetInMinutes = Math.abs(new Date().getTimezoneOffset());

        // Calculate the GMT offset in milliseconds
        var gmtOffsetInMilliseconds = gmtOffsetInMinutes * 60 * 1000;

        // Create a new Date object with the GMT offset
        var dateWithOffset = new Date(currentDate.getTime() + gmtOffsetInMilliseconds);
        return dateWithOffset.toJSON();
    }
}

  static ksaDefaultDate() {
    const utcDate = new Date();
    const saudiTime = moment(utcDate).tz('Asia/Riyadh').format('YYYY-MM-DDTHH:mm:ss');
      return saudiTime;
  }
  static ksaSettDate(date: string) {
    const saudiTime = moment(date).tz('Asia/Riyadh').format('YYYY-MM-DDTHH:mm:ss');
      return saudiTime;
  }

  /**
   * Get the decimal count value from the user object stored in local storage.
   *
   * @return {number} The decimal count value.
   */
  static getUserDecimalCount() {
    if(localStorage.getItem("selectedCompanyBranch")) {
      if(JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId == 1 || JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId == 2) {
        return 2;
      } else 
      return 3;
    }
  }
  static getUserDecimalPlace() {
    if(localStorage.getItem("selectedCompanyBranch")) {
      return JSON.parse(localStorage.getItem("selectedCompanyBranch")).decimalPlace;
    }
  }
}

