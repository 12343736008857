<div class="form-grid-container mt-3">
  <div class="">
    <form [formGroup]="requestForm">
      <ng-container class="row mt-3" formArrayName="sections">
        <ng-container
          *ngFor="let section of sectionsArray.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <div class="row">
              <ng-container formArrayName="childern">
                <div
                   [ngClass]="item.get('name').value === 'CX' ? 'col-sm-12' : 'col-sm-6'"
                  [formGroupName]="j"
                  *ngFor="let item of getChildern(section); let j = index"
                >
                <div class="card" *ngIf="showInputs">
                  <div class="card-header">
                      {{ item.get("name").value }}
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                          <dx-number-box [inputAttr]="{ autocomplete: 'off' }" 
                          [step]="0.25"
                          placeholder="Min"
                            formControlName="min"
                      >
                      <dx-validator>
                          <dxi-validation-rule 
                              type="required"
                              message="Min required">
                          </dxi-validation-rule>
                          </dx-validator>
                    </dx-number-box>
                      </div>
                      <div class="col-sm-6">
                          <dx-number-box [inputAttr]="{ autocomplete: 'off' }" 
                          [step]="0.25"
                          placeholder="Max"
                            formControlName="max"
                      >
                      <dx-validator>
                          <dxi-validation-rule 
                              type="required"
                              message="Max required">
                          </dxi-validation-rule>
                          </dx-validator>
                    </dx-number-box>
                      </div>
                    </div>
                  </div>
                </div>
                  <!-- <fieldset>
                    <legend>
                      {{ item.get("name").value }}
                      <small class="text-muted">Range</small>
                    </legend>
                  
                    <div class="row p-20">
                        <div class="col-sm-6">
                            
                        <mat-form-field class="w-100">
                          <input
                            type="number"
                            matInput
                            placeholder="Min"
                            step="0.25"
                            formControlName="min"
                            required
                            autocomplete="off"
                          />
                          <mat-hint>min -15 </mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                        <mat-form-field class="w-100">
                          <input
                            type="number"
                            matInput
                            placeholder="Max"
                            step="0.25"
                            formControlName="max"
                            required
                            autocomplete="off"
                          />
                          <mat-hint>max 15</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </fieldset> -->
                  <div class="row clearfix">
                    <div class="col-sm-12 mt-3 mb-3">
                       <div class="float-right">
                        <div *ngIf="getCxTypeDetail(section).length > 0 && item.get('name').value === 'CX';then clearBtn;else generateBtn"></div>
                        <ng-template #clearBtn>
                            <dx-button 
                            id="add-row"
                            text="Add Rows"
                            type="normal"  
                            class="mr-2"
                            (click)="addRows()" 
                            >
                          </dx-button>
                            <!-- <button
                            type="button"
                            color="info"
                            class="p-2 mr-2"
                            mat-raised-button 
                            (click)="addRows()"
                    >
                      Add Rows
                    </button> -->
                    <dx-button text="Clear Range"  type="danger" class="float-right" (click)="clearField()"></dx-button>
                    <!-- <dx-button text="Clear Range" *ngIf="!isEdit" type="danger" class="float-right" (click)="clearField()"></dx-button> -->
                        </ng-template>
                        <ng-template #generateBtn>
                            <dx-button 
                            id="button"
                            text="Generate Range"
                            *ngIf="(item.get('min').value  && item.get('max').value) && item.get('name').value === 'CX' || (item.get('min').value === 0 && item.get('max').value) "
                            type="default"  
                            class="float-right ml-3"
                            (click)="generateGrid(item.get('min').value, item.get('max').value)" 
                            >
                          </dx-button>
                          <!-- <button
                                  color="primary" type="button"
                                  *ngIf="(item.get('min').value  && item.get('max').value) && item.get('name').value === 'CX' || (item.get('min').value === 0 && item.get('max').value) "
                                  class="p-2"
                                  mat-raised-button
                                  (click)="generateGrid(item.get('min').value, item.get('max').value)"
                          >
                            Generate Range
                          </button> -->

                        </ng-template>
                      </div> 
                    </div>
                  </div>
                </div>

              </ng-container>
            </div>

          

        
      <!-- <span class="total-count">Total: {{total}}</span> -->
      <div class="clearfix"></div>
      <div class="row mt-2" *ngIf="getCxTypeDetail(section).length > 0">
        <div class="col">
          <div class="card animated fadeIn">
            <div class="card-body pb-0">
                <div class="table-responsive-sm" >
                  	<ng-container formArrayName="cxTypeDetail">
                    <table class="table table-striped use-equal-cell-width text-center table-with-over">
                      <colgroup>
                        <col />
                        <col />
                        <col width="200px" />
                        <col width="200px" *ngIf="showItemName" />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col *ngIf="showWt" />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                            <th>S#.</th>
                          <th *ngFor="let item of cxHeader">{{item.name}}</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr [formGroupName]="k" *ngFor="let item of getCxTypeDetail(section); let k = index">
                              <td>{{k + 1}}</td>
                              <td>
                                <select class="form-control-sm w-100" formControlName="cxtypeId">
                                  <option value="">Select Cx Type</option>
                                  <option *ngFor="let cx of cxList" [value]="cx.cxtypeId">
                                    {{cx.cxtypeName}}
                                </option>
                                </select>
                            </td>
                            <td class="position-relative"><input type="text" (focusout)="getCatalogByBarcode(item.get('vendorItemCode').value, k)" min="0" SignNotAllowed="-" formControlName="vendorItemCode" class="form-control form-control-sm hvr-glow animated fadeInRight" autocomplete="off">
                              <i *ngIf="item.get('loading').value" class="fa fa-spinner fa-spin" style="position: absolute;right: 7px;top:12px;font-size:14px;"></i></td>
                            <td *ngIf="showItemName" ><input type="text" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off" [value]="item.get('itemName').value" readonly="readonly"></td>
                            <td><input type="text" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off" [value]="item.get('sideName').value" readonly="readonly"></td>
                            <td>
                              <input
                                tabindex="{{ k + 1 }}"
                                SignNotAllowed="{{ inputType ? '' : '101' }}"
                                type="text"
                                NumberValidate
                                formControlName="qty"
                                [readonly]="!item.get('vendorItemCode').value"
                                SignNotAllowed="-"
                                min="0"
                                class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                autocomplete="off"
                              />
                            </td>
                            <td><input type="number"  formControlName="size" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off" readonly="readonly"></td>
                            <td><input type="number"  formControlName="currentStock" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off" readonly="readonly"></td>
                            <td><input type="number"  formControlName="cx" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off"></td>
                            <td><input type="number" min="0" SignNotAllowed="-"  formControlName="tc" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off"></td>
                            <td><input type="number" min="0" SignNotAllowed="-"  formControlName="cc" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off"></td>
                            <td><input type="number" min="0" SignNotAllowed="-"  formControlName="ct" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off"></td>
                            <td><input type="number" min="0" SignNotAllowed="-"  formControlName="et" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off"></td>
                           
                            <td><input type="number" min="0" SignNotAllowed="-" formControlName="add" class="form-control form-control-sm hvr-glow animated fadeInRight text-center" autocomplete="off"></td>
                            <!-- <td><input type="text" formControlName="axis" class="form-control form-control-sm hvr-glow animated fadeInRight" autocomplete="off"></td>
                            <td><input type="text" formControlName="cyl" class="form-control form-control-sm hvr-glow animated fadeInRight" autocomplete="off"></td> -->
                            <td *ngIf="showWt"><input type="number" min="0" SignNotAllowed="-"  formControlName="wt" class="form-control form-control-sm hvr-glow animated text-center fadeInRight" autocomplete="off"></td>
                            <td>
                              <!-- <i class="fa fa-commenting" id="comment-{{k}}"></i> -->
                              <input type="text" id="comment-{{k}}" (input)="showRemarks(section,k)" (focus)="showRemarks(section,k)"   formControlName="remarks" class="form-control form-control-sm hvr-glow animated fadeInRight" autocomplete="off">
                            </td>
                            <td><a href="javascript:void(0)" class="cursor-pointer warn"><mat-icon  (click)="removeIndex(k)">delete_forever</mat-icon></a></td>
                          </tr>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
            </div>
          </div>
        </div>
      </div>
      <dx-popover position="top" [width]="150" [showTitle]="true" title="Remarks Preview"
      [(visible)]="actionSheetVisible" [target]="popupTarget" [hideOnOutsideClick]="true">
      <div *dxTemplate="let data = model of 'content'">
        {{userRemarks}}
      </div>
    </dx-popover>
      <div class="row mt-2">
       
          <div class="col  animated fadeInUp" *ngIf="validateSubmitBtn">
            <!--<div class="float-left mt-2" *ngIf="isEdit">
                Active <dx-check-box formControlName="isActive"></dx-check-box>
              </div>-->
              <dx-button 
                id="button"
                [text]="isEdit ? 'Update':  'Save'"
                type="default"  
                class="float-right ml-3"
                
                (click)="!requestForm.valid || saveStockRequest(requestForm)"
                [useSubmitBehavior]="true">
            </dx-button>
            <dx-button text="Cancel" type="normal" class="float-right" appBackButton>
            </dx-button>
            <!-- <button
              class="btn float-right btn-primary hvr-glow"
              type="submit" *ngIf="getCxTypeDetail(section).length > 0 ||  section.get('condition').value == 1 || section.get('condition').value == 2"  
            >
              Save</button
            ><button
              class="btn float-right mr-3 btn-secondary hvr-glow"
              type="button"
              appBackButton
            >
              Cancel
            </button> -->
          </div>
      </div>
    </ng-container>
    </ng-container>

  </ng-container>
    </form>
  </div>
</div>
