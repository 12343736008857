import { environment } from 'src/environments/environment';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'report-viewer-dialog',
    styleUrls: ['./report-viewer-dialog.component.scss'],
    templateUrl: './report-viewer-dialog.component.html',
  })
  export class ReportViewerDialogComponent implements OnInit {
    loadingIndicator: boolean;
    title = 'DXReportViewerSample';
    reportUrl = '';
    hostUrl: string = environment.reportBaseUrl;
    invokeAction: string = environment.reportActionUrl;
    constructor(
      public dialogRef: MatDialogRef<ReportViewerDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data) {
          this.loadingIndicator = false;
          this.reportUrl = data.reportUrl;
        }

  ngOnInit() {
  }
   
  }
