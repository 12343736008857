import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { SharedService } from "../../shared.service";
import notify from "devextreme/ui/notify";
import { Papa } from "ngx-papaparse";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
@Component({
  selector: "app-dx-cx-grid",
  templateUrl: "./dx-cx-grid.component.html",
  styleUrls: ["./dx-cx-grid.component.css"],
})
export class DxCXGridComponent implements OnInit {
  @Input() dataSource: any;
  @Input() focalId: 1;
  pivotListRight: any[];
  pivotListLeft: any[];
  pivotListBoth: any[];
  loading: boolean;
  data: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('csvfileInput') csvfileInput: ElementRef;
  @Output() isSuccess: EventEmitter<any> = new EventEmitter();
  constructor(private sharedService: SharedService, private papa: Papa) {}

  ngOnInit(): void {
    this.pivotListRight = [];
    this.pivotListLeft = [];
    this.pivotListBoth = [];
  }
  /**
   * @name ngOnChanges
   * @desc Fires when change in input properties are detected
   * @return void
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource && Object.keys(changes.dataSource.currentValue).length > 0) {
      this.data = changes.dataSource.currentValue;
      this.getPivotForCxTable(
        changes.dataSource.currentValue.cxMin,
        changes.dataSource.currentValue.cxMax,
        changes.dataSource.currentValue.cylMin,
        changes.dataSource.currentValue.cylMax,
        changes.dataSource.currentValue.addMin,
        changes.dataSource.currentValue.addMax,
        changes.dataSource.currentValue.focalId,
        changes.dataSource.currentValue.itemTypeMasterId
      );
    }
  }
  
   /**
   * @name customizeColumns
   * @param columns array
   * @returns void
   * @description columns settings
   */
   customizeColumns(columns) {
    if (columns.length > 0) {
      columns.forEach((element) => {
        element.allowFiltering = false;
        if (element.dataField == "CX") {
          columns[0].allowEditing = false;
          element.allowFiltering = true;
        } else if (element.dataField == "ADDF") {
          columns[0].allowEditing = false;
        } else {
          element.caption = element.dataField.replace(" ", ".");
        }
        if(element.dataField == 'lensSideId' || element.dataField == 'purchaseRequisitionDetailId') {
          element.visible = false;      
        }
      });
    }
  }

  /**
   * @name getPivotForCxTable
   * @param {sphMin} string
   * @param {sphMax} string
   * @param {cylMin} string
   * @param {cylMax} string
   * @param {AddfMin} string
   * @param {AddfMax} string
   * @returns Rage list
   * @memberof salesService
   */
  getPivotForCxTable(
    cxMin: any,
    cxMax: any,
    cylMin: any,
    cylMax: any,
    addMin: any,
    addMax: any,
    focalId: any,
    itemTypeMasterId: any
  ) {
    this.loading = true;
    this.sharedService
      .getPivotForCxTable(cxMin, cxMax, cylMin, cylMax, addMin, addMax, focalId, itemTypeMasterId)
      .then((response: any) => {
        if (
          response.data.completed &&
          response.data.payload &&
          response.data.payload.length > 0
        ) {
          this.loading = false;
          this.pivotListBoth = response.data.payload;
          var right = JSON.stringify(response.data.payload);
          var left = JSON.stringify(response.data.payload);
          this.pivotListLeft = JSON.parse(left);
          this.pivotListRight = JSON.parse(right);

          if(this.data && this.data.both && this.data.both.length > 0) {
            this.data.both.forEach(element => {
              let cx;
              let strCylAddf = this.data.focalId == 1 && this.data.itemTypeMasterId == 2 ? element.strCx.replace('+','') :  element.strAddf.replace('+','');
              strCylAddf = strCylAddf.replace('.',' ');
              let strCx = element.strCx.replace('+','');
              this.pivotListBoth.forEach((item: any) => {
                for (const [key, value] of Object.entries(item)) {
                  if(key === 'CX'){
                    cx = value;
                  }
                  
                 if(strCx == cx && strCylAddf == key){
                      item[key] = element.qty;
                      item["purchaseRequisitionDetailId"] = element.purchaseRequisitionDetailId;
                      item['lensSideId'] = element.lensSideId
                 }
                }
              });
              cx = '';
            });
           
          } 
          

          if(this.data && this.data.right && this.data.right.length > 0) {
            this.data.right.forEach(element => {
              let cx;
              let strCylAddf = this.data.focalId == 1 && this.data.itemTypeMasterId == 2 ? element.strCx.replace('+','') :  element.strAddf.replace('+','');
              strCylAddf = strCylAddf.replace('.',' ');
              let strCx = element.strCx.replace('+','');
              this.pivotListRight.forEach((item: any) => {
                for (const [key, value] of Object.entries(item)) {
                  if(key === 'CX'){
                    cx = value;
                  }
                  
                 if(strCx == cx && strCylAddf == key){
                      item[key] = element.qty;
                      item["purchaseRequisitionDetailId"] = element.purchaseRequisitionDetailId;
                      item['lensSideId'] = element.lensSideId
                 }
                }
              });
              cx = '';
            });
           
          } 
          if(this.data && this.data.left && this.data.left.length > 0) {
            this.data.left.forEach(element => {
              let cx;
              let strCylAddf = this.data.focalId == 1 && this.data.itemTypeMasterId == 2 ? element.strCx.replace('+','') :  element.strAddf.replace('+','');
              strCylAddf = strCylAddf.replace('.',' ');
              let strCx = element.strCx.replace('+','');
              this.pivotListLeft.forEach((item: any) => {
                for (const [key, value] of Object.entries(item)) {
                  if(key === 'CX'){
                    cx = value;
                  }
                  
                 if(strCx == cx && strCylAddf == key){
                      item[key] = element.qty;
                      item["purchaseRequisitionDetailId"] = element.purchaseRequisitionDetailId;
                      item['lensSideId'] = element.lensSideId
                 }
                }
              });
              cx = '';
            });
           
          } 
          this.isSuccess.emit(true);
        } else {
          this.isSuccess.emit(false);
          this.loading = false;
          this.pivotListBoth = [];
          const option = {
            width: 300,
            displayTime: 8000,
            message: response.data.message,
            type: "warning",
          };
          notify(option);
        }
      })
      .catch((error) => {
        this.isSuccess.emit(false);
        this.loading = false;
        const option = {
          width: 300,
          displayTime: 8000,
          message: error.message,
          type: "error",
        };
        notify(option);
      });
  }

  /**
 * @name setColumnDataType
 * @reutrn void
 */
  setColumnDataType(e) {
    if(e && e.column) {
      e.column.dataType = 'number';
    }
  }

/**
 * @name readCSV
 * @param event 
 * @param listName 
 */
  readCSV(event, listName: string) {
    let files: FileList = event.target.files;
    let ParentArray: any = [];
    let tempData: any = [];
    if (files && files.length > 0) {
        let file: File = files.item(0);
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            let csvData: string = reader.result as string;
            this.papa.parse(csvData, {
                complete: (result) => {
                  let filteredData = result.data.slice(1).map((row: any) => row.slice(1));
                  filteredData.map((parentArray, parentArrayIndex) => {
                      ParentArray = parentArray;
                        parentArray.map((v, childArrayIndex) => {
                          console.log('parentArrayIndex', parentArrayIndex + ' ' + 'childArrayIndex' + ' ' + childArrayIndex + ' ' + v)
                          if(v) {
                            tempData.push(v)
                          }
                        })
                        if(listName == 'pivotListBoth') {
                          this.pivotListBoth.forEach((item: any, index: any) => {
                            for (const [key, value] of Object.entries(item)) {
                              if(key !== 'CX') {
                                if(tempData.length == 0)
                                break;
                                if(parentArrayIndex == index) {
                                  item[key] = tempData.shift();
                                }
                              }
                            }
                          });
                        }
                        if(listName == 'pivotListRight') {
                          this.pivotListRight.forEach((item: any, index: any) => {
                            for (const [key, value] of Object.entries(item)) {
                              if(key !== 'CX') {
                                if(tempData.length == 0)
                                break;
                                if(parentArrayIndex == index) {
                                  item[key] = tempData.shift();
                                }
                              }
                            }
                          });
                        }
                        if(listName == 'pivotListLeft') {
                          this.pivotListLeft.forEach((item: any, index: any) => {
                            for (const [key, value] of Object.entries(item)) {
                              if(key !== 'CX') {
                                if(tempData.length == 0)
                                break;
                                if(parentArrayIndex == index) {
                                  item[key] = tempData.shift();
                                }
                              }
                            }
                          });
                        }
                       
                    });
                    console.log(tempData)
                 
                }
              
            });
            
        }
        this.csvfileInput.nativeElement.value = "";
    } 
}
  /**
   * @name savebothSidesForm
   * @returns payload list
   */
  savebothSidesForm() {
    this.optSelected.emit({
      pivotListRight: this.pivotListRight,
      pivotListLeft: this.pivotListLeft,
      pivotListBoth: this.pivotListBoth,
    });
  }

  /**
   * Executes when exporting.
   *
   * @param {Object} e - The event object.
   */
  onExporting(e) {
    e.component.beginUpdate();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('both');

    exportDataGrid({
        component: e.component,
        worksheet: worksheet
    }).then(function() {
        workbook.xlsx.writeBuffer().then(function(buffer: BlobPart) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'both.xlsx');
        });
    }).then(function() {
        e.component.endUpdate();
    });
}
}
