<div class="form-grid-container mt-3">
  <div>
    <form [formGroup]="requestForm">
      <ng-container class="row mt-3" formArrayName="sections">
        <ng-container
          *ngFor="let section of sectionsArray.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <div class="row">
              <ng-container formArrayName="childern">
                <div
                  [ngClass]="
                    item.get('name').value === 'CX' ? 'col-sm-12' : 'col-sm-6'
                  "
                  [formGroupName]="j"
                  *ngFor="let item of getChildern(section); let j = index"
                >
                  <div class="card" *ngIf="showInputs">
                    <div class="card-header">
                      {{ item.get("name").value }}
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-6">
                          <dx-number-box
                            [step]="0.25"
                            placeholder="Min"
                            formControlName="min"
                          >
                            <dx-validator>
                              <dxi-validation-rule
                                type="required"
                                message="Min required"
                              ></dxi-validation-rule>
                            </dx-validator>
                          </dx-number-box>
                        </div>
                        <div class="col-sm-6">
                          <dx-number-box
                            [step]="0.25"
                            placeholder="Max"
                            formControlName="max"
                          >
                            <dx-validator>
                              <dxi-validation-rule
                                type="required"
                                message="Max required"
                              ></dxi-validation-rule>
                            </dx-validator>
                          </dx-number-box>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-sm-12 mt-3 mb-3">
                      <div class="float-right">
                        
                        <dx-button
                          text="Generate Range"
                          *ngIf="
                            item.get('min').value &&
                            item.get('max').value &&
                            item.get('name').value === 'CX'
                          "
                          type="default"
                          class="float-right ml-3"
                          (click)="
                            generateGrid(
                              item.get('min').value,
                              item.get('max').value
                            )
                          "
                        ></dx-button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- ✅ Updated DevExtreme DataGrid -->
            <div class="row mt-2" *ngIf="getCxTypeDetail(section).length > 0">
              <div class="col">
                <div class="card animated fadeIn">
                  <div class="card-body pb-0">
                    <dx-data-grid
                      [dataSource]="section.get('cxTypeDetail').value"
                      [showBorders]="true"
                      [columnAutoWidth]="true"
                      [allowColumnResizing]="true"
                      [allowColumnReordering]="true"
                      [editing]="{
                        mode: 'cell',
                        allowUpdating: true,
                        allowDeleting: true
                      }"
                      (onRowUpdated)="updateFormArray($event, section)"
                      (onRowRemoving)="removeIndex($event.data, section)"
                      (onCellPrepared)="onCellPrepared($event)"
                      [filterRow]="{ visible: true }"
                      [headerFilter]="{ visible: true }"
                      [searchPanel]="{ visible: true, width: 300, placeholder: 'Search...' }"
                    >
                    <dxi-column caption="Sr#" [width]="50" [allowEditing]="false"></dxi-column>

                      <dxi-column
                        dataField="cxtypeId"
                        caption="Cx Type"
                        [lookup]="{
                          dataSource: cxList,
                          valueExpr: 'cxtypeId',
                          displayExpr: 'cxtypeName'
                        }"
                      >
                      </dxi-column>
                      <dxi-column
                        dataField="vendorName"
                        caption="Vendor Name"
                        [allowEditing]="true"
                      ></dxi-column>
                      <dxi-column
                        dataField="vendorItemCode"
                        caption="Vendor Item Code"
                        [allowEditing]="true"
                      ></dxi-column>
                      <dxi-column
                        *ngIf="showItemName"
                        dataField="itemName"
                        caption="Item Name"
                        [allowEditing]="false"
                      ></dxi-column>
                      <dxi-column
                        dataField="sideName"
                        caption="Side"
                        [allowEditing]="false"
                      ></dxi-column>
                      <dxi-column
                        dataField="qty"
                        caption="QTY"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="size"
                        caption="Size"
                        dataType="number"
                        [allowEditing]="false"
                      ></dxi-column>
                      <dxi-column
                        dataField="currentStock"
                        caption="Current Stock"
                        dataType="number"
                        [allowEditing]="false"
                      ></dxi-column>
                      <dxi-column
                        dataField="cx"
                        caption="CX"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="tc"
                        caption="TC"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="cc"
                        caption="CC"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="ct"
                        caption="CT"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="et"
                        caption="ET"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="add"
                        caption="ADD"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        *ngIf="showWt"
                        dataField="wt"
                        caption="WT"
                        dataType="number"
                      ></dxi-column>
                      <dxi-column
                        dataField="remarks"
                        caption="Remarks"
                      ></dxi-column>
                      <dxi-column type="buttons">
                        <dxi-button name="delete"></dxi-button>
                      </dxi-column>
                    </dx-data-grid>
                  </div>
                </div>
              </div>
            </div>

            <!-- Save & Cancel Buttons -->
            <div class="row mt-2">
              <div class="col animated fadeInUp" *ngIf="validateSubmitBtn">
                <dx-button
                  text="{{ isEdit ? 'Update' : 'Save' }}"
                  type="default"
                  class="float-right ml-3"
                  (click)="saveStockRequest(requestForm)"
                ></dx-button>
                <dx-button
                  text="Cancel"
                  type="normal"
                  class="float-right"
                  appBackButton
                ></dx-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>
