
<form [formGroup]="addressHolderForm">
<div class="p-2">
<div class="row">
  <!-- NAME COLUMN -->
  <div class="col-md-6">
      <h5>Address Holder Details</h5>
      <div class="dx-field">
        <kendo-formfield>
          <kendo-label
            text="Name *"
          ></kendo-label>
          <kendo-textbox
            class="w-100"
            [tabIndex]="1"
            #nameEng
            [readonly]="true"
            placeholder="Name"
            formControlName="nameEng"
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
    
  </div>
  <!--/ NAME COLUMN -->
  <!-- ARBIC NAME COLUMN -->
  <div class="col-md-6 text-right arbic">
      <h5>تفاصيل صاحب العنوان</h5>
      <div class="dx-field">
        <kendo-formfield>
          <kendo-label
            text="اسم *"
          ></kendo-label>
          <kendo-textbox
            class="w-100"
            [readonly]="true"
            [tabIndex]="2"
            #nameArb
            placeholder="اسم"
            formControlName="nameArb"
          >
          </kendo-textbox>
          <!-- <kendo-formerror>مطلوب اسم</kendo-formerror> -->
        </kendo-formfield>
      </div>
  </div>
  <!--/ ARBIC NAMECOLUMN -->
  <div class="col-md-12 mt-3 d-flex justify-content-center align-items-center">
    <div class="mr-2">ID No.</div>
    <kendo-numerictextbox #idNo1 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo1" [tabIndex]="3"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo2 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo2" [tabIndex]="4"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo3 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo3" [tabIndex]="5"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo4 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo4" [tabIndex]="6"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo5 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo5" [tabIndex]="7"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo6 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo6" [tabIndex]="8"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo7 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo7" [tabIndex]="9"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo8 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo8" [tabIndex]="10"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo9 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo9" [tabIndex]="11"></kendo-numerictextbox>
    <kendo-numerictextbox #idNo10 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" [readonly]="true" formControlName="idNo10" [tabIndex]="12"></kendo-numerictextbox>
    <div class="ml-2 f-17">رقم بطاقة الهوية</div>
  </div>

  <div class="col-md-12 mt-3 d-flex justify-content-center align-items-center">
    <div class="mr-2" style="width: 100px;">Reg Date.</div>
    <kendo-dateinput class="w-100 kendo-input-center"  [readonly]="true" formControlName="regDate" [tabIndex]="13"></kendo-dateinput>
    <div class="ml-2 f-17" style="width: 100px;">تاريخ التسجيل</div>
  </div>
  <div style="background:#837c7c;height:2px;width: 96%; margin: 15px auto;"></div>
</div>
</div>

<div class="p-2">
  <div class="row">
    <div class="col-md-6">
        <h5>Address Details</h5>
    </div>
    <div class="col-md-6 text-right arbic">
        <h5 class="f-17">تفاصيل العنوان</h5>
    </div>
    <div class="col-md-12 mt-3 d-flex justify-content-center align-items-center">
      <div class="mr-2">Short Address</div>
      <kendo-numerictextbox #shortAdd1 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd1" required [tabIndex]="14" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd2 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd2" required [tabIndex]="15" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd3 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd3" required [tabIndex]="16" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd4 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd4" required [tabIndex]="17" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd5 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd5" required [tabIndex]="18" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd6 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd6" required [tabIndex]="19" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd7 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd7" required [tabIndex]="20" ></kendo-numerictextbox>
      <kendo-numerictextbox #shortAdd8 [format]="'n0'" class="w-50-per kendo-input-center" [spinners]="false" formControlName="shortAdd8" required [tabIndex]="21" ></kendo-numerictextbox>
      <div class="ml-2 f-17">عنوان قصير</div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
            <label>Building No. / Street</label>
            <div class="d-flex">
              <kendo-numerictextbox #buildingNo1Eng [format]="'n0'" (valueChange)="setValue($event, 'buildingNo1Arb')" class="w-50-per kendo-input-center" [spinners]="false" formControlName="buildingNo1Eng" required [tabIndex]="22" ></kendo-numerictextbox>
              <kendo-numerictextbox #buildingNo2Eng [format]="'n0'" (valueChange)="setValue($event, 'buildingNo2Arb')" class="w-50-per kendo-input-center" [spinners]="false" formControlName="buildingNo2Eng" required [tabIndex]="23" ></kendo-numerictextbox>
              <kendo-numerictextbox #buildingNo3Eng [format]="'n0'" (valueChange)="setValue($event, 'buildingNo3Arb')" class="w-50-per kendo-input-center" [spinners]="false" formControlName="buildingNo3Eng" required [tabIndex]="24" ></kendo-numerictextbox>
              <kendo-numerictextbox #buildingNo4Eng [format]="'n0'" (valueChange)="setValue($event, 'buildingNo4Arb')" class="w-50-per kendo-input-center" [spinners]="false" formControlName="buildingNo4Eng" required [tabIndex]="25" ></kendo-numerictextbox>
              <kendo-formfield class="w-100">
                <kendo-textbox
                  class="w-100"
                  [tabIndex]="26"
                  required
                  #streetEng
                  placeholder="Street"
                  formControlName="streetEng"
                >
                </kendo-textbox>
                <!-- <kendo-formerror>Street is required</kendo-formerror> -->
              </kendo-formfield>
            </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 text-right arbic">
            <label class="f-17">رقم المبنى / شارع</label>
            <div class="d-flex">
              <kendo-numerictextbox #buildingNo1Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'buildingNo1Eng')" [spinners]="false" formControlName="buildingNo1Arb" required [tabIndex]="27" ></kendo-numerictextbox>
              <kendo-numerictextbox #buildingNo2Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'buildingNo2Eng')" [spinners]="false" formControlName="buildingNo2Arb" required [tabIndex]="28" ></kendo-numerictextbox>
              <kendo-numerictextbox #buildingNo3Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'buildingNo3Eng')" [spinners]="false" formControlName="buildingNo3Arb" required [tabIndex]="29" ></kendo-numerictextbox>
              <kendo-numerictextbox #buildingNo4Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'buildingNo4Eng')" [spinners]="false" formControlName="buildingNo4Arb" required [tabIndex]="30" ></kendo-numerictextbox>
             
              <kendo-formfield class="w-100">
                <kendo-textbox
                  class="w-100"
                  [tabIndex]="31"
                  required
                  #streetArb
                  placeholder="شارع"
                  formControlName="streetArb"
                >
                </kendo-textbox>
              </kendo-formfield>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
            <label>Secondary No. / District</label>
            <div class="d-flex">
              <kendo-numerictextbox #sceondaryNo1Eng [format]="'n0'" (valueChange)="setValue($event, 'sceondaryNo1Arb')" class="w-50-per" [spinners]="false" formControlName="sceondaryNo1Eng" required [tabIndex]="32" ></kendo-numerictextbox>
              <kendo-numerictextbox #sceondaryNo2Eng [format]="'n0'" (valueChange)="setValue($event, 'sceondaryNo2Arb')" class="w-50-per" [spinners]="false" formControlName="sceondaryNo2Eng" required [tabIndex]="33" ></kendo-numerictextbox>
              <kendo-numerictextbox #sceondaryNo3Eng [format]="'n0'" (valueChange)="setValue($event, 'sceondaryNo3Arb')" class="w-50-per" [spinners]="false" formControlName="sceondaryNo3Eng" required [tabIndex]="34" ></kendo-numerictextbox>
              <kendo-numerictextbox #sceondaryNo4Eng [format]="'n0'" (valueChange)="setValue($event, 'sceondaryNo4Arb')" class="w-50-per" [spinners]="false" formControlName="sceondaryNo4Eng" required [tabIndex]="35" ></kendo-numerictextbox>
              
             
              <!-- <kendo-formfield class="w-100"> -->
                <kendo-combobox
                [virtual]="true"
                [clearButton]="false"
                class="w-100"
                formControlName="districtEng"
                [valuePrimitive]="true"
                [data]="districList"
                [filterable]="true"
                [kendoDropDownFilter]="{ operator: 'contains' }"
                [valueField]="'districtId'"
                [textField]="'districtName'"
                [suggest]="true"
                required
                [placeholder]="'District'"
                [tabIndex]="36"
              >
              </kendo-combobox>
              <!-- </kendo-formfield> -->
            </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 text-right arbic">
            <label class="f-17">الرقم الثانوي / يصرف</label>
            <div class="d-flex">
              <kendo-numerictextbox #sceondaryNo1Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'sceondaryNo1Eng')" [spinners]="false" formControlName="sceondaryNo1Arb" required [tabIndex]="37" ></kendo-numerictextbox>
              <kendo-numerictextbox #sceondaryNo2Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'sceondaryNo2Eng')" [spinners]="false" formControlName="sceondaryNo2Arb" required [tabIndex]="38" ></kendo-numerictextbox>
              <kendo-numerictextbox #sceondaryNo3Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'sceondaryNo3Eng')" [spinners]="false" formControlName="sceondaryNo3Arb" required [tabIndex]="39" ></kendo-numerictextbox>
              <kendo-numerictextbox #sceondaryNo4Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'sceondaryNo4Eng')" [spinners]="false" formControlName="sceondaryNo4Arb" required [tabIndex]="40" ></kendo-numerictextbox>
              <kendo-formfield class="w-100">
                <kendo-textbox
                  class="w-100"
                  [tabIndex]="41"
                  required
                  placeholder="يصرف"
                  #districtArb
                  formControlName="districtArb"
                >
                </kendo-textbox>
              </kendo-formfield>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
            <label>Postal Code</label>
            <div class="d-flex">
              <kendo-numerictextbox #postalCodeNo1Eng [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo1Arb')" [spinners]="false" formControlName="postalCodeNo1Eng" required [tabIndex]="42" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo2Eng [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo2Arb')" [spinners]="false" formControlName="postalCodeNo2Eng" required [tabIndex]="43" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo3Eng [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo3Arb')" [spinners]="false" formControlName="postalCodeNo3Eng" required [tabIndex]="44" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo4Eng [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo4Arb')" [spinners]="false" formControlName="postalCodeNo4Eng" required [tabIndex]="45" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo5Eng [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo5Arb')" [spinners]="false" formControlName="postalCodeNo5Eng" required [tabIndex]="46" ></kendo-numerictextbox>
            </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 text-right">
            <label class="f-17">رمز بريدي</label>
            <div class="d-flex justify-content-end arbic">
              <kendo-numerictextbox #postalCodeNo1Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo1Eng')" [spinners]="false" formControlName="postalCodeNo1Arb" required [tabIndex]="47" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo2Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo2Eng')" [spinners]="false" formControlName="postalCodeNo2Arb" required [tabIndex]="48" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo3Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo3Eng')" [spinners]="false" formControlName="postalCodeNo3Arb" required [tabIndex]="49" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo4Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo4Eng')" [spinners]="false" formControlName="postalCodeNo4Arb" required [tabIndex]="50" ></kendo-numerictextbox>
              <kendo-numerictextbox #postalCodeNo5Arb [format]="'n0'" class="w-50-per" (valueChange)="setValue($event, 'postalCodeNo5Eng')" [spinners]="false" formControlName="postalCodeNo5Arb" required [tabIndex]="51" ></kendo-numerictextbox>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="p-2">
    <div class="row">
      <!-- NAME COLUMN -->
      <div class="col-md-6">
          <div class="dx-field">
            <!-- <kendo-formfield> -->
              <!-- <kendo-label
              text="City *"  [for]="cityId"
            ></kendo-label> -->
              <kendo-combobox
                [virtual]="true"
                [clearButton]="false"
                class="w-100"
                #cityId
                formControlName="cityEng"
                [valuePrimitive]="true"
                [data]="cityList"
                [filterable]="true"
                [kendoDropDownFilter]="{ operator: 'contains' }"
                [valueField]="'cityId'"
                [textField]="'cityName'"
                [suggest]="true"
                required
                [placeholder]="'City *'"
                [tabIndex]="52"
              >
              </kendo-combobox>
            <!-- </kendo-formfield> -->
          </div>
        
      </div>
      <!--/ NAME COLUMN -->
      <!-- ARBIC NAME COLUMN -->
      <div class="col-md-6 text-right arbic">
          <div class="dx-field">
            <kendo-formfield class="w-100">
              <kendo-textbox
                class="w-100"
                [tabIndex]="53"
                required
                placeholder="مدينة *"
                #cityArb
                formControlName="cityArb"
              >
              </kendo-textbox>
            </kendo-formfield>
          </div>
      </div>
      <!--/ ARBIC NAMECOLUMN -->
    </div>
    <div class="row">
      <!-- NAME COLUMN -->
      <div class="col-md-6">
          <div class="dx-field">
            <kendo-combobox
                [virtual]="true"
                [clearButton]="false"
                class="w-100"
                #cityId
                formControlName="countryEng"
                [valuePrimitive]="true"
                [data]="countryList"
                [filterable]="true"
                [kendoDropDownFilter]="{ operator: 'contains' }"
                [valueField]="'countryId'"
                [textField]="'countryName'"
                [suggest]="true"
                required
                [placeholder]="'Country * '"
                [tabIndex]="54"
              >
              </kendo-combobox>
          </div>
        
      </div>
      <!--/ NAME COLUMN -->
      <!-- ARBIC NAME COLUMN -->
      <div class="col-md-6 text-right arbic">
          <div class="dx-field">
            <kendo-formfield class="w-100">
              <kendo-textbox
                class="w-100"
                [tabIndex]="55"
                required
                #countryArb
                placeholder="دولة *"
                formControlName="countryArb"
              >
              </kendo-textbox>
            </kendo-formfield>
          </div>
      </div>
      <!--/ ARBIC NAMECOLUMN -->
    </div>
    </div>

  </form>

