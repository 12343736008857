import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { SharedService } from "./../template/shared/shared.service";
import notify from 'devextreme/ui/notify';
import axios from "axios";

@Injectable()
export class AccessibilityGuard  {
  constructor(
    private router: Router,
    private sharedService: SharedService,
    public auth: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(["/authentication/login"]);
      axios.interceptors.request.use(
        (config) => {
          config.headers.clear();
          return config;
        },
        (error) => {
          // Do something with request error
          return Promise.reject(error);
        }
      );
      return false;
    }
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).isAdministrator) {
      return true;
    }
    return this.getPermition(state.url.replace("/portal/", "").replace('/index', ''))
      .then((roles: any) => {
        if (roles) {
          if (
            roles.canView && // canView : true
            !roles.canAdd && // canAdd : false
            (state.url.replace("/portal/", "").includes("create") || (roles.canView && !roles.canModify && 
              (state.url.replace("/portal/", "").includes("edit"))))
          ) {
            this.router.navigate(["/no-access"]);
            return false;
          }
          // if user access canView then run this condition
          if (roles.canView) {
            return true;
          }

          this.router.navigate(["/no-access"]);
          return false;
        } else {
          const option = {
            width: 300,
            displayTime: 5000,
            message: 'Have some problem to access please call to administration',
            type: 'error'
          };
          notify(option);
          this.router.navigate(['/portal/dashboard']);
          return true;
        }
      })
      .catch(error => {
        console.log('accessibility guard error: ', error)
        return false;
      });
  }
  /**
   * @function getPermition
   * @returns void
   * @param {*} screenName
   * @memberof UserAccessibilityDirective
   */
  getPermition(formUrl: string): Promise<any> {
    return this.sharedService
      .getUserRoles()
      .toPromise()
      .then(res => {
        if(res) {
          return res.find(x => formUrl.includes(x.formUrl ? x.formUrl.replace('/index', '') : "") && x.formUrl);
        }
        
      //   if (res.formUrl) {
      //     res.formUrl = res['formUrl'].replace('/index', '');
      //     if (res.formUrl == formUrl) {
      //       return res;
      //     }
      // }
    });
        // return res.find(x => formUrl.includes(x.formUrl ? x.formUrl.replace('/index', '') : "") && x.formUrl);
      // });
  }
}
