import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from '../shared.service';
import * as moment from 'moment';
@Component({
  selector: 'app-copy-template-mpl',
  templateUrl: './copy-template-mpl.component.html',
  styleUrls: ['./copy-template-mpl.component.scss']
})
export class CopyTemplateMplComponent implements OnInit, OnChanges {
  pricingTypeList: any[];
  citiesList: any[];
  mplForm: FormGroup;
  futureDate: any;
  validator1: any;
  validator2: any;
  uomList: any[];
  @Output() popupHeight: EventEmitter<any> = new EventEmitter();
  materialList: any[];
  brandMasterList: any[];
  brandList: any[];
  currentDate: any;
  customerList: any[];
  companyBranchsList: any[];
  itemCategoryList: any[];
  @Input() formDataSource: any;
  @Input() visibleFilter: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @Input() isBrandNotShowing: boolean = false;
  @Input() isItemCategoryShowing: boolean = false;
  @Input() isMaterialNotShowing: boolean = false;
  type: string;
  brandFilter: boolean;
  materialFilter: boolean ;
  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    
    // this.currentDate = new Date();
    // this.futureDate = new Date(
    //   Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 365
    // );
    this.uomList = [];
    this.pricingTypeList = [];
    this.citiesList = [];
    this.brandMasterList = [];
    this.materialList = [];
    this.brandList = [];
    this.customerList = [];
    this.companyBranchsList = [];
    this.itemCategoryList = [];
    

    this.getCityByCountryId(
      JSON.parse(localStorage.getItem('selectedCompanyBranch')).countryId
    );
    // this.getAllCustomerCompanyBranchId(JSON.parse(localStorage.getItem('selectedCompanyBranch')).companyBranchId);

    this.getAllPricingTypes('');
    this.getAllUoms();
    this.getAllBrands();
    this.getAllMaterials();
    this.getAllCompanyBranches();
  }

    /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
        this.getInitForm(changes.formDataSource.currentValue);
        this.changePricingType(changes.formDataSource.currentValue.pricingTypeId, 'add');
  }


  getInitForm(formData?: any) {
    this.mplForm = new FormGroup({
      mplId: new FormControl(formData ? formData.mplId : '', []),
      validityFrom: new FormControl(formData ?  formData.validityFrom : new Date(), [Validators.required]),
      validityTo: new FormControl(formData ? formData.validityTo : this.futureDate, [Validators.required]),
      customerId: new FormControl(formData ? formData.customerId : null, []),
      customerIdTo: new FormControl(formData ? formData.customerIdTo : null, []),
      cityId: new FormControl(formData ? formData.cityId : null, []),
      cityTo: new FormControl(formData ? formData.cityTo : null, []),
      companyBranchId: new FormControl(formData ? formData.companyBranchId : null, []),
      companyBranchIdTo: new FormControl(formData ? formData.companyBranchIdTo : null, []),
      pricingTypeId: new FormControl(formData ? formData.pricingTypeId : null, [Validators.required]),
      description: new FormControl(formData ? formData.description : '', [Validators.required]),
      brandMasterId: new FormControl(formData ? formData.brandMasterId : '', [Validators.required]),
      itemCategoryId: new FormControl(formData ? formData.itemCategoryId : '', []),
      // materialId: new FormControl(formData ? formData.materialId : '', [Validators.required]),
    });
    this.currentDate  = formData.validityFrom;
    this.futureDate = formData.validityTo;
    if (this.isBrandNotShowing) {
      this.brandFilter = true;
      this.mplForm.get('brandMasterId').clearValidators();
      this.mplForm.get('brandMasterId').updateValueAndValidity();
  }
      if (this.isMaterialNotShowing) {
        this.materialFilter = true;
      // this.mplForm.get('materialId').clearValidators();
      // this.mplForm.get('materialId').updateValueAndValidity();
      }
      if (this.isItemCategoryShowing) {
        this.getAllItemCategories();
        this.mplForm.get('itemCategoryId').setValidators([Validators.required]);
      } else {
        this.mplForm.get('itemCategoryId').clearValidators();
      }
      this.mplForm.get('itemCategoryId').updateValueAndValidity();
  }
    /**
    * @name getAllPricingTypes
    * @returns Promise
    * @description fetch  PricingType
    */
   getAllPricingTypes(type: string) {
    this.sharedService
      .getAllPricingTypes(type)
      .then(response => {
        if (response.data.completed) {
          // this.pricingTypeList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.pricingTypeList = list;
          } else {
            if (response.data.payload.active) {
              this.pricingTypeList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error('Error:  ', err);
      });
  }

   /**
   * @name getAllItemCategories
   * @returns Promise
   * @description fetch all item
   */
  getAllItemCategories() {
    this.sharedService
      .getAllItemCategories()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active && element.itemCategoryId !== 1) {
                list.push(element);
              }
            });
            this.itemCategoryList = list;
          } else {
            const list = [];
              response.data.payload.forEach(element => {
                if (element.itemCategoryId !== 1) {
                  list.push(element);
                }
              });
              this.itemCategoryList = list;
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllCities
   * @returns Promise
   * @description fetch all City
   */
  getCityByCountryId(countryId) {
    if (countryId) {
      this.citiesList = [];
      this.sharedService
        .getCityByCountryId(countryId)
        .then(response => {
          if (response.data.completed) {
            this.citiesList = response.data.payload;
          }
        })
        .catch(err => {
          console.error('Error:  ', err);
        });
    }
  }

  /**
  * @name getAllCustomerCompanyBranchId
  * @returns Promise
  * @description fetch all Customer
  */
  getAllCustomerCompanyBranchId(customerId: number) {
    this.customerList = [];
    this.sharedService
      .getAllCustomerCompanyBranchId(customerId)
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.customerList = list;
          } else {
            if (response.data.payload.active) {
              this.customerList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error('Error:  ', err);
      });
  }

    /**
   * @name GetAllUoms
   * @returns Promise
   * @description fetch all UOM
   */
  getAllUoms() {
    this.sharedService
      .getAllUoms()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });

            this.uomList = list;
          } else {
            if (response.data.payload.active) {
              this.uomList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error('Error:  ', err);
      });
  }
  onInitialized1(e: any) {
    this.validator1 = e.component;
  }
  onValueChanged1(e: any) {
    if (this.validator2) {
      this.validator2.validate();
    }
  }
  onInitialized2(e: any) {
    this.validator2 = e.component;
  }
  onValueChanged2(e: any) {
    if (this.validator1) {
      this.validator1.validate();
    }
  }

  get diffInDay() {
    this.futureDate = moment(this.mplForm.get('validityFrom').value).month(12).format('YYYY-MM-DDTHH:mm:ss');
    return this.mplForm.get('validityTo').setValue(moment(this.mplForm.get('validityFrom').value).month(12).format('YYYY-MM-DDTHH:mm:ss'));
  }

  dateValidation = () => {
    //if we have either start or end validate, else not required and return true.
    if (
      this.mplForm.get('validityFrom').value &&
      this.mplForm.get('validityTo').value
    ) {
      //start date must come before end date
      if (
        this.mplForm.get('validityFrom').value <
        this.mplForm.get('validityTo').value
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  /**
  * @name changePricingType
  * @returns validation and hide and show dropdown
  */
  changePricingType(value, type?: string) {
  
    if (value) {

      if (type == 'add') {
        // this.mplForm.get("cityId").setValue(null);
        // this.mplForm.get("customerId").setValue(null);
        // this.mplForm.get("companyBranchId").setValue(null);
      }
      if (value == 1) {
        this.mplForm.get('customerId').clearValidators();
        this.mplForm.get('companyBranchId').clearValidators();
        this.mplForm.get('cityId').clearValidators();
      }
      if (value == 2 || value == 3 || value == 4 || value == 5) {

        this.mplForm.get('customerId').clearValidators();
        this.mplForm.get('companyBranchId').clearValidators();
        this.mplForm.get('cityId').clearValidators();
        if (value == 3 || value == 4 || value == 5) {
          this.mplForm.get('cityId').setValidators([Validators.required]);
        }
       
        if (value == 1) {
          this.popupHeight.emit(535);
          if (this.isBrandNotShowing) {
            this.popupHeight.emit(400);
          }
          if (this.isMaterialNotShowing) {
            this.popupHeight.emit(400);
          }
         
        }
        if (value == 2) {
          this.popupHeight.emit(535);
          if (this.isBrandNotShowing) {
            this.popupHeight.emit(400);
          }
          if (this.isMaterialNotShowing) {
            this.popupHeight.emit(400);
          }
          // if (this.isItemCategoryShowing) {
          //   this.popupHeight.emit(480);
          // }
          this.mplForm.get('cityTo').clearValidators();
          this.mplForm.get('companyBranchIdTo').clearValidators();
          this.mplForm.get('customerIdTo').clearValidators();
        }
        if (value == 3) {
          this.popupHeight.emit(670);
          if (this.isBrandNotShowing) {
            this.popupHeight.emit(530);
          }
          if (this.isMaterialNotShowing) {
            this.popupHeight.emit(530);
          }
          this.mplForm.get('cityTo').setValidators([Validators.required]);
          this.mplForm.get('companyBranchIdTo').clearValidators();
          this.mplForm.get('customerIdTo').clearValidators();
        }
        if (value == 4) {
          this.popupHeight.emit(730);
          if (this.isBrandNotShowing) {
            this.popupHeight.emit(600);
          }
          if (this.isMaterialNotShowing) {
            this.popupHeight.emit(600);
          }
          this.mplForm
            .get('companyBranchId')
            .setValidators([Validators.required]);
          this.mplForm.get('companyBranchIdTo').setValidators([Validators.required]);
          this.mplForm.get('cityTo').clearValidators();
          this.mplForm.get('customerIdTo').clearValidators();
        }
        if (value == 5) {
          this.popupHeight.emit(830);
          if (this.isBrandNotShowing) {
            this.popupHeight.emit(600);
          }
          if (this.isMaterialNotShowing) {
            this.popupHeight.emit(600);
          }
          this.mplForm.get('customerId').setValidators([Validators.required]);
          this.mplForm.get('customerIdTo').setValidators([Validators.required]);
          this.mplForm.get('cityTo').clearValidators();
          this.mplForm.get('companyBranchIdTo').clearValidators();
        }

        
      }


      if (this.isItemCategoryShowing) {
        if (value == 1 || value == 2) {
         this.popupHeight.emit(480);
        }
        if (value == 3) {
          this.popupHeight.emit(620);
         }
         if (value == 4) {
          this.popupHeight.emit(680);
         }
         if (value == 5) {
          this.popupHeight.emit(830);
         }
      }
    }
    this.mplForm.get('cityId').updateValueAndValidity();
    this.mplForm.get('cityTo').updateValueAndValidity();
    this.mplForm.get('customerId').updateValueAndValidity();
    this.mplForm.get('customerIdTo').updateValueAndValidity();
    this.mplForm.get('companyBranchId').updateValueAndValidity();
    this.mplForm.get('companyBranchIdTo').updateValueAndValidity();
  }
  /**
     * @name getAllMaterials
     * @returns Promise
     * @description fetch all item
     */
  getAllBrands() {
    this.sharedService
      .getAllBrands()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.brandList = list;
          } else {
            if (response.data.payload.active) {
              this.brandList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error('Error:  ', err);
      });
  }

  /**
   * @name getAllCompanyBranches
   * @returns Promise
   * @description fetch all CompanyBranch
   */
  getAllCompanyBranches() {
    this.sharedService
      .getAllCompanyBranches()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.companyBranchsList = list;
          } else {
            if (response.data.payload.active) {
              this.companyBranchsList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error('Error:  ', err);
      });
  }

  /**
   * @name getAllMaterials
   * @returns Promise
   * @description fetch all item
   */
  getAllMaterials() {
    this.sharedService
      .getAllMaterials()
      .then(response => {
        if (response.data.completed) {
          // this.brandList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.materialList = list;
          } else {
            if (response.data.payload.active) {
              this.materialList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error('Error:  ', err);
      });
  }

  /**
   * @name submitCopyPricing
   * @param {number} pricingTypeId
   * @param {number} brandMasterId
   * @returns Promise
   * @description fetch  Initial
   */
  submitCopyPricing(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.optSelected.emit(form.value);
  }
}
