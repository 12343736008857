import { SharedService } from './../../shared.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject, Subscription, debounceTime, distinctUntilChanged } from "rxjs";
import { AppConstant } from "src/app/app.const";
import notify from "devextreme/ui/notify";
import * as moment from 'moment';
@Component({
  selector: "app-basic-filter",
  templateUrl: "./basic-filter.component.html",
  styleUrls: ["./basic-filter.component.css"],
})
export class BasicFilterComponent implements OnInit {
  filterForm: FormGroup;
  validator1: any;
  validator2: any;
  correctDate: boolean;
  appCons = new AppConstant();
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @Input() printEnable: boolean;
  @Input() vendorEnable: boolean;
  @Input() poNumber: boolean;
  @Input() accountType: boolean;
  @Input() fromAccount: boolean;
  @Input() toAccount: boolean;
  @Input() documentNumber: boolean = true;
  @Input() docummentTile: string = '';
  @Input() showIncludeFilter: boolean = true;
  @Input() customerEnable: boolean;
  @Input() isDateRangeRequired: boolean;
  @Input() isCompanyBranchByUser: boolean;
  @Input() resetEnable: boolean;
  @Input() isCompany: boolean;
  @Input() loading: boolean = false;
  vendorList: any[];
  accountTypeList: any[];
  fromAccountList: any[];
  toAccountList: any[];
  customerList: any[];
  private readonly vendorsearchSubject = new Subject<Object | undefined>();
  private vendorsearchSubscription?: Subscription;
  loadingVendor: boolean;
  loadingReport: boolean;
  loadingAccountType: boolean;
  loadingAccounts: boolean;
  loadingCustomer: boolean;
  branchList: any[];
  companyList: any[];
  constructor(private shareService: SharedService) {}

  ngOnInit(): void {
    this.vendorList = [];
    this.correctDate = true;
    this.filterForm = new FormGroup({
      fromDate: new FormControl(moment(new Date()).format('YYYY-MM-DDT' + '00:00:00'), []),
      toDate: new FormControl(moment(new Date()).format('YYYY-MM-DDT' + '23:59:00'), []),
      includeDateFilter: new FormControl(true, [])
    });
    this.initValueChange();
    if(this.documentNumber) {
      this.filterForm.addControl('id', new FormControl(0, []));
    } else {
      this.filterForm.removeControl('id');
    }
    if(this.vendorEnable) {
      this.filterForm.addControl('vendorId', new FormControl(0, []));
    } else {
      this.filterForm.removeControl('vendorId');
    }
    if(this.poNumber) {
      this.filterForm.addControl('purchaseOrderId', new FormControl(null, []));
    } else {
      this.filterForm.removeControl('purchaseOrderId');
    }
    if(this.accountType) {
      this.getCOA(3);
      this.filterForm.addControl('accountType', new FormControl(0, []));
    } else {
      this.filterForm.removeControl('accountType');
    }
    if(this.fromAccount) {
      this.filterForm.addControl('fromAccountCode', new FormControl(0, []));
    } else {
      this.filterForm.removeControl('fromAccountCode');
    }
    if(this.toAccount) {
      this.filterForm.addControl('toAccountCode', new FormControl(0, []));
    } else {
      this.filterForm.removeControl('toAccountCode');
    }
    if(this.isCompany) {
      this.getAllCompanys();
      this.filterForm.addControl('companyId', new FormControl(0, []));
    } else {
      this.filterForm.removeControl('companyId');
    }
    
    if(this.customerEnable) {
      this.filterForm.addControl('customerId', new FormControl(0, []));
      if(!this.isCompanyBranchByUser) {
        this.getAllCustomerByCompanyBranchId(JSON.parse(
          localStorage.getItem("selectedCompanyBranch")
        ).companyBranchId);
      }
    } else {
      this.filterForm.removeControl('customerId');
    }

    if(this.isCompanyBranchByUser) {
      this.filterForm.addControl('companyBranchId', new FormControl(0, []));
        this.getAllCompanyBranchesByUserId();
    } else {
      this.filterForm.removeControl('companyBranchId');
    }
    
    if(this.isDateRangeRequired) {
      this.filterForm.get('fromDate').setValidators([Validators.required]);
      this.filterForm.get('toDate').setValidators([Validators.required]);
      this.filterForm.get('fromDate').updateValueAndValidity();
      this.filterForm.get('toDate').updateValueAndValidity();
    }
    
    
  }

  
  /**
   * Retrieves a list of all companies from the finance service.
   *
   * @return {Array} A list of company objects
   */
  getAllCompanys() {
    this.shareService
      .getAllCompanys()
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.companyList = list;
          } else {
            if (response.data.payload.active) {
              this.companyList = response.data.payload;
            }
          }
        }
      })
      .catch((err) => {
        console.error("Error:  ", err);
      });
  }
  /**
   * Retrieves all company branches based on user ID.
   *
   * @return {Promise<any>} Data from the API call
   */
  getAllCompanyBranchesByUserId() {
    this.loadingReport = true;
    this.shareService
      .getAllCompanyBranchesByUserId(
        JSON.parse(localStorage.getItem("user"))
          .userId,
          JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId,
          JSON.parse(localStorage.getItem("user")).isAdministrator  
      )
      .then((response: any) => {
        if (response.data.completed) {
          this.loadingReport = false;
          if (response.data.payload.length > 0) {
            this.branchList = response.data.payload;
          } else {
            if (response.data.payload.active) {
              this.branchList = response.data.payload;
            }
          }
        } else {
          this.loadingReport = false;
          const option = {
            width: 300,
            displayTime: 2000,
            message: response.data.message,
            type: "error",
          };
          notify(option);
        }
      })
      .catch((err) => {
        console.error("Error:  ", err);
        this.loadingReport = false;
        const option = {
          width: 300,
          displayTime: 3000,
          message: err.message,
          type: "error",
        };
        notify(option);
      });
  }

/**
 * @name changeFilter
 * @param event 
 * @returns void
 */
  changeFilter(event: any) {
    if (
      event &&
      !this.filterForm.get("fromDate").value &&
      !this.filterForm.get("toDate").value
    ) {
      this.filterForm.get("fromDate").setValue(moment(new Date()).format('YYYY-MM-DDT' + '00:00:00'));
      this.filterForm.get("toDate").setValue(moment(new Date()).format('YYYY-MM-DDT' + '23:59:00'));
    }
  }

   /**
   * @name initValueChange
   * @return void
   */
   initValueChange() {
      this.vendorsearchSubscription = this.vendorsearchSubject
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((results: any) => {
        this.getVendors(results);
      });
  }

  /**
   * @name getSearchVendors
   * @param {string} search
   */
getSearchVendors(search: string) {
  if (search) {
    this.vendorsearchSubject.next(search);
  }
}

validateFromAccount(event: any) {
  let fromAccountCode = parseInt(this.filterForm.get('fromAccountCode').value);
  let toAccountCode = parseInt(this.filterForm.get('toAccountCode').value);
  if(fromAccountCode && toAccountCode) {
    if(fromAccountCode > toAccountCode) {
      this.filterForm.get('fromAccountCode').setErrors({invalidAccount: true});
      // this.filterForm.get('fromAccountCode').updateValueAndValidity();
    } else {
      this.filterForm.get('fromAccountCode').setErrors(null);
      // this.filterForm.get('fromAccountCode').updateValueAndValidity();
    }
  }
}
  

/**
   * @name getVendors
   * @param search 
   * @returns 
   */
getVendors(search) {
  this.loadingVendor = true;
  this.shareService
    .getVendors(search)
    .then((response) => {
      if (response.data.completed) {
        if (response.data.payload.length > 0) {
          this.vendorList = response.data.payload;
          this.loadingVendor = false;
        } else {
          this.loadingVendor = false;
        }
      }
    })
    .catch((err) => {
      this.loadingVendor = false;
      console.error("Error:  ", err);
    });
}
/**
   * @name getCOA
   * @param coaLevelId 
   * @returns 
   */
getCOA(coaLevelId) {
  this.loadingAccountType = true;
  this.shareService
    .getCOA(coaLevelId)
    .then((response) => {
      if (response.data.completed) {
        if (response.data.payload.length > 0) {
          this.accountTypeList = response.data.payload;
          this.loadingAccountType = false;
        } else {
          this.loadingAccountType = false;
        }
      }
    })
    .catch((err) => {
      this.loadingAccountType = false;
      console.error("Error:  ", err);
    });
}
/**
   * @name getCOALevel6
   * @param accountId 
   * @returns 
   */
getCOALevel6(accountId) {
  if(accountId) {
    this.loadingAccounts = true;
  this.shareService
    .getCOALevel6(accountId)
    .then((response) => {
      if (response.data.completed) {
        if (response.data.payload.length > 0) {
          this.fromAccountList = response.data.payload;
          this.toAccountList = response.data.payload;
          this.loadingAccounts = false;
        } else {
          this.loadingAccounts = false;
        }
      }
    })
    .catch((err) => {
      this.loadingAccounts = false;
      console.error("Error:  ", err);
    });
  } else {
    this.fromAccountList = [];
    this.toAccountList = [];
  }
  
}

  onInitialized1(e: any) {
    this.validator1 = e.component;
  }
  onValueChanged1(e: any) {
    if (this.validator2) {
      this.validator2.validate();
    }
  }
  onInitialized2(e: any) {
    this.validator2 = e.component;
  }
  onValueChanged2(e: any) {
    if (this.validator1) {
      this.validator1.validate();
    }
  }

  /**
   * @name dateValidation
   * @returns void
   * @description validate date
   */
  dateValidation = () => {
    let fromDate = this.filterForm.get("fromDate").value;
    let toDate = this.filterForm.get("toDate").value;
    //if we have either start or end validate, else not required and return true.
    if (fromDate && toDate) {
      //start date must come before end date
      if (toDate < fromDate || fromDate > toDate) {
        this.correctDate = false;
        return false;
      } else {
        this.correctDate = true;
        return true;
      }
    } else {
      return true;
    }
  };

  

  /**
   * @name submitFilter
   * @return void
   */
  submitFilter() {
    if(this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      return;
    }
    if (this.correctDate) {
      if (this.documentNumber && this.filterForm.get("id").value) {
        this.filterForm.get("includeDateFilter").setValue(false);
      }
      let initFromDate = moment(new Date()).format('YYYY-MM-DDT' + '00:00:00');
      let initToDate = moment(new Date()).format('YYYY-MM-DDT' + '23:59:00');
      let f = this.filterForm.get("fromDate").value ? this.filterForm.value.fromDate : AppConstant.ksaSettDate(initFromDate);
      let t = this.filterForm.get("toDate").value ? this.filterForm.value.toDate : AppConstant.ksaSettDate(initToDate);
      // if (!this.filterForm.get("includeDateFilter").value) {
      //   this.filterForm.get("fromDate").setValue(null);
      //   this.filterForm.get("toDate").setValue(null);
      // }
      const payload = {
        id: this.documentNumber ? (this.filterForm.get("id").value
          ? this.filterForm.get("id").value
          : 0) : 0,
        fromDate: f,
        toDate: t,
        includeDateFilter: this.filterForm.get("includeDateFilter").value,
        vendorId: this.vendorEnable ? (this.filterForm.get("vendorId").value ? this.filterForm.get("vendorId").value : 0) : 0,
        purchaseOrderId: this.poNumber ? (this.filterForm.get("purchaseOrderId").value ? this.filterForm.get("purchaseOrderId").value : 0) : 0,
        accountType: this.accountType ? (this.filterForm.get("accountType").value ? this.filterForm.get("accountType").value : 0) : 0,
        fromAccountCode: this.fromAccount ? (this.filterForm.get("fromAccountCode").value ? this.filterForm.get("fromAccountCode").value : 0) : 0,
        toAccountCode : this.toAccount ? (this.filterForm.get("toAccountCode").value ? this.filterForm.get("toAccountCode").value : 0) : 0,
        customerId : this.customerEnable ? (this.filterForm.get("customerId").value ? this.filterForm.get("customerId").value : 0) : 0,
        companyBranchId : this.isCompanyBranchByUser ? (this.filterForm.get("companyBranchId").value ? this.filterForm.get("companyBranchId").value : 0) : 0,
        companyId : this.isCompany ? (this.filterForm.get("companyId").value ? this.filterForm.get("companyId").value : 0) : 0,
      };
      // payload['vendorId'] = payload.vendorId ? payload.vendorId : 0;
      this.optSelected.emit(payload);
    }
  }

  /**
   * @name submitPrint
   * @return void
   */
  submitPrint() {
    if(this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      return;
    }
    this.generateReport(this.filterForm.get("vendorId").value)
  }

  /**
   * @name resetFilter
   * @return void
   */
  resetFilter() {
    this.filterForm.reset();
    this.optSelected.emit('reset');
    this.filterForm.get('fromDate').setValue(moment(new Date()).format('YYYY-MM-DDT' + '00:00:00'));
    this.filterForm.get('toDate').setValue(moment(new Date()).format('YYYY-MM-DDT' + '23:59:00'));
    this.filterForm.get('includeDateFilter').setValue(true);
  }


  /**
   * @name generateReport
   * @returns void
   * @description Generate Report
   */
  generateReport(documentId: number) {
    this.loadingReport = true;
    let f = moment(new Date()).format('YYYY-MM-DDT' + '00:00:00');
    let t =  moment(new Date()).format('YYYY-MM-DDT' + '23:59:00');
    if (documentId > 0) {
      let payload = {
        moduleId: 1,
        reportConfigurationId: 104,
        recordNo: documentId,
        customerId: 0,
        customerBranchId: 0,
        brandMasterId: [],
        driverId: 0,
        fromDate: AppConstant.ksaSettDate(f),
        toDate: AppConstant.ksaSettDate(t),
        fileType: 1,
        companyBranchId: [],
        salesOrderTypeId: [],
        accountHeadDetailTypeId: null,
        accountId: null,
        companyId: JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId,
        activeOrder: false,
      };
      this.shareService
        .generateReport(payload)
        .then((res: any) => {
          if(res.data.payload) {
            window.open(res.data.payload, "_blank");
          } else {
            const option = {
              width: 300,
              displayTime: 5000,
              message: res.data.message,
              type: "error",
            };
            notify(option);
          }
          this.loadingReport = false;
        })
        .catch((error) => {
          this.loadingReport = false;
          const option = {
            width: 300,
            displayTime: 5000,
            message: error.message,
            type: "error",
          };
          notify(option);
        });
    }
  }

    /**
   * @name getAllCustomerByCompanyBranchId
   * @param {number} companyBranchId
   * @reutrn Customer list
   */
    getAllCustomerByCompanyBranchId(companyBranchId: any) {
      if(this.isCompanyBranchByUser) {
        this.filterForm.get('customerId').setValue(null);
      }
      if (companyBranchId) {
        this.loadingCustomer = true;
        this.customerList = [];
      this.shareService
        .getAllCustomerByCompanyBranchId(companyBranchId, 0)
        .then((response) => {
          if (response.data.completed) {
            if (response.data.payload.length > 0) {
              const list = [];
              response.data.payload.forEach((element) => {
                if (element.active) {
                  list.push(element);
                }
              });
              this.customerList = list;
            } else {
              if (response.data.payload.active) {
                this.customerList = response.data.payload;
              }
            }
          }
          this.loadingCustomer = false;
        })
        .catch((err) => {
          this.loadingCustomer = false;
          console.error("Error:  ", err);
        });
      }
    }

  ngOnDestroy(): void {
    if(this.vendorsearchSubscription) {
      this.vendorsearchSubscription.unsubscribe();
    }
  }
}
