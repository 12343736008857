import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { AppSettings } from "../../app.settings";
import { MenuService } from "../../template/theme/components/menu/menu.service";
// import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Settings } from "../../app.settings.model";
import { StorageService } from "../../services/branchstorage.service";
import axios from "axios";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgScrollbar } from "ngx-scrollbar";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [MenuService],
})
export class PagesComponent implements OnInit {
  currentRoute: string;
  userRights: any = [];
  @ViewChild("sidenav") sidenav: any;
  @ViewChild("backToTop") backToTop: any;
  @ViewChild("companyBranchSelectButton") companyBranchSelectButton;
  @ViewChild("mainSidenavContent") mainSidenavContent: any;
  @ViewChild("mainContent") mainContent: ElementRef;
  public settings: Settings;
  public menus = ["vertical", "horizontal"];
  public menuOption: string;
  public menuTypes = ["default", "compact", "mini"];
  public menuTypeOption: string;
  public companyName: string;
  public isStickyMenu: boolean = false;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized
  public isPanelOpen: boolean = false;
  public userBranches = null;
  public companyBranchId = null;
  public companyBranchName = null;
  public CompanyArabicName = null;
  isBranchChange: boolean;
  isNotifications: boolean;
  constructor(
    private _snackBar: MatSnackBar,
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    private bService: StorageService
  ) {
    this.settings = this.appSettings.settings;
    this.userBranches = JSON.parse(localStorage.getItem("user")).userBranchs;
    this.companyBranchId = bService.getBranch.companyBranchId;
    // this.setCompanyBranch(bService.getBranch.companyBranchId);
    let currentBranch = this.userBranches.find(
      (obj) => obj.companyBranchId == bService.getBranch.companyBranchId
    );
    this.companyBranchName = currentBranch.companyBranchName;
    this.companyName = JSON.parse(
      localStorage.getItem("selectedCompanyBranch")
    ).companyName;
    this.CompanyArabicName = JSON.parse(
      localStorage.getItem("selectedCompanyBranch")
    ).companyArabicName;

    this.bService.setBranch = currentBranch;
    localStorage.setItem(
      "selectedCompanyBranch",
      JSON.stringify(currentBranch)
    );
  }

  ngOnInit() {
    this.isNotifications = true;
    this.bService.notificationEmit.subscribe((responce: boolean) => {
      this.isNotifications = responce;
    });
    if (
      localStorage.getItem("isNotifications") &&
      localStorage.getItem("isNotifications") == "false"
    ) {
      this.isNotifications = false;
    }
    window.addEventListener("storage", (event) => {
      //console.log(event)
      if (
        event.newValue &&
        JSON.parse(event.newValue).companyBranchId !==
          JSON.parse(event.oldValue).companyBranchId
      ) {
        localStorage.setItem("selectedCompanyBranch", event.newValue);
        this.companyBranchId = null;
        // this.isPanelOpen = false;
        this.companyBranchId = JSON.parse(event.newValue).companyBranchId;
        this.router.navigate(["/portal/dashboard"]);
        // this.isPanelOpen = true;
        this.companyBranchName = JSON.parse(
          localStorage.getItem("selectedCompanyBranch")
        ).companyBranchName;
        // const option = {
        //   width: 300,
        //   displayTime: 8000,
        //   message: 'You Selected Company Branch is Confilict to multiple Browser Tab',
        //   type: "error",
        // };
        // notify(option);
        //setTimeout(() => {
        // localStorage.removeItem("user");
        // localStorage.removeItem("token");
        // localStorage.removeItem("selectedCompanyBranch");
        // localStorage.removeItem("userRights");
        // localStorage.removeItem("vendorFilters");
        // localStorage.removeItem("rxgrouping");
        // localStorage.removeItem("tokenExpireIn");
        // localStorage.removeItem("filters");
        // localStorage.removeItem("state");
        // this.router.navigate(["/authentication/login"]);
        // /}, 9000);
      }
    });
    this.currentRoute = this.router.url;
    if (window.innerWidth <= 768) {
      this.settings.menu = "vertical";
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  }
  setCompanyBranch(val) {
    this.settings.loadingSpinner = true;
    let currentBranch = this.userBranches.find(
      (obj) => obj.companyBranchId == val
    );
    this.companyBranchName = currentBranch.companyBranchName;
 
    this.bService.setBranch = currentBranch;
    this.isBranchChange = true;
    localStorage.setItem(
      "selectedCompanyBranch",
      JSON.stringify(currentBranch)
    );
    window.localStorage.setItem("state", JSON.stringify(currentBranch));
    // Add a request interceptor
    // axios.interceptors.request.use(
    //   (config) => {
    //     config.headers.clear();

    //     // Do something before request is sent
    //     // config.headers.common.CompanyId  = localStorage.getItem("selectedCompanyBranch") ? JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId : 0;
    //     // config.headers.common.CompanyBranchId = localStorage.getItem("selectedCompanyBranch") ? JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyBranchId : 0;
    //     config.headers["CompanyId"] = localStorage.getItem(
    //       "selectedCompanyBranch"
    //     )
    //       ? JSON.parse(localStorage.getItem("selectedCompanyBranch")).companyId
    //       : 0;
    //     config.headers["CompanyBranchId"] = localStorage.getItem(
    //       "selectedCompanyBranch"
    //     )
    //       ? JSON.parse(localStorage.getItem("selectedCompanyBranch"))
    //           .companyBranchId
    //       : 0;
    //     config.headers["UserId"] = localStorage.getItem("user")
    //       ? JSON.parse(localStorage.getItem("user")).userId
    //       : 0;
    //     return config;
    //   },
    //   (error) => {
    //     // Do something with request error
    //     return Promise.reject(error);
    //   }
    // );

    axios
      .get(
        "AdminForm/Menu?userId=" +
          JSON.parse(localStorage.getItem("user")).userId +
          "&branchId=" +
          this.companyBranchId
      )
      .then((response) => {
        if (response.data.completed) {
          this.userRights = response.data.payload;
          localStorage.setItem("userRights", JSON.stringify(this.userRights));
          this.router.navigate(["/portal/dashboard"]);
          this.settings.loadingSpinner = false;
        } else {
          this.settings.loadingSpinner = false;
          this._snackBar.open(response.data.message, "", {
            duration: 3000,
          });
        }
      });

    this._snackBar.open(
      `Switched to "${this.companyBranchName}" branch !`,
      "",
      {
        duration: 2000,
      }
    );
    this.companyName = JSON.parse(
      localStorage.getItem("selectedCompanyBranch")
    ).companyName;
    this.CompanyArabicName = JSON.parse(
      localStorage.getItem("selectedCompanyBranch")
    ).companyArabicName;
  }
  toggleCompanyBranchSelectButtonPanel(bool) {
    if (bool) {
      this.isPanelOpen = true;
      setTimeout(() => {
        this.companyBranchSelectButton.open();
      }, 100);
    } else {
      this.companyBranchSelectButton.close();
      setTimeout(() => {
        this.isPanelOpen = false;
      }, 100);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    this.backToTop.nativeElement.style.display = "none";
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu == "vertical")
      this.menuService.expandActiveSubMenu(
        this.menuService.getVerticalMenuItems()
      );
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(["/"]);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    event.target.scrollTop > 300
      ? (this.backToTop.nativeElement.style.display = "flex")
      : (this.backToTop.nativeElement.style.display = "none");
    if (this.settings.menu == "horizontal") {
      if (this.settings.fixedHeader) {
        var currentScrollTop =
          event.target.scrollTop > 56 ? event.target.scrollTop : 0;
        currentScrollTop > this.lastScrollTop
          ? (this.isStickyMenu = true)
          : (this.isStickyMenu = false);
        this.lastScrollTop = currentScrollTop;
      } else {
        event.target.scrollTop > 56
          ? (this.isStickyMenu = true)
          : (this.isStickyMenu = false);
      }
    }
  }

  public onPageScroll(event: any) {
    event.target.scrollTop > 300
      ? (this.backToTop.nativeElement.style.display = "flex")
      : (this.backToTop.nativeElement.style.display = "none");
    if (this.settings.menu == "horizontal") {
      if (this.settings.fixedHeader) {
        var currentScrollTop =
          event.target.scrollTop > 56 ? event.target.scrollTop : 0;
        if (currentScrollTop > this.lastScrollTop) {
          document.querySelector("#horizontal-menu")!.classList.add("sticky");
          event.target.classList.add("horizontal-menu-hidden");
        } else {
          document
            .querySelector("#horizontal-menu")!
            .classList.remove("sticky");
          event.target.classList.remove("horizontal-menu-hidden");
        }
        this.lastScrollTop = currentScrollTop;
      } else {
        if (event.target.scrollTop > 56) {
          document.querySelector("#horizontal-menu")!.classList.add("sticky");
          event.target.classList.add("horizontal-menu-hidden");
        } else {
          document
            .querySelector("#horizontal-menu")!
            .classList.remove("sticky");
          event.target.classList.remove("horizontal-menu-hidden");
        }
      }
    }
  }

  public scrollToTop() {
    this.mainSidenavContent.scrollTo({
      top: 0,
    });
    this.mainContent.nativeElement.scrollTo({
      duration: 100,
      top: 0,
    });
  }

  @HostListener("window:resize")
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = "vertical";
    } else {
      this.defaultMenu == "horizontal"
        ? (this.settings.menu = "horizontal")
        : (this.settings.menu = "vertical");
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }
}
