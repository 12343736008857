import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

import {DialogAddRows} from '../dilog/add-rows.component';
import {MatDialog} from '@angular/material/dialog';
import {Papa} from 'ngx-papaparse';
import {SharedService} from '../../shared.service';

declare var $: any;





@Component({
    selector: 'app-add-form',
    templateUrl: './add-form.component.html',
    styleUrls: ['./add-form.component.scss']
})
export class AddFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit{
    requestForm: FormGroup;
    @Input() dataSource: any;
    @Output() optSelected: EventEmitter<any> = new EventEmitter();
    @Input() itemCatalogGroupId: any;
    @Input() itemCatalogId: any;
    @Input() focalId: any;
    @Input() itemTypeMasterId: any;
    @Input() companyBranchId: any;
    @Input() acknowledgement: any;
    loadingIndicator: boolean;
    showGenerateBtn: boolean;
    SPH_RANGE = [];
    ADD_RANGE = [];
    rangeStockListPayload = [];
    isEdit: boolean;
    isCsv: boolean;
    actionSheetVisible: boolean;
    actionSheetTarget: any;
    readonly: boolean;
    isAllZero: boolean;
    templist: any;
    previousStock: boolean;
    crrentStock: number;
    sphError: boolean;
    addError: boolean;
    stockIcon: boolean;
    inputType: boolean;
    totalQTY: number;
    availableStock: number;
    loadingStockAvailable: boolean;
    displayOnly: boolean;
    rangeLoading: boolean;
    showPreviousStock: boolean;
    loadingAllAvailableStock: boolean;
    hideClearBtn: boolean;
    totalToggle: boolean;
    isPreviousStockValueSetInput: boolean;
    constructor (
        private sharedService: SharedService,
        public dialog: MatDialog,
        private papa: Papa
    ) {
    }

    ngOnInit() {
        this.totalQTY = 0;
        this.availableStock = 0;
        // this.showGenerateBtn = false;
        // this.displayOnly = false;
    }
    ngAfterViewInit() {
        $(document).ready(() => {
            var element = null;
            $('table tr td input').focus((e) => {
               element =  e.target.parentNode.parentNode.parentNode;
               $(element).find('th').css('background-color', 'lightgreen');
            }).blur(() => {
               $(element).find('th').css('background-color', 'inherit');
            })
        })
    }
    /**
     * @name itemClick
     * @param sphIndex 
     * @param addlIndex 
     * @returns previousStock and crrentStock 
     */
    // itemClick(sphIndex, addlIndex) {
    //     const elementIndex = sphIndex + '-' + addlIndex;
    //     this.actionSheetTarget = '#img-' + elementIndex;
    //     this.actionSheetVisible = true;
    //     this.previousStock = 0;
    //     this.crrentStock = 0;
    //     if (this.templist &&  this.templist.length > 0) {
    //         this.templist.forEach(data => {
    //             // tslint:disable-next-line:triple-equals
    //         let sph = data.sph.toFixed(2).toString();
    //         let addf = data.addf.toFixed(2).toString();
    //             if (sph == this.SPH_RANGE[sphIndex] && addf == this.ADD_RANGE[addlIndex]) {
    //                 this.previousStock = data.previousStock ? data.previousStock : 0;
    //                 this.crrentStock = data.crrentStock ? data.crrentStock : 0;
    //                 return ;
    //             }
    //         });
    //     }
    // }
     /**
     * @name getAvailableStock
     * @param {*} e
     * @param {*} sph
     * @param {*} cyl
     * @memberof CylFormComponent
     */
    getAvailableStock(e, sph: any, add: any) {
        this.availableStock = 0;
        this.loadingStockAvailable = true;
        let payload = {
            itemCatalogGroupId: this.itemCatalogGroupId,
            sph: sph,
            cylOrAdd: add 
        };
        this.sharedService.getAvailableStock(payload)
            .then((response: any) => {
                if (response.data.completed) {
                    this.loadingStockAvailable = false;
                    this.availableStock = response.data.payload;
                    console.log('getAvailableStock', response.data.payload);
                    this.actionSheetTarget = '#img-' + e;
                    this.actionSheetVisible = true;
                } else {
                    this.loadingStockAvailable = false;
                    this.availableStock = 0;
                    console.error(response);
                }
            })
            .catch(error => {
                this.availableStock = 0;
                this.loadingStockAvailable = false;
                console.log(error);

            });

    }
    readCSV(event) {
        const files: FileList = event.target.files;
        if (files && files.length > 0) {
            const file: File = files.item(0);
            const reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                const csvData: string = reader.result as string;
                // console.log(csvData);
                this.papa.parse(csvData, {
                    complete: (result) => {
                        // console.log('Parsed: ', result);
                        result.data.map((parentArray, parentArrayIndex) => {
                            parentArray.map((value, childArrayIndex) => {
                                $('table tr td #input-' + parentArrayIndex + '-' + childArrayIndex).val(value);
                                this.getRangeValues(value, this.ADD_RANGE[childArrayIndex], this.SPH_RANGE[parentArrayIndex]);
                            });
                        });
                    }
                });
            };
        }
    }

    /**
     * @name getInitForm
     * @description init form
     * @return form controls
     */
    getInitForm() {
        this.requestForm = new FormGroup({
            sections: new FormArray([])
        });
    }

    /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
        this.getInitForm();
        if (Object.keys(changes.dataSource.currentValue).length > 0) {
            this.rangeStockListPayload = [];
            this.isAllZero = false;
            // console.log('Add', changes.dataSource.currentValue);
            this.inputType = changes.dataSource.currentValue.inputType;
            this.stockIcon = changes.dataSource.currentValue.stockIcon;
            this.displayOnly = changes.dataSource.currentValue.displayOnly;
            this.showPreviousStock = changes.dataSource.currentValue.showPreviousStock;
            this.hideClearBtn = changes.dataSource.currentValue.hideClearBtn;
            this.readonly = changes.dataSource.currentValue.readonly;
             this.totalToggle = changes.dataSource.currentValue.totalToggle;
             this.showGenerateBtn = changes.dataSource.currentValue.generateBtn;
             this.isPreviousStockValueSetInput = changes.dataSource.currentValue.isPreviousStockValueSetInput;
            this.addSection(changes.dataSource.currentValue);

             if (!this.showGenerateBtn) {
                this.checkRange();
            }
            if (changes.dataSource.currentValue.isEdit) {
                if (changes.dataSource.currentValue.rangeStockListPayload) {
                    this.setInventoryFormData(changes.dataSource.currentValue.rangeStockListPayload);
                }
                this.isEdit = true;
                this.isPreviousStockValueSetInput = false;
                this.readonly = changes.dataSource.currentValue.readonly;
                this.isAllZero = changes.dataSource.currentValue.isAllZero;
                this.templist = changes.dataSource.currentValue.rangeStockListPayload;
            }
            if (changes.dataSource.currentValue.rangeOption) {
                const payload = {
                    itemCatalogGroupId: this.itemCatalogGroupId,
                    itemTypeMasterId: this.itemTypeMasterId,
                    focalId: this.focalId,
                    sphmin: (changes.dataSource.currentValue.barCodeData.sphmin || changes.dataSource.currentValue.barCodeData.sphmin == 0) ? changes.dataSource.currentValue.barCodeData.sphmin : 0,
                    sphmax: (changes.dataSource.currentValue.barCodeData.sphmax || changes.dataSource.currentValue.barCodeData.sphmax == 0)  ? changes.dataSource.currentValue.barCodeData.sphmax : 0,
                    cylmin: (changes.dataSource.currentValue.barCodeData.cylmin || changes.dataSource.currentValue.barCodeData.cylmin == 0)  ? changes.dataSource.currentValue.barCodeData.cylmin : 0,
                    cylmax: (changes.dataSource.currentValue.barCodeData.cylmax || changes.dataSource.currentValue.barCodeData.cylmax == 0) ? changes.dataSource.currentValue.barCodeData.cylmax : 0,
                    addmin: (changes.dataSource.currentValue.barCodeData.addmin || changes.dataSource.currentValue.barCodeData.addmin == 0)  ? changes.dataSource.currentValue.barCodeData.addmin : 0,
                    addmax: (changes.dataSource.currentValue.barCodeData.addmax || changes.dataSource.currentValue.barCodeData.addmax == 0) ? changes.dataSource.currentValue.barCodeData.addmax : 0,
                    cxmin:  (changes.dataSource.currentValue.barCodeData.cxmin || changes.dataSource.currentValue.barCodeData.cxmin == 0) ? changes.dataSource.currentValue.barCodeData.cxmin : 0,
                    cxmax:  (changes.dataSource.currentValue.barCodeData.cxmax || changes.dataSource.currentValue.barCodeData.cxmax == 0) ? changes.dataSource.currentValue.barCodeData.cxmax : 0
                };
                this.validateBarcodeRequestRange(payload);
            }
        }
    }

    /**
     // tslint:disable-next-line:no-redundant-jsdoc
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description addSection
     // tslint:disable-next-line:no-redundant-jsdoc
     // tslint:disable-next-line:no-redundant-jsdoc
     * @params data
     * @returns void
     * @description create section array
     */
    addSection(data?: any): void {
        if (data) {
            if (data.active) {
                // console.log(data);
                this.showGenerateBtn = data.generateBtn;
                this.isCsv = data.csv;
                const sectionsList = this.requestForm.get('sections') as FormArray;
                sectionsList.push(this.createSection(data));
            }
        }
    }

    /**
      // tslint:disable-next-line:no-redundant-jsdoc
     * @description createSection
     * @params data
     * @return FormGroup
     * @description section controls
     */
    createSection(data?: any): FormGroup {
        return new FormGroup({
            condition: new FormControl(data ? data.condition : '', []),
            active: new FormControl(data ? data.active : false, []),
            childern: new FormArray(this.addChildern(data.childern)),
        });
    }

    /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description addChildern
     * @params data
     * @returns childern array
     */
    addChildern(data?: any) {
        const formArray = [];
        data.forEach(child => {
            formArray.push(this.createChild(child));
        });
        return formArray;
    }

    /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description createChild
     * @params child
     * @return  childer controls
     * @deprecated child formcontrols
     */
    createChild(child?): FormGroup {
        return new FormGroup({
            name: new FormControl(child ? child.name : '', []),
            min: new FormControl(child ? child.min : '', [Validators.required]),
            max: new FormControl(child ? child.max : '', [Validators.required]),
            valueType: new FormControl(child.valueType, [])
        });
    }

    /**
     * @description sectionsArray
     * @returns section array controls
     */
    get sectionsArray() {
        return this.requestForm.get('sections') as FormArray;
    }

    /**
     * @description getChildern
     * @params form section item index
     * @returns childern array controls
     */
    getChildern(form) {
        return form.controls.childern.controls;
    }

    /**
     * @description saveStockRequest
     * @params {FormGroup} requestForm
     * @returns payload form cxtypedetail
     */
    saveStockRequest(requestForm: FormGroup) {
        this.loadingIndicator = true;
        const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
        const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        const add_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
        const add_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
        const add_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value;
        const sph_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('valueType').value;
        this.rangeStockListPayload.map(item => {
            if(item.vendorItemCatalogId) {
                item['vendorItemCatalogId'] = $('table tr td #' + item.vendorItemCatalogId).val();
            } else {
                item['vendorItemCatalogId'] = 0;
            }
        })
        const payload = {
            sphmin: sph_valueType ? -sph_min : sph_min,
            sphmax: sph_valueType ? -sph_max : sph_max,
            addmin: add_valueType ? -add_min : add_min,
            addmax: add_valueType ? -add_max : add_max,
            rangeStockListPayload: this.rangeStockListPayload,
            totalQTY: this.totalQTY
        };
        if (requestForm.invalid) {
            return false;
        }
        this.optSelected.emit(payload);
        setTimeout(() => {
            this.loadingIndicator = false;
        },300)
    }

    /**
     // tslint:disable-next-line:no-redundant-jsdoc
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description clearField
     * @return void
     */
    clearField(): void {
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue('');
        // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
        // while (child.length !== 0) {
        //     child.removeAt(0);
        // }
        this.rangeStockListPayload = [];
        this.SPH_RANGE = [];
        this.ADD_RANGE = [];
    }

    /**
     * @description addRows
     * @return number of rows
     * @description modal popup
     */
    addRows() {
        const dialogRef = this.dialog.open(DialogAddRows, {
            width: '250px',
            data: {label: 'ADD'}
        });

        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed', result);
            if (result.rows) {
                // tslint:disable-next-line:radix
                for (let i = 0; i < parseInt(result.rows); i++) {
                    // this.createCxTypeDetail();
                    this.updatedMaxVal('increment');
                }
            }
        });
    }

    /**
     * @description incrementMaxVal
     * @returns updated max value
     */
    updatedMaxVal(label: string) {
        const val = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        if (label === 'increment') {
            // tslint:disable-next-line:radix
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) + 1);
            // tslint:disable-next-line:radix
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue(parseInt(val) + 1);
        }
        if (label === 'decrement') {
            // tslint:disable-next-line:radix
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) - 1);
            // tslint:disable-next-line:radix
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue(parseInt(val) - 1);
        }
    }

    /**
     * @description removeIndex
     // tslint:disable-next-line:no-redundant-jsdoc
     * @params index
     * @return void
     * @description removing row index
     */
    removeIndex(index) {
        // this.SPH_RANGE.splice(index, 1);
        // this.CYL_RANGE.splice(index, 1);
        // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
        // item.removeAt(index);
        this.updatedMaxVal('decrement');
        // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
    }

    getRangeValues(val, addf, sph, hiddenId?: any) {
        if (!val && this.isAllZero) {
            // if value empty then remove it from collection
            for (let i = 0; i < this.rangeStockListPayload.length; i++) {
                if (
                    this.rangeStockListPayload[i].addf === addf &&
                    this.rangeStockListPayload[i].sph === sph
                ) {
                    this.rangeStockListPayload.splice(i, 1);
                    break;
                }
            }
            this.totalQTY  = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
            // console.log('if value empty then remove it from collection ', this.rangeStockListPayload);
            return;
        }

        // check whether the value already exist then modify it
        if (this.rangeStockListPayload.some(o => o.addf === addf && o.sph === sph)) {
            for (let i = 0; i < this.rangeStockListPayload.length; i++) {
                if (
                    this.rangeStockListPayload[i].addf === addf &&
                    this.rangeStockListPayload[i].sph === sph && val
                ) {
                    this.rangeStockListPayload[i]['vendorItemCode'] = val;
                    break;
                }
            }
            this.totalQTY  = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        } else {
            // else not found so push it
            const rangePairObj = {
                cx: null,
                cxtypeId: null,
                vendorItemCode: val,
                cc: null,
                addf: addf,
                axis: null,
                cyl: null,
                ct: null,
                et: null,
                tc: null,
                wt: null,
                sph: sph,
                vendorItemCatalogId: hiddenId
            };
            this.rangeStockListPayload.push(rangePairObj);
            this.totalQTY  = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        }
        // console.log('Else range ', this.rangeStockListPayload);
    }

     checkRange() {
        this.SPH_RANGE = [];
        this.ADD_RANGE = [];
        const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
        const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        const sph_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('valueType').value;
        const add_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
        const add_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
        const add_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value;
        if (sph_max < sph_min) {
            return this.sphError = true;
        } else {
            this.sphError = false;
            if ((sph_min && sph_max || sph_min === 0 && sph_max || sph_min === 0 && sph_max === 0)) {
                // (sph_max < sph_min) ? this.isSphInvalid = true : this.isSphInvalid = false;
                // this.SPH_RANGE = this.sharedService.generateRange(sph_min, sph_max, 0.25);
                this.SPH_RANGE = this.sharedService.generateRange(sph_valueType ? -sph_max : sph_min, sph_valueType ? -sph_min : sph_max, 0.25);
                if (sph_valueType) {
                    this.SPH_RANGE.reverse();
                }
                // console.log('SphRange ', this.SPH_RANGE);
            } else {
                this.SPH_RANGE = null;
            }
        }
        if (add_max < add_min) {
            return this.addError = true;
         } else {
            this.addError = false;
            if ((add_min && add_max) || (add_min === 0 && add_max) || (add_min === 0 && add_max === 0)) {
                // (cyl_max < cyl_min) ? this.isCylInvalid = true : this.isCylInvalid = false;
                this.ADD_RANGE = this.sharedService.generateRange(add_valueType ? -add_max : add_min, add_valueType ? -add_min : add_max, 0.25);
                // this.ADD_RANGE = this.sharedService.generateRange(add_min, add_max, 0.25);
                // console.log('ADD_RANGE ', this.ADD_RANGE);
                if (add_valueType) {
                    this.ADD_RANGE.reverse();
                }
            } else {
                this.ADD_RANGE = null;
            }
         }
       
    }

    toggleStock(label: string) {
        switch (label) {
            case 'previous':
                this.previousStock = !this.previousStock;
                this.getAllAvailableStock(this.companyBranchId, this.itemCatalogGroupId, ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value > 0 ? true : false);
                break;
            //  case 'current':
            //     this.currentStock = !this.currentStock;
            //     this.setCurrentStock(this.dataSource.rangeStockListPayload);
            //   break;
        }
     }
      /**
   * @name getAllAvailableStock
   * @param {number} companyBranchId
   * @param {number} itemCatalogGroupId
   * @param {number} isPositiveRange
   * @returns
   * @memberof SharedService
   */
  getAllAvailableStock(companyBranchId: number, itemCatalogGroupId: number, isPositiveRange: boolean) {
    this.loadingAllAvailableStock = true;
    this.sharedService.getAllAvailableStock(companyBranchId, itemCatalogGroupId, isPositiveRange)
        .then((response: any) => {
            if (response.data.completed) {
            this.checkRange();
            this.loadingAllAvailableStock = false;
            this.setAvailableStock(response.data.payload.availableStock);
            } else {
                this.loadingAllAvailableStock = false;
                console.error(response);
            }
        })
        .catch(error => {
            this.loadingAllAvailableStock = false;
            console.log(error);

        });

 }
  /**
     * @name setCurrentStock
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setAvailableStock(dataSource) {
        // if (this.SPH_RANGE && this.SPH_RANGE.length > 0 && this.CYL_RANGE && this.CYL_RANGE.length > 0) {
        dataSource.forEach(data => {
            this.SPH_RANGE.forEach((sph, sphIndex) => {
                this.ADD_RANGE.forEach((addf, index) => {
                    
                        if (data.sph == sph.toString() && data.addf == addf.toString()) {
                            setTimeout(() => {
                                if(this.isPreviousStockValueSetInput) {
                                    $('table tr td #input-' + sphIndex + '-' + index).val(data.availableStock);
                                    this.getRangeValues(data.availableStock, addf, sph);
                                }
                                 $('table tr td #availableStock-' + sphIndex + '-' + index).text(data.availableStock);
                            }, 10);
                        }
                    });
                });
        });
        // } else {
        //     console.error('Line: 634-SPH AND CYL LENGHT 0');
        // }
       
    }
 /**
   * @name validateBarcodeRequestRange
   * @param {number} itemCatalogGroupId
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateBarcodeRequestRange(payload) {
    this.rangeLoading = true;
     this.sharedService.validateBarcodeRequestRange(payload)
         .then((response: any) => {
             if (response.data.completed && response.data.payload.length > 0) {
                 this.rangeLoading = false;
                 console.log('validateBarcodeRequestRange', response.data.payload);
                 this.setBarCodeRange(response.data.payload);
             } else {
                 this.rangeLoading = false;
                 console.error(response);
             }
         })
         .catch(error => {
             this.rangeLoading = false;
             console.log(error);

         });

 }
    /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description setInventoryFormData
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setInventoryFormData(dataSource) {
        this.SPH_RANGE.forEach((sph, sphIndex) => {
            this.ADD_RANGE.forEach((addf, index) => {
                dataSource.forEach(data => {
                    // tslint:disable-next-line:triple-equals
                    if (data.sph == sph.toString() && data.addf == addf.toString()) {
                        this.getRangeValues(data.vendorItemCode, addf, sph, 'hidden-' + sphIndex + '-' + index);
                        setTimeout(() => {
                            $('table tr td #input-' + sphIndex + '-' + index).val(data.vendorItemCode);
                            $('table tr td #hidden-' + sphIndex + '-' + index).val(data.vendorItemCatalogId);
                            if (this.isEdit) {
                                this.totalQTY  = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
                            }
                        }, 10);
                    }
                });
            });
        });
    }

     /**
     * @name setInventoryFormData
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setBarCodeRange(dataSource) {
        this.SPH_RANGE.forEach((sph, sphIndex) => {
            this.ADD_RANGE.forEach((addf, index) => {
                dataSource.forEach(data => {
                let s = sph.toString().replace('-','');
                let add = addf.toString().replace('-', '');
                let sp = data.sph.toFixed(2).replace('-', '');
                let ad = data.addf.toFixed(2).replace('-', '');
                $('table tr td #input-' + sphIndex + '-' + index).attr('disabled', 'disabled');
                    if (sp == s && ad == add) {
                        setTimeout(() => {
                                console.log('data.status', data.Status)
                                 $('table tr td #input-' + sphIndex + '-' + index).removeAttr('disabled');
                        }, 10);
                    } 
                });
            });
        });
    }
    toggleGenerateBtn() {
        if (((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).length > 0) {
            const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
            const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
            const add_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
            const add_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
            if ((sph_min && sph_max && add_min && add_max) ||
                (sph_min === 0 && sph_max && add_min === 0 && add_max) ||
                (sph_min === 0 && sph_max && add_min && add_max) ||
                (add_min === 0 && add_max && sph_min && sph_max)) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     // tslint:disable-next-line:no-redundant-jsdoc
     * @description destroy component
     */
    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        // this.subscription.unsubscribe();
    }
}
